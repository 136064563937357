import { Stack, ThemeContext } from 'grommet'
import styled from 'styled-components/macro'

import { Box, ColorProp, ImageSize, SIZE_MAP } from '@cutover/react-ui'

export type IntegrationImageProps = {
  size?: ImageSize
  color?: ColorProp
  src: string
  inProgress?: boolean
  active?: boolean
}

export const IntegrationImage = ({ size = 'medium', color, src, inProgress, active }: IntegrationImageProps) => {
  const Image = styled.img`
    border-radius: 50%;
  `
  const content = (
    <ThemeContext.Extend value={{ dark: false }}>
      <Box align="center" justify="center" height={SIZE_MAP[size].size} width={SIZE_MAP[size].size} round="full">
        <Image src={src} height={SIZE_MAP[size].size} width={SIZE_MAP[size].size} />
      </Box>
    </ThemeContext.Extend>
  )

  if (inProgress === undefined) {
    return content
  }

  return (
    <Box height={SIZE_MAP[size].size} width={SIZE_MAP[size].size}>
      <Stack anchor="bottom-right">
        {content}
        {active ? (
          inProgress ? (
            <Box
              height={SIZE_MAP[size].inProgress.size}
              width={SIZE_MAP[size].inProgress.size}
              background="success"
              border={{ size: SIZE_MAP[size].inProgress.border, color: 'bg' }}
              css={`
                position: relative;
                bottom: ${SIZE_MAP[size].inProgress.top};
                left: ${SIZE_MAP[size].inProgress.left};
              `}
              round="full"
            />
          ) : (
            <Box
              height={SIZE_MAP[size].inProgress.size}
              width={SIZE_MAP[size].inProgress.size}
              css={`
                position: relative;
                bottom: ${SIZE_MAP[size].inProgress.top};
                left: ${SIZE_MAP[size].inProgress.left};
              `}
              background={color ?? 'bg-2'}
              round="full"
            />
          )
        ) : (
          ''
        )}
      </Stack>
    </Box>
  )
}

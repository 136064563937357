import { ReactNode } from 'react'

import { Box } from '../layout'
import { ListItem } from '../list-item'
import { Text } from '../typography'

export type CardProps = {
  type?: 'small'
  title: string
  titleSuffix?: string
  subtitle?: string
  onClick?: () => void
  onClickRemove?: (id: number) => void
  active?: boolean
  startComponent?: ReactNode
  endComponents?: ReactNode[]
  'data-testid'?: string
  a11yTitle?: string
  disabled?: boolean
  role?: string
}

// This component wraps multiple subtypes of cards. Currently we only have the Small Card but more are coming soon
export const Card = ({ type, ...props }: CardProps) => {
  if (type === 'small') {
    return <CardSmall {...props} />
  }
  return <CardSmall {...props} />
}

const CardSmall = ({
  title,
  titleSuffix,
  subtitle,
  onClick,
  startComponent,
  endComponents,
  disabled,
  active,
  'data-testid': testId,
  a11yTitle,
  role
}: CardProps) => {
  const textContent = (
    <Box>
      <Text truncate="tip" a11yTitle={title} tipPlacement="top" color="text" weight={500} size="15px">
        {title}
        {titleSuffix && (
          <Text color="text-light" size="15px">
            {' '}
            {titleSuffix}
          </Text>
        )}
      </Text>
      {subtitle && (
        <Text size="small" color="text-light">
          {subtitle}
        </Text>
      )}
    </Box>
  )

  return (
    <ListItem
      startComponent={startComponent}
      endComponents={endComponents}
      onClick={onClick}
      size="large"
      border={true}
      disabled={disabled}
      a11yTitle={a11yTitle ?? title}
      active={active}
      data-testid={testId}
      role={role}
    >
      {textContent}
    </ListItem>
  )
}

import { useCallback } from 'react'
import { useRecoilCallback, useRecoilTransaction_UNSTABLE } from 'recoil'
import { produce } from 'immer'
import { extend } from 'lodash'

import { runbookResponseState_INTERNAL, runbookVersionResponseState_INTERNAL, useTaskListRefetch } from '../models'
import {
  RunbookResponse,
  RunbookVersionCreateResponse,
  RunbookVersionImportResponse
} from 'main/services/api/data-providers/runbook-types'
import { useNavigateToVersion } from 'main/recoil/shared/nav-utils'

export const useProcessRunbookVersionResponse = () => {
  const processRunbookVersionCsvImportResponse = useProcessRunbookVersionCsvImportResponse()
  const processRunbookVersionCreateResponse = useProcessRunbookVersionCreateResponse()
  const navigateToVersion = useNavigateToVersion()

  return useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'create':
          processRunbookVersionCreateResponse(response as RunbookVersionCreateResponse)
          const currentVersionId = (response as RunbookVersionCreateResponse).runbook_version.id
          navigateToVersion(currentVersionId)
          break
        case 'runbook_version_import':
          processRunbookVersionCsvImportResponse(response as RunbookVersionImportResponse)
          break
        default:
          return
      }
    },
    [processRunbookVersionCsvImportResponse, processRunbookVersionCreateResponse]
  )
}

export const useProcessRunbookVersionCsvImportResponse = () => {
  const refetchTasks = useTaskListRefetch()

  // FIXME: seems wrong. we don't updeate the runbook state's current_version and runbook_version_id
  return useRecoilCallback(({ set }) => async (data: RunbookVersionImportResponse) => {
    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, data.runbook_version)
      })
    )

    refetchTasks()
  })
}

export const useProcessRunbookVersionCreateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookVersionCreateResponse) => {
    const { set } = transactionInterface

    // FIXME: seems wrong - doesn't update the runbook's runbook_version_id
    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        extend(draftRunbookResponse.runbook, { current_version: response.runbook_version })
      })
    )

    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, response.runbook_version)
      })
    )
  })
}

import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { DashboardRunbookList } from 'main/components/workspace/pages/runbooks-dashboard'
import { useDashboardRunbookListPanel } from 'main/context/panel-context'

export const DashboardRunbookListPanel = () => {
  const { dashboardId, accountId } = useParams()
  const { params, clearPanel } = useDashboardRunbookListPanel()

  // TODO: remove when runbook feature flag is turned on
  const onClickRunbookItem = useCallback(() => {
    clearPanel()
    localStorage.setItem('previousTargetState', 'app.root.runbooks.dashboard')
    localStorage.setItem('previousQuery', JSON.stringify({ dashboardId, accountId }))
  }, [dashboardId, clearPanel])

  return (
    <>
      {params !== null && clearPanel !== null && (
        <DashboardRunbookList params={params} onClose={clearPanel} onClickRunbookItem={onClickRunbookItem} />
      )}
    </>
  )
}

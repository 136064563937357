import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useFormState } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Box, Button, colors, Form, Heading, Message, TextInput } from '@cutover/react-ui'
import { AuthenticationFormWrapper } from './authentication-form-wrapper'
import { FooterLinkText } from './login-styled'
import { useLanguage } from 'main/services/hooks'
import { RequestPasswordResetPayload, useRequestPasswordReset } from 'main/services/queries/use-request-password-reset'

type PasswordForgotFormType = Pick<RequestPasswordResetPayload, 'email'>

export const PasswordForgotForm = () => {
  const [emailSent, setEmailSent] = useState(false)
  const [serverError, setServerError] = useState('')
  const navigate = useNavigate()
  const { t } = useLanguage('authentication')

  const { register, handleSubmit, getValues, control } = useForm<PasswordForgotFormType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(
      yup.object({
        email: yup.string().email('incompleteForm').required('incompleteForm')
      })
    ),
    shouldFocusError: false
  })

  const { errors } = useFormState({ control })

  const passwordReset = useRequestPasswordReset()

  const handlePasswordReset = (data: PasswordForgotFormType) => {
    if (serverError) setServerError('')
    passwordReset.mutate(data, {
      onSuccess: () => {
        setEmailSent(true)
      },
      onError: () => setServerError('invalidCredentials')
    })
  }

  return (
    <>
      <Box width="320px">
        <Heading
          as="h1"
          color={colors.white}
          css={`
            margin: 12px 0px 24px;
            text-align: center;
          `}
          weight={300}
        >
          {t('forgotPasswordHeading')}
        </Heading>
        <Form onSubmit={handleSubmit(handlePasswordReset)}>
          {emailSent ? (
            <AuthenticationFormWrapper>
              <Message
                type="info"
                message={t('passwordResetEmailInfo', {
                  email:
                    getValues('email').length < 18 ? getValues('email') : `${getValues('email').substring(0, 16)}...`
                })}
              />
            </AuthenticationFormWrapper>
          ) : (
            <>
              <AuthenticationFormWrapper>
                {(!!errors.email || serverError) && (
                  <Box margin={{ bottom: '12px' }}>
                    <Message type="error" message={t(errors.email?.message || serverError)} />
                  </Box>
                )}
                <Box pad={{ horizontal: 'small', top: 'small' }}>
                  <TextInput
                    {...register('email')}
                    hasError={!!errors.email}
                    required
                    label={t('emailInputLabel')}
                    data-testid="email-input"
                    tooltipText={t('emailTooltip')}
                  />
                </Box>
                <Box pad="small">
                  <Button
                    primary
                    full
                    type="submit"
                    data-testid="submit-button"
                    label={t('submit')}
                    loadingLabel={t('submitting')}
                    icon={'arrow-forward'}
                    loading={passwordReset.isLoading}
                  />
                </Box>
              </AuthenticationFormWrapper>
            </>
          )}
        </Form>
      </Box>
      <Box direction="row" gap="medium" alignSelf="center" margin={{ top: '12px' }}>
        <FooterLinkText onClick={() => navigate('/login')}>{t('returnToLogin')}</FooterLinkText>
      </Box>
    </>
  )
}

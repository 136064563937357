import { RunbooksDashboardSchedule } from 'main/components/workspace/pages/runbooks-dashboard/runbooks-dashboard-schedule'
import { useRunbookDashboardSchedulePanel } from 'main/context/panel-context'

export const RunbooksDashboardSchedulePanel = () => {
  const { accountId, dashboardId, currentUserId, clearPanel } = useRunbookDashboardSchedulePanel()

  return (
    <>
      {accountId !== null && dashboardId !== null && currentUserId !== null && clearPanel !== null && (
        <RunbooksDashboardSchedule
          accountId={accountId}
          dashboardId={dashboardId}
          currentUserId={currentUserId}
          onClose={clearPanel}
        />
      )}
    </>
  )
}

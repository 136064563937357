import { useEffect, useState } from 'react'
import { flatMap } from 'lodash'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import {
  LegacyBox as Box,
  LegacyFlex as Flex,
  LoadingPanel,
  px2rem,
  SearchBar,
  LegacyText as Text
} from '@cutover/react-ui'
import { SnippetList } from './snippet-list'
import { SnippetsNotFound } from '../shared/snippets-not-found'
import { useLanguage } from 'main/services/hooks/use-language'
import { RunbookTemplateStatus } from 'main/services/queries/types'
import { Snippet, useSnippets } from 'main/services/queries/use-snippets'

export type LinkedSnippet = {
  id: number
  name: string
  archived: boolean
  tasks_count: number
  template_status: RunbookTemplateStatus
}

type SelectSnippetProps = {
  linkedSnippets: LinkedSnippet[]
}

export const SelectSnippet = ({ linkedSnippets }: SelectSnippetProps) => {
  const [snippets, setSnippets] = useState<LinkedSnippet[] | Snippet[]>(linkedSnippets)
  const [searchValue, setSearchValue] = useState('')
  const [isSearchCleared, setIsSearchCleared] = useState(false)
  const [atBottom, setAtBottom] = useState(false)
  const { accountId = null } = useParams()

  const { isFetching, isFetchingNextPage, data, hasNextPage, fetchNextPage } = useSnippets(
    accountId,
    {
      offset: 0,
      limit: 20,
      q: searchValue,
      has_tasks: true,
      template_status: 'template_approved',
      sort_dir: 'desc',
      fields: ['id', 'name', 'tasks_count']
    },
    linkedSnippets?.length === 0
  )

  const totalResults = searchValue ? (data ? data.pages[0].meta.total_results : null) : null

  useEffect(() => {
    if (data && linkedSnippets?.length === 0) {
      const snippets = flatMap(data.pages, r => r.snippets)
      setSnippets(snippets)
    }
  }, [data])

  const { t } = useLanguage('runbook', { keyPrefix: 'snippetListModal' })

  const clearSearch = () => {
    setIsSearchCleared(true)
  }

  useEffect(() => {
    if (isSearchCleared) {
      setIsSearchCleared(false)
    }
  }, [searchValue])

  const haslinkedSnippets = linkedSnippets.length > 0

  return (
    <>
      <Box margin={[20, 0, 0]}>
        <Text>{t('selectSnippetDescription')}</Text>
      </Box>
      <Content
        flexDirection="column"
        atBottom={atBottom}
        haslinkedSnippets={haslinkedSnippets}
        itemsCount={snippets?.length || 1}
      >
        {!haslinkedSnippets && (
          <SearchBar
            onSearch={setSearchValue}
            isSearchCleared={isSearchCleared}
            placeholder={t('searchPlaceholder')}
            open
            width="100%"
            autoFocus={false}
            suffix={
              totalResults !== null
                ? () => <Box css="font-size: 15px">{t('resultsCount', { count: totalResults })}</Box>
                : undefined
            }
          />
        )}
        <Box css="position: relative; flex-grow: 1">
          {snippets && snippets.length > 0 ? (
            <SnippetList
              snippets={snippets}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              setAtBottom={setAtBottom}
            />
          ) : !haslinkedSnippets && isFetching ? (
            <LoadingPanel />
          ) : (
            <SnippetsNotFound search={searchValue} clearSearch={clearSearch} />
          )}
        </Box>
      </Content>
    </>
  )
}

const Content = styled(Flex)<{ atBottom: boolean; itemsCount: number; haslinkedSnippets: boolean }>`
  height: ${({ itemsCount, haslinkedSnippets }) =>
    haslinkedSnippets
      ? itemsCount > 3
        ? px2rem(312)
        : px2rem(itemsCount * 72 + 20)
      : itemsCount > 3
      ? px2rem(352)
      : px2rem(itemsCount * 72 + 60)};
  margin-top: ${({ haslinkedSnippets }) => (haslinkedSnippets ? 0 : px2rem(20))};
  position: relative;
  ${({ atBottom }) =>
    !atBottom &&
    `
  &:after {
    content: '';
    position: absolute;
    bottom: ${px2rem(3)};
    left: 0;
    width: 100%;
    height: ${px2rem(40)};
    pointer-events: none;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  `}
`

import { createTaskFilterFunction, RunbookFilterType } from './filters'
import { TaskListTask } from 'main/services/queries/types'

export const late = createTaskFilterFunction('l', (task: TaskListTask, filters: RunbookFilterType): boolean => {
  const isLate = (task: TaskListTask): boolean => {
    const now = new Date().getTime() / 1000
    if (task.stage == 'startable') {
      const endPlanned = (task.start_latest_planned || 0) + task.duration
      const endlatestPlanned = task.end_fixed && task.end_fixed > endPlanned ? task.end_fixed : endPlanned
      return now + task.duration > endlatestPlanned
    }
    if (task.stage == 'default' && task.start_latest_planned) {
      return task.start_latest_planned < now
    }
    return false
  }
  return filters.l ? isLate(task) : !isLate(task)
})

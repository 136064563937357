import { useCallback } from 'react'

import {
  MultiSelect,
  MultiSelectProps,
  RenderMultiSelectOptionProps,
  TeamListItem,
  UserListItem
} from '@cutover/react-ui'
import { RunbookTeam } from 'main/services/queries/types'
import { RunbookVersionUser } from 'main/services/queries/use-runbook-versions'
import { useUserOrTeamDetailsEditPanel } from 'main/context/panel-context'

export type UserTeamSelectProps = Omit<
  MultiSelectProps<RunbookVersionUser | RunbookTeam>,
  'renderOption' | 'filterKeys' | 'options' | 'loadOptions'
> & {
  selectedUsers: RunbookVersionUser[]
  selectedTeams: RunbookTeam[]
  possibleUsersTeams: (RunbookVersionUser | RunbookTeam)[]
  draggable?: boolean
  setUsersAndTeamsValue: (value?: { userIds?: number[]; runbookTeamIds?: number[] }) => void
  readOnly?: boolean
}

export const UserTeamMultiSelect = ({
  selectedUsers,
  selectedTeams,
  possibleUsersTeams,
  draggable = false,
  setUsersAndTeamsValue,
  readOnly,
  ...props
}: UserTeamSelectProps) => {
  const { openPanel: openUserOrTeamDetailsPanel } = useUserOrTeamDetailsEditPanel()

  const renderOption = useCallback(
    (
      option: RunbookVersionUser | RunbookTeam,
      { onDeselect, selected, highlighted }: RenderMultiSelectOptionProps<RunbookVersionUser | RunbookTeam>
    ) => {
      if ('team_id' in option) {
        const teamOption = option as RunbookTeam
        return (
          <TeamListItem
            size="small"
            id={teamOption.id}
            name={teamOption.name}
            usersCount={teamOption.users_count}
            color={teamOption.color}
            active={highlighted}
            linked={teamOption.linked}
            onClick={
              !!selected
                ? () =>
                    openUserOrTeamDetailsPanel({
                      userOrTeam: teamOption
                    })
                : undefined
            }
            // @ts-ignore
            onClickRemove={!readOnly && onDeselect ? () => onDeselect(teamOption.id) : undefined}
            draggable={draggable}
          />
        )
      } else {
        const userOption = option as RunbookVersionUser
        return (
          <UserListItem
            size="small"
            id={userOption.id}
            firstName={userOption.first_name}
            lastName={userOption.last_name}
            online={userOption.online}
            color={userOption.color}
            active={highlighted}
            status={userOption.status}
            // @ts-ignore
            onClickRemove={!readOnly && onDeselect ? () => onDeselect(userOption.id) : undefined}
            draggable={draggable}
          />
        )
      }
    },
    [readOnly, openUserOrTeamDetailsPanel, draggable]
  )

  const handleChange = useCallback(
    (val: (RunbookVersionUser | RunbookTeam)[] | undefined) => {
      const runbookTeamIds = val?.filter(v => 'team_id' in v).map(v => v.id)
      const userIds = val?.filter(v => 'first_name' in v).map(v => v.id)
      setUsersAndTeamsValue({ userIds, runbookTeamIds })
    },
    [setUsersAndTeamsValue]
  )

  const getOptionId = (item: RunbookVersionUser | RunbookTeam) => {
    if ('team_id' in item) {
      return item.id
    } else {
      return `${item.id}-${item.name}`
    }
  }

  return (
    <MultiSelect<RunbookVersionUser | RunbookTeam>
      showMoreThreshold={10}
      {...props}
      options={possibleUsersTeams}
      optionToString={option => ('team_id' in option ? `${option.name}` : `${option.first_name} ${option.last_name}`)}
      filterKeys={['first_name', 'last_name', 'handle', 'name']}
      icon="user-add"
      valueKey={getOptionId}
      placeholder={props.placeholder}
      renderOption={renderOption}
      readOnly={readOnly}
      value={[...selectedTeams, ...selectedUsers]}
      onChange={handleChange}
      label={props.label}
    />
  )
}

import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import Cookies from 'js-cookie'
import { isEmpty } from 'lodash'
import { Controller, useForm, useFormState } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

import { toSnakeCase } from '@cutover/api'
import {
  Box,
  Button,
  colors,
  Form,
  Heading,
  Message,
  PhoneNumber,
  PhoneNumberValue,
  TextInput
} from '@cutover/react-ui'
import { AuthenticationFormWrapper } from './authentication-form-wrapper'
import { LoadingPage } from 'main/components/authentication/loading-page'
import { useGlobalConfig, useLanguage } from 'main/services/hooks'
import {
  SSOUserInviteVerification,
  useEmailUserInviteVerification,
  UserToVerify,
  verifyUserByInviteToken
} from 'main/services/queries/use-user-invite-verification'

export type UserVerifyForm = {
  email: string
  firstName: string
  lastName: string
  countryCode: string
  phoneNumber: PhoneNumberValue
  memorableWord: string
  password: string
  passwordConfirmation: string
}

type Provider = 'email' | 'saml' | undefined

export const UserVerifyForm = () => {
  const navigate = useNavigate()
  const [userToVerify, setUserToVerify] = useState<UserToVerify | undefined>(undefined)
  const [serverErrors, setServerErrors] = useState<string[] | undefined>(undefined)
  const [provider, setProvider] = useState<Provider>(undefined)
  const [showMemorableWord, setShowMemorableWord] = useState(false)
  const [searchParams] = useSearchParams()
  const { countries } = useGlobalConfig()
  const { t } = useLanguage('authentication')

  const invitationToken = searchParams.get('invitation_token')

  const commonValidationSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    phoneNumber: yup.object({
      code: yup.string(),
      number: yup
        .string()
        .nullable()
        .default(null)
        .test('number', t('validationError.phoneNumberMinLength'), val => {
          if (val && val.length > 0 && val.length < 7) {
            return false
          }
          return true
        })
    }),
    memorableWord: yup.string().when('phoneNumber', {
      is: (phone: PhoneNumberValue) => phone?.number && phone.number.length > 0,
      then: schema => schema.min(4, t('validationError.memorableWordMinLength'))
    })
  })

  const emailValidationSchema = yup.object().shape({
    password: yup.string().required(),
    passwordConfirmation: yup
      .string()
      .required()
      .oneOf([yup.ref('password')], t('validationError.passwordsDoNotMatch'))
  })

  const validationSchema =
    provider === 'email' ? commonValidationSchema.concat(emailValidationSchema) : commonValidationSchema

  const { register, handleSubmit, control, reset, getValues, watch, clearErrors } = useForm<UserVerifyForm>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    shouldFocusError: false
  })
  const { errors } = useFormState({ control })

  useEffect(() => {
    if (invitationToken) {
      Cookies.remove('auth_headers')
      const fetchUser = async () => {
        const user = await verifyUserByInviteToken(invitationToken).catch(() => {
          navigate('/login')
        })
        if (user) {
          reset({
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            phoneNumber: {
              code: user.country_code,
              number: user.mobile_number
            },
            password: '',
            passwordConfirmation: ''
          })
          setUserToVerify(user)
          setProvider(user.provider as Provider)
        }
      }
      fetchUser()
    } else {
      navigate('/login')
    }
  }, [invitationToken])

  useEffect(() => {
    const phoneNumber = getValues('phoneNumber')
    if (phoneNumber?.number && phoneNumber.number.length > 0) {
      setShowMemorableWord(true)
    } else {
      setShowMemorableWord(false)
      if (!isEmpty(errors.phoneNumber)) {
        clearErrors(['memorableWord', 'phoneNumber'])
      }
    }
  }, [watch(['phoneNumber'])])

  const emailUserInviteVerification = useEmailUserInviteVerification({
    onError: ({ errors }) => {
      setServerErrors(errors)
    },
    onSuccess: () => {
      localStorage.setItem('userVerifySuccess', 'true')
      navigate('/login')
    }
  })

  const clearServerErrors = () => {
    setServerErrors(undefined)
  }

  const handleAcceptInvite = (data: UserVerifyForm) => {
    if (invitationToken) {
      const dataForUserVerification: UserToVerify = toSnakeCase({
        id: userToVerify?.id,
        invitationToken: invitationToken,
        provider: provider,
        uniqueId: userToVerify?.unique_id,
        firstName: data.firstName,
        lastName: data.lastName,
        inputEmail: data.email,
        secretKey: data.memorableWord,
        countryCode: data.phoneNumber?.code || '',
        mobileNumber: data.phoneNumber?.number || '',
        password: data.password || '',
        passwordConfirmation: data.passwordConfirmation || ''
      })
      if (provider === 'saml') {
        SSOUserInviteVerification(dataForUserVerification)
      } else {
        emailUserInviteVerification.mutate(dataForUserVerification)
      }
    }
  }

  const formatError = () => {
    if (serverErrors) {
      if (serverErrors.length === 1) return serverErrors[0]
      return `<strong>${t('errorsFound', { amount: serverErrors.length })}</strong><ul>
        ${serverErrors.map(err => `\n<li style="list-style-type:circle;margin-left:19px;">${err}</li>`).join('')}</ul>`
    }
    return t('incompleteForm')
  }

  return (
    <>
      {!provider ? (
        <LoadingPage />
      ) : (
        <>
          <Box width="320px">
            <Heading
              as="h1"
              color={colors.white}
              css={`
                margin: 12px 0px 24px;
                text-align: center;
              `}
              weight={300}
            >
              {t('acceptInvitation')}
            </Heading>
            <Form onSubmit={handleSubmit(handleAcceptInvite)}>
              <AuthenticationFormWrapper>
                {!isEmpty(errors) || serverErrors ? <Message type="error" message={formatError()} /> : null}
                <Box pad={{ horizontal: 'small' }}>
                  <Box width="300px" direction="row" css="padding-top: 12px;" justify="between">
                    <Box>
                      <TextInput
                        {...register('firstName')}
                        hasError={Boolean(errors.firstName)}
                        required
                        label={t('firstNameLabel')}
                        width="130px"
                      />
                    </Box>
                    <Box>
                      <TextInput
                        {...register('lastName')}
                        hasError={Boolean(errors.lastName)}
                        required
                        label={t('lastNameLabel')}
                        width="130px"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box pad={{ horizontal: 'small' }} css="position: relative;">
                  <TextInput
                    {...register('email')}
                    hasError={Boolean(errors.email)}
                    disabled
                    label={t('emailInputLabel')}
                    data-testid="email-input"
                    tooltipText={t('emailTooltip')}
                  />
                </Box>
                <Box pad="small" css="position: relative;">
                  <Controller
                    name={'phoneNumber'}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PhoneNumber
                        onChange={onChange}
                        hasError={Boolean(errors.phoneNumber?.number)}
                        inlineError={errors.phoneNumber?.number?.message}
                        countries={countries}
                        value={value}
                        label={t('mobileNumberLabel')}
                        tooltipText={t('mobileNumberTooltip')}
                        required={false}
                      />
                    )}
                  />
                </Box>
                {showMemorableWord ? (
                  <Box pad={{ horizontal: 'small' }} css="position: relative;">
                    <TextInput
                      {...register('memorableWord')}
                      hasError={Boolean(errors.memorableWord)}
                      inlineError={errors.memorableWord?.message}
                      required
                      label={t('memorableWordLabel')}
                      tooltipText={t('memorableWordTooltip')}
                    />
                  </Box>
                ) : null}
                {provider === 'email' ? (
                  <>
                    <Box pad={{ horizontal: 'small' }} css="position: relative;">
                      <TextInput
                        {...register('password')}
                        hasError={Boolean(errors.password)}
                        type="password"
                        required
                        label={t('passwordCreate')}
                      />
                    </Box>
                    <Box pad={{ horizontal: 'small' }} css="position: relative;">
                      <TextInput
                        {...register('passwordConfirmation')}
                        hasError={Boolean(errors.passwordConfirmation)}
                        type="password"
                        required
                        label={t('passwordCreateConfirm')}
                      />
                    </Box>
                  </>
                ) : null}
                <Box pad="small">
                  <Button
                    primary
                    full
                    type="submit"
                    data-testid="submit-button"
                    onClick={() => clearServerErrors()}
                    label={t('submitAcceptInvitation')}
                    icon="arrow-forward"
                  />
                </Box>
              </AuthenticationFormWrapper>
            </Form>
          </Box>
        </>
      )}
    </>
  )
}

import { memo, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  Box,
  Checkbox,
  DateTimePicker,
  DayTimePicker,
  LegacyFormField as FormField,
  Modal,
  RadioboxGroup,
  Text
} from '@cutover/react-ui'
import { RunbookValidation } from 'main/components/runbook/modals/run-modals/runbook-validation'
import { useLanguage } from 'main/services/hooks'
import { RunbookEditRunbook } from 'main/services/queries/types'
import { RunbookUpdatePayload } from 'main/services/queries/use-update-runbook'
import {
  useValidateRunbook,
  ValidatedErrors,
  ValidatedWarnings,
  ValidateRunbookErrorResponse
} from 'main/services/queries/use-validate-runbook'

type RunbookEditTimingModeFieldsProps = {
  runbook: RunbookEditRunbook
  disabled?: boolean
  readOnly?: boolean
}

export const RunbookEditTimingModeFields = memo(({ runbook, disabled, readOnly }: RunbookEditTimingModeFieldsProps) => {
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false)
  const [validationErrors, setValidationErrors] = useState<ValidatedErrors | null>(null)
  const [validationWarnings, setValidationWarnings] = useState<ValidatedWarnings | null>(null)
  const [validateRunbookEnabled, setValidateRunbookEnabled] = useState(false)

  const { t } = useLanguage('runbooks')
  const { control, formState, watch, register, setValue } = useFormContext<RunbookUpdatePayload>()
  const isTemplate = watch('runbook.is_template')
  const autoStart = watch(`runbook.runbook_versions_attributes.${0}.auto_start`)
  const runbookTimezone = watch('runbook.timezone')
  const runbookTimingMode = watch('runbook.timing_mode')
  // display date options if start_scheduled is dirty
  const startScheduledIsDirty = formState.dirtyFields.runbook?.runbook_versions_attributes?.[0].start_scheduled
  const lowerCaseTimezone = runbookTimezone?.toLowerCase()

  useValidateRunbook({
    runbookId: runbook.id,
    runbookVersionId: runbook.current_version.id,
    onError: response => {
      const { errors: responseErrors } = response as { errors: ValidateRunbookErrorResponse; status: 422 }
      const { errors, warnings } = responseErrors
      const { status } = response

      // Open model, only if we have errors
      if (status === 422 && errors && Object.keys(errors).length > 0) {
        setValue(`runbook.runbook_versions_attributes.${0}.auto_start`, false)
        setIsValidationModalOpen(true)
        setValidationWarnings(warnings)
        setValidationErrors(errors)
      }
    },
    enabled: validateRunbookEnabled
  })

  useEffect(() => {
    if (autoStart && !runbook.current_version.auto_start) {
      setValidateRunbookEnabled(true)
    }

    if (!autoStart) {
      setValidateRunbookEnabled(false)
    }
  }, [autoStart])

  useEffect(() => {
    setValidateRunbookEnabled(false)
  }, [runbook.id])

  useEffect(() => {
    // If runbook is set to template, auto-start should be set back to false
    if (isTemplate && autoStart) {
      setValue(`runbook.runbook_versions_attributes.${0}.auto_start`, false)
    }
  }, [autoStart, isTemplate])

  return (
    <>
      <Controller
        name="runbook.timing_mode"
        control={control}
        render={() => (
          <RadioboxGroup
            name="runbook-timing-mode"
            label={t('fields.timingMode.label')}
            helpText={t('fields.timingMode.tooltip')}
            direction="row"
            value={runbookTimingMode}
            disabled // always
            options={[
              { value: 'scheduled', label: t('fields.timingMode.radioOptions.scheduled.label') },
              { value: 'unscheduled', label: t('fields.timingMode.radioOptions.unscheduled.label') }
            ]}
          />
        )}
      />

      {runbook.current_version.timing_mode === 'scheduled' ? (
        <>
          <Controller
            name={`runbook.runbook_versions_attributes.${0}.start_scheduled`}
            control={control}
            render={({ field: { onChange, value, ref, onBlur } }) => (
              <DateTimePicker
                required
                hasError={!!formState.errors.runbook?.runbook_versions_attributes?.[0]?.start_scheduled}
                value={!!value ? new Date(value) : null}
                onChange={date => (!!date ? onChange(date.toISOString()) : onChange(null))}
                label={t('fields.scheduledStart.label')}
                timezone={lowerCaseTimezone === 'automatic' ? null : runbookTimezone}
                disabled={disabled}
                readOnly={readOnly}
                inputRef={ref}
                onBlur={onBlur}
              />
            )}
          />

          {runbook.template_type !== 'snippet' && !isTemplate && (
            <Box margin={{ bottom: '16px' }}>
              <Controller
                control={control}
                name={`runbook.runbook_versions_attributes.${0}.auto_start`}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    label={t('fields.autoStart.label')}
                    aria-label={t('fields.autoStart.label')}
                    onChange={onChange}
                    checked={value}
                    disabled={
                      disabled || readOnly || !!runbook.current_version.run || !!runbook.linked_runbook_details?.id
                    }
                    helpText={t('fields.autoStart.helpText')}
                  />
                )}
              />
            </Box>
          )}
          <Controller
            name={`runbook.runbook_versions_attributes.${0}.end_scheduled`}
            control={control}
            render={({ field: { onChange, value, ref, onBlur } }) => (
              <DateTimePicker
                hasError={!!formState.errors.runbook?.runbook_versions_attributes?.[0]?.end_scheduled}
                value={!!value ? new Date(value) : null}
                onChange={date => (!!date ? onChange(date.toISOString()) : onChange(null))}
                label={t('fields.scheduledEnd.label')}
                timezone={lowerCaseTimezone === 'automatic' ? null : runbookTimezone}
                disabled={disabled}
                readOnly={readOnly}
                inputRef={ref}
                onBlur={onBlur}
              />
            )}
          />

          {startScheduledIsDirty && (
            <Box css="padding-bottom: 17.5px;">
              <FormField css="margin-top: -5px; padding-bottom: 4px;">
                <Text size="small" color="text-light">
                  {t('fields.dateOptions.label')}
                </Text>
                <Checkbox
                  label={t('fields.dateOptions.shiftTimesOption.label')}
                  aria-label={t('fields.dateOptions.shiftTimesOption.label')}
                  {...register('shift_time')}
                  // TODO: distinguish between disabled and readonly
                  disabled={disabled || readOnly || runbook.current_version.timing_mode !== 'scheduled'}
                />
              </FormField>
            </Box>
          )}
          <Controller
            name={`runbook.runbook_versions_attributes.${0}.end_planned`}
            control={control}
            render={({ field: { onChange, value, ref, onBlur } }) => (
              <DateTimePicker
                hasError={!!formState.errors.runbook?.runbook_versions_attributes?.[0]?.end_planned}
                value={!!value ? new Date(value) : null}
                onChange={date => (!!date ? onChange(date.toISOString()) : onChange(null))}
                label={t('fields.plannedEnd.label')}
                timezone={lowerCaseTimezone === 'automatic' ? null : runbookTimezone}
                disabled={true}
                tooltipText={t('fields.plannedEnd.helpText')}
                inputRef={ref}
                onBlur={onBlur}
              />
            )}
          />
        </>
      ) : (
        // unscheduled runbook
        <>
          <Controller
            name={`runbook.runbook_versions_attributes.${0}.start_planned`}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <DayTimePicker
                  hasError={!!formState.errors.runbook?.runbook_versions_attributes?.[0]?.start_planned}
                  value={!!value ? new Date(value) : new Date()}
                  // for runbooks, day zero starting point is the same as the value (runbook.start_planned)
                  dayZeroStartValue={value ? new Date(value) : new Date()}
                  onChange={date => (!!date ? onChange(date.toISOString()) : onChange(null))}
                  disableDayPickerOnly
                  timezone={lowerCaseTimezone === 'automatic' ? null : runbookTimezone}
                  disabled={disabled}
                  readOnly={readOnly}
                />
              )
            }}
          />

          {runbook.template_type !== 'snippet' && !isTemplate && (
            <Box margin={{ bottom: '16px' }}>
              <Checkbox
                label={t('fields.autoStart.label')}
                aria-label={t('fields.autoStart.label')}
                {...register(`runbook.runbook_versions_attributes.${0}.auto_start`)}
                disabled // always
                helpText={t('fields.autoStart.helpText')}
              />
            </Box>
          )}
        </>
      )}

      <Modal
        title={t('validateRunbookModal.title')}
        open={isValidationModalOpen}
        onClose={() => setIsValidationModalOpen(false)}
        hasCancelButton={false}
        confirmText={t('validateRunbookModal.ok')}
        confirmIcon="check"
        onClickConfirm={() => setIsValidationModalOpen(false)}
      >
        <RunbookValidation
          closeModal={() => setIsValidationModalOpen(false)}
          errors={validationErrors}
          warnings={validationWarnings}
        />
      </Modal>
    </>
  )
})

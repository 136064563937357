import { RunbooksSummary } from 'main/components/workspace/pages/runbooks-timeline/runbooks-summary'
import { useRunbooksTimelineSummaryPanel } from 'main/context/panel-context'

export const RunbooksTimelineSummaryPanel = () => {
  const { runbooks, clearPanel, selectedDates } = useRunbooksTimelineSummaryPanel()

  return (
    <>
      {runbooks !== null && selectedDates !== null && (
        <RunbooksSummary runbooks={runbooks} onClickClose={clearPanel} selectedDates={selectedDates} />
      )}
    </>
  )
}

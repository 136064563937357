import { createTaskFilterFunction, RunbookFilterType } from './filters'
import { TaskListTask } from 'main/services/queries/types'

export const sourceRunbook = createTaskFilterFunction(
  'rbc',
  (task: TaskListTask, filters: RunbookFilterType): boolean => {
    return (
      task.runbook_component_id !== null &&
      filters.rbc !== undefined &&
      filters.rbc?.includes(task.runbook_component_id)
    )
  }
)

import { useParams } from 'react-router-dom'

import { LoadingPanel } from '@cutover/react-ui'
import { RunbookEdit } from 'main/components/shared/runbook-edit/runbook-edit'
import { useRunbookEditPanel } from 'main/context/panel-context'
import { useRunbookEdit } from 'main/services/queries/use-runbook-edit'

export const RunbookEditPanel = () => {
  const { accountId: slug } = useParams()
  const { runbookId, clearPanel, panel } = useRunbookEditPanel()
  const { data: runbookData } = useRunbookEdit(runbookId, slug)

  if (panel?.panel !== 'runbook-edit') {
    return null
  } else {
    return runbookData?.runbook && clearPanel !== null ? (
      <RunbookEdit runbook={runbookData.runbook} onClose={clearPanel} />
    ) : (
      <LoadingPanel />
    )
  }
}

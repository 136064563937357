import * as yup from 'yup'

import { useLanguage } from 'main/services/hooks'

const certificateRegex =
  /^(?:(?!-{3,}(?:BEGIN|END) CERTIFICATE)[\s\S])*(-{3,}BEGIN CERTIFICATE(?:(?!-{3,}END CERTIFICATE)[\s\S])*?-{3,}END CERTIFICATE-{3,})(?![\s\S]*?-{3,}BEGIN CERTIFICATE[\s\S]+?-{3,}END CERTIFICATE[\s\S]*?$)/

export const samlConfigFormValidationSchema = (t: typeof useLanguage, mode?: string) => {
  return yup.object().shape({
    idp_sso_service_url: yup
      .string()
      .required(t('samlConfigurations.formData.idpSsoServiceServiceUrl.errors.requiredError'))
      .matches(/^http/, t('samlConfigurations.formData.idpSsoServiceServiceUrl.errors.regexError')),
    allowed_clock_drift: yup
      .number()
      .required()
      .typeError(t('samlConfigurations.formData.allowedClockDrift.errors.typeError')),
    name: yup
      .string()
      .required(t('samlConfigurations.formData.displayName.errors.requiredError'))
      .max(16, t('samlConfigurations.formData.displayName.errors.lengthError')),
    idp_cert_multi: yup.object().shape({
      signing: yup.array().of(yup.string()).min(1, t('samlConfigurations.formData.idpCertMulti.errors.missingError'))
    }),
    certificate: yup
      .string()
      .matches(certificateRegex, t('samlConfigurations.formData.idpCertMulti.errors.formatError')),
    custom_acs_url:
      mode === 'edit'
        ? yup.string().matches(/^http/, t('samlConfigurations.formData.customAcsUrl.errors.httpRegexError'))
        : yup.string().notRequired(),
    sp_entity_id:
      mode === 'edit'
        ? yup.string().matches(/^http/, t('samlConfigurations.formData.spEntityId.errors.httpRegexError'))
        : yup.string().notRequired(),
    default: yup.bool().required()
  })
}

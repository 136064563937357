import { useCallback } from 'react'
import { mapValues } from 'lodash'
import { selectorFamily, useRecoilValue } from 'recoil'

import { runbookPermissionsState } from './runbook/runbook'
import { runbookVersionPermissionsState } from './runbook-version/runbook-version'
import { taskListMetaState } from './tasks/task-list'
import { useCurrentUser } from 'main/recoil/current-user'
import { GetRunbookResponseType } from 'main/services/queries/use-runbook'
import { RunbookVersionMeta } from 'main/services/queries/use-runbook-versions'
import { TaskGetTaskResponse } from 'main/services/queries/use-task'
import { TaskListResponseType } from 'main/services/queries/use-tasks'
import { RunbookStreamShowResponse } from 'main/services/api/data-providers/runbook-types'

type RunbookPermissionsResponse = GetRunbookResponseType['meta']['permissions']
type RunbookVersionPermissionsResponse = RunbookVersionMeta['permissions']['runbook_version']
type RunsPermissionsResponse = RunbookVersionMeta['permissions']['runs']
type TasksPermissionsResponse = TaskListResponseType['meta']['permissions']
type StreamsPermissionsResponse = RunbookVersionMeta['permissions']['streams']

export const runbookPermission = selectorFamily<boolean, { attribute: keyof RunbookPermissionsResponse }>({
  key: 'permission:runbook',
  get:
    ({ attribute }) =>
    ({ get }) => {
      const permissions = get(runbookPermissionsState)
      return !!permissions[attribute]?.length
    }
})

const runbookVersionPermission = selectorFamily<boolean, { attribute: keyof RunbookVersionPermissionsResponse }>({
  key: 'permission:runbook-version',
  get:
    ({ attribute }) =>
    ({ get }) => {
      const permissions = get(runbookVersionPermissionsState)
      return !!permissions.runbook_version[attribute]?.length
    }
})

export const tasksPermission = selectorFamily<boolean, { attribute: keyof TasksPermissionsResponse }>({
  key: 'permission:tasks',
  get:
    ({ attribute }) =>
    ({ get }) => {
      const tasksMeta = get(taskListMetaState)
      return !!tasksMeta.permissions[attribute]?.length
    }
})

const runsPermission = selectorFamily<boolean, { attribute: keyof RunsPermissionsResponse }>({
  key: 'permission:runs',
  get:
    ({ attribute }) =>
    ({ get }) => {
      const permissions = get(runbookVersionPermissionsState)
      return !!permissions.runs[attribute]?.length
    }
})

const streamsPermission = selectorFamily<boolean, { attribute: keyof StreamsPermissionsResponse }>({
  key: 'permission:streams',
  get:
    ({ attribute }) =>
    ({ get }) => {
      const permissions = get(runbookVersionPermissionsState)
      return !!permissions.streams[attribute]?.length
    }
})

export const useRunbookPermission = <TKey extends keyof RunbookPermissionsResponse>({
  attribute
}: {
  attribute: TKey
}) => useRecoilValue(runbookPermission({ attribute }))
export const useRunbookVersionPermission = <TKey extends keyof RunbookVersionPermissionsResponse>({
  attribute
}: {
  attribute: TKey
}) => useRecoilValue(runbookVersionPermission({ attribute }))
export const useTasksPermission = <TKey extends keyof TasksPermissionsResponse>({ attribute }: { attribute: TKey }) =>
  useRecoilValue(tasksPermission({ attribute }))
export const useRunsPermission = <TKey extends keyof RunsPermissionsResponse>({ attribute }: { attribute: TKey }) =>
  useRecoilValue(runsPermission({ attribute }))
export const useStreamsPermission = <TKey extends keyof StreamsPermissionsResponse>({
  attribute
}: {
  attribute: TKey
}) => useRecoilValue(streamsPermission({ attribute }))

export type TaskShowPermissions = ReturnType<typeof getTaskShowPermissions>
export type StreamShowPermissions = ReturnType<typeof getStreamShowPermissions>

export const useGetTaskShowPermissions = () => {
  const { id: currentUserId } = useCurrentUser()

  return useCallback((resp: TaskGetTaskResponse) => getTaskShowPermissions(resp, currentUserId), [currentUserId])
}

const getTaskShowPermissions = (resp: TaskGetTaskResponse, currentUserId: number) => {
  return mapValues(resp.meta.permissions, v => v?.includes(currentUserId))
}

export const useGetStreamShowPermissions = () => {
  const { id: currentUserId } = useCurrentUser()

  return useCallback(
    (resp: RunbookStreamShowResponse) => getStreamShowPermissions(resp, currentUserId),
    [currentUserId]
  )
}

const getStreamShowPermissions = (resp: RunbookStreamShowResponse, currentUserId: number) => {
  return mapValues(resp.meta.permissions, v => v?.includes(currentUserId))
}

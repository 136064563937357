import { ReactNode, SyntheticEvent } from 'react'
import { Box } from 'grommet'
import styled from 'styled-components/macro'

import { resolveColor } from '../theme'

type TaskItemLayoutProps = {
  'data-testid': string
  onClick?: (e: SyntheticEvent) => void
  onFocus?: (e: SyntheticEvent) => void
  draggable?: boolean
  highlight?: boolean
  loading?: boolean
  disabled?: boolean
  showBorderTop?: boolean
  hideStageIcon?: boolean
  prefixContent?: ReactNode
  dateContent?: ReactNode
  icon: ReactNode
  mainContentPrefix?: ReactNode
  mainContent: ReactNode
  mainContentSubLine?: ReactNode
  endContent?: ReactNode
  suffixContent?: ReactNode
}

export const TaskItemLayout = ({
  'data-testid': dataTestId,
  onClick,
  onFocus,
  draggable,
  highlight,
  loading,
  disabled,
  showBorderTop,
  prefixContent,
  dateContent,
  icon,
  mainContentPrefix,
  mainContent,
  mainContentSubLine,
  endContent,
  suffixContent
}: TaskItemLayoutProps) => (
  <TaskItemContainer
    data-testid={dataTestId}
    onClick={onClick}
    onFocus={onFocus}
    draggable={draggable}
    $loading={loading}
    highlight={highlight}
    disabled={disabled}
    showBorderTop={showBorderTop}
  >
    <Box width="30px" css="flex: 1 0 30px">
      {prefixContent}
    </Box>
    <Box width="110px" flex={false} align="end">
      {dateContent}
    </Box>
    <Box flex={false}>{icon}</Box>
    <Box direction="row" gap="xxsmall" align="center" flex="shrink" basis="100%" css="position: relative;">
      {mainContentPrefix}
      {mainContent}
      <Box css="position: absolute; top: 18px;" data-testid="task-content-box">
        {mainContentSubLine}
      </Box>
    </Box>
    <Box direction="row" gap="xsmall" align="center" css="flex: 0 0 auto;">
      {endContent}
      <Box width="32px" height="32px" alignSelf="center" flex={false}>
        {suffixContent}
      </Box>
    </Box>
  </TaskItemContainer>
)

const TaskItemContainer = styled(Box).attrs(() => ({
  align: 'center',
  direction: 'row',
  gap: 'xsmall',
  height: '52px',
  pad: { right: 'xsmall', top: 'xsmall', bottom: 'xsmall' }
}))<{ showBorderTop?: boolean; $loading?: boolean; highlight?: boolean; disabled?: boolean; hideStageIcon?: boolean }>`
  background-color: ${({ highlight, theme }) => highlight && resolveColor('primary-bg', theme)} !important;
  border-top: ${({ showBorderTop, theme }) => showBorderTop && `1px solid ${resolveColor('bg-2', theme)}`};
  margin-right: 16px;
  opacity: ${({ $loading, disabled }) => ($loading || disabled) && 0.5};
  outline: none;
  flex: 0 0 auto;

  .task-item-display-on-task-hover {
    display: none;
  }

  .task-item-remove-on-task-hover {
    display: block;
  }

  &:hover {
    background-color: ${({ theme }) => resolveColor('bg-1', theme)};

    .task-item-bg {
      background-color: ${({ theme }) => resolveColor('bg-1', theme)};
    }

    .task-item-display-on-task-hover {
      display: block;
    }

    .task-item-remove-on-task-hover {
      display: none;
    }
  }
`

import { keyBy } from 'lodash'
import { selector } from 'recoil'

import { runbookVersionMetaState } from './runbook-version'
import { GetRunbookVersionResponse } from 'main/services/queries/use-runbook-versions'

type RunbookComponent = GetRunbookVersionResponse['meta']['runbook_components'][number]

export const runbookComponentsState = selector<RunbookComponent[]>({
  key: 'runbook-components:list',
  get: ({ get }) => {
    return get(runbookVersionMetaState).runbook_components
  }
})

export const runbookComponentsStateLookup = selector<Record<RunbookComponent['id'], RunbookComponent>>({
  key: 'runbook-components:lookup',
  get: ({ get }) => {
    return keyBy(get(runbookComponentsState), 'id')
  }
})

import { Ref } from 'react'
import { produce } from 'immer'
import { selector, useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil'
import { extend } from 'lodash'

import { MenuListItemProps } from '@cutover/react-ui'
import { taskListResponseState_INTERNAL } from '../tasks/task-list'
import { runbookAtom } from 'main/recoil/shared/recoil-state-runbook-decorators'

export type ModalActiveTaskProgressionType =
  | { id: number; type: 'task-action' }
  | { id: number; type: 'task-override' }
  | { id: number; type: 'task-override-optional' }
  | { id: number; type: 'task-override-fixed-start' }
  | { id: number; type: 'task-finish-confirm' }
  | { id: never; type: 'task-start-block' }
export type ModalActiveType =
  | ModalActiveTaskProgressionType
  | { id: number; type: 'snippet-add' }
  | { id: number; type: 'linked-runbook-add' }
  | { id: number[]; type: 'tasks-delete' }
  | { id: number[]; type: 'tasks-skip' }

export type TaskListMenu = {
  taskId?: number
  type?: 'options' | 'predecessors' | 'successors'
  triggerRef?: Ref<HTMLElement>
  keyPrefix?: string
  isOpen?: boolean
  items: MenuListItemProps[]
  minWidth?: number
  maxWidth?: number
  maxHeight?: number
}

export type RunbookViewStateType<TModalHistory extends object = {}> = {
  loadingIds: Record<number, boolean | undefined>
  selectedIds: number[]
  modal: {
    active?: ModalActiveType
    history: (ModalActiveType & { context?: TModalHistory })[]
  }
  taskList: {
    isMenuOpen: boolean
    menu: TaskListMenu
  }
}

export const runbookViewState_INTERNAL = runbookAtom<RunbookViewStateType>({
  key: 'runbook-view-state',
  default: {
    loadingIds: {},
    selectedIds: [],
    modal: {
      active: undefined,
      history: []
    },
    taskList: {
      isMenuOpen: false,
      menu: {
        taskId: undefined,
        type: undefined,
        items: [],
        triggerRef: undefined,
        keyPrefix: undefined,
        minWidth: undefined,
        maxWidth: undefined,
        maxHeight: undefined
      }
    }
  }
})

/* -------------------------------------------------------------------------- */
/*                                SELECTED IDS                                */
/* -------------------------------------------------------------------------- */

export const useSelectedIdsValue = () => {
  const { selectedIds } = useRecoilValue(runbookViewState_INTERNAL)
  return selectedIds
}
export const useSetSelectedIds = () => {
  // :!: warning: this function is for setters and callbacks only. Do NOT listen to any state here :!:
  const selectedIdsValueCallback = useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
  })

  const selectedIdAdd = useRecoilCallback(({ set }) => (id: number) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds.push(id)
      })
    )
  })
  const selectedIdRemove = useRecoilCallback(({ set }) => (id: number) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds = draft.selectedIds.filter(draftId => draftId !== id)
      })
    )
  })
  const selectedIdToggle = useRecoilCallback(({ snapshot }) => async (id: number) => {
    const selectedIds = (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
    selectedIds.includes(id) ? selectedIdRemove(id) : selectedIdAdd(id)
  })
  const selectedIdsOverwrite = useRecoilCallback(({ set }) => (ids: number[]) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds = ids
      })
    )
  })
  const selectedIdsSelectAll = useRecoilCallback(({ snapshot }) => async () => {
    const { tasks } = await snapshot.getPromise(taskListResponseState_INTERNAL)
    selectedIdsOverwrite(tasks.map(({ id }) => id))
  })

  const selectedIdsRemoveAll = () => selectedIdsOverwrite([])

  const selectedIdsToggleAll = useRecoilCallback(({ snapshot }) => async () => {
    const selectedIds = (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
    selectedIds.length ? selectedIdsRemoveAll() : selectedIdsSelectAll()
  })

  return {
    selectedIdAdd,
    selectedIdRemove,
    selectedIdToggle,
    selectedIdsOverwrite,
    selectedIdsSelectAll,
    selectedIdsRemoveAll,
    selectedIdsToggleAll,
    selectedIdsValueCallback
  }
}

/* -------------------------------------------------------------------------- */
/*                                ACTIVE MODAL                                */
/* -------------------------------------------------------------------------- */

export const useModalActiveValue = () => useRecoilValue(runbookViewState_INTERNAL).modal.active
export const useModalHistoryValue = <TModalHistory extends object = {}>() =>
  (useRecoilValue(runbookViewState_INTERNAL) as RunbookViewStateType<TModalHistory>).modal.history
export const useSetModalActiveState = () => {
  // :!: warning: this function is for setters and callbacks only. Do NOT listen to any state here :!:
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  const modalActiveValueCallback = useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).modal.active
  })
  const modalHistoryValueCallback = useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).modal.history
  })

  const modalClose = () =>
    setViewState(previousState =>
      produce(previousState, draft => {
        draft.modal.active = undefined
        draft.modal.history = []
      })
    )
  const modalOpen = (modal: ModalActiveType) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        draft.modal.active = modal
      })
    )
  const modalContinue = (nextModal: ModalActiveType, previousModal: ModalActiveType & { context?: object }) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        draft.modal.active = nextModal
        draft.modal.history.push(previousModal)
      })
    )

  return { modalClose, modalOpen, modalContinue, modalActiveValueCallback, modalHistoryValueCallback }
}

/* -------------------------------------------------------------------------- */
/*                                ACTIVE MENU                                 */
/* -------------------------------------------------------------------------- */

export const menuState = selector({
  key: 'runbook-menu-state',
  get: ({ getCallback }) => {
    const setMenu = getCallback(({ set }) => (menu: RunbookViewStateType['taskList']['menu']) => {
      set(runbookViewState_INTERNAL, previousState =>
        produce(previousState, draft => {
          extend(draft.taskList.menu, menu)
        })
      )
    })

    const clearMenu = getCallback(({ set }) => () => {
      set(runbookViewState_INTERNAL, previousState =>
        produce(previousState, draft => {
          draft.taskList.isMenuOpen = false
          draft.taskList.menu.taskId = undefined
          draft.taskList.menu.type = undefined
          draft.taskList.menu.triggerRef = undefined
          draft.taskList.menu.keyPrefix = undefined
          draft.taskList.menu.items = []
          draft.taskList.menu.minWidth = undefined
          draft.taskList.menu.maxWidth = undefined
          draft.taskList.menu.maxHeight = undefined
        })
      )
    })

    const setMenuOpenState = getCallback(({ set }) => (isOpen: boolean) => {
      set(runbookViewState_INTERNAL, previousState =>
        produce(previousState, draft => {
          draft.taskList.isMenuOpen = isOpen
        })
      )
    })

    return { setMenu, clearMenu, setMenuOpenState }
  }
})

export const useMenu = () => {
  const { taskList } = useRecoilValue(runbookViewState_INTERNAL)
  return taskList
}

export const useSetMenuState = () => {
  return useRecoilValue(menuState)
}

/* -------------------------------------------------------------------------- */
/*                                 LOADING IDS                                */
/* -------------------------------------------------------------------------- */

export const useLoadingIdsAllValue = () => useRecoilValue(runbookViewState_INTERNAL).loadingIds

export const useLoadingIdValue = (id: number) => useRecoilValue(runbookViewState_INTERNAL).loadingIds[id]

export const useSetLoadingIdsState = () => {
  // :!: : this function is for setters and callbacks only. Do NOT listen to any state here :!:
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  const loadingIdsValueCallback = useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).loadingIds
  })

  const loadingIdAdd = (id: number) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        draft.loadingIds[id] = true
      })
    )
  const loadingIdRemove = (id: number) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        delete draft.loadingIds[id]
      })
    )
  const loadingIdAddBulk = (ids: number[]) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        ids.forEach(id => (draft.loadingIds[id] = true))
      })
    )
  const loadingIdRemoveBulk = (ids: number[]) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        ids.forEach(id => delete draft.loadingIds[id])
      })
    )
  return { loadingIdAdd, loadingIdRemove, loadingIdAddBulk, loadingIdRemoveBulk, loadingIdsValueCallback }
}

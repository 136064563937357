import { ReactNode, useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'
import { useSetRecoilState } from 'recoil'

import { operations, useComponentPropsStateHandler } from './apps-component-props-helper'
import { AppsChannelResponse } from 'main/components/apps/apps-types'
import { appComponentProps, appHeaderView, appViews } from 'main/recoil/apps/apps-atoms'
import { useCurrentUser } from 'main/recoil/current-user'
import { useWebsockets } from 'main/services/hooks'

const APPS_RESOURCE_CHANNEL_NAME = 'AppResourceChannel'

export const AppsResourceChannelSubscriber = ({ children }: { children: ReactNode }) => {
  const componentPropsStateHandler = useComponentPropsStateHandler()
  const setAppViews = useSetRecoilState(appViews)
  const setHeaderApp = useSetRecoilState(appHeaderView)
  const setComponentProps = useSetRecoilState(appComponentProps)
  const [isTemplate, setIsTemplate] = useState<boolean | undefined>(false)
  const [runbookPermissions, setRunbookPermissions] = useState<{ [x: string]: number[] } | undefined>(undefined)
  const [subscriptionId, setSubscriptionId] = useState<string>('')
  const websockets = useWebsockets()
  const { runbookId } = useParams()
  const user = useCurrentUser()

  const appsResourceChannelSubscription = (id: string) =>
    websockets.findExistingSubscription(APPS_RESOURCE_CHANNEL_NAME, id)

  useMount(() => {
    eventManager.on('runbook-data', handleRunbookData)
  })

  useUnmount(() => {
    eventManager.off('runbook-data', handleRunbookData)
    websockets.findExistingSubscription(APPS_RESOURCE_CHANNEL_NAME, subscriptionId)?.unsubscribe()
  })

  useEffect(() => {
    if (!runbookPermissions || isTemplate) {
      return
    }

    const capability = runbookPermissions.update.includes(user.id) ? 'edit' : 'read'
    const id = `${runbookId}:${capability}`
    setSubscriptionId(id)

    if (!appsResourceChannelSubscription(id)) {
      websockets.subscribe(APPS_RESOURCE_CHANNEL_NAME, id, {
        received: data => {
          updateAppViewsState(data.response)
        }
      })
    }
  }, [runbookPermissions])

  const handleRunbookData = ({
    permissions,
    runbook
  }: {
    permissions: { [x: string]: number[] }
    runbook: { is_template: boolean }
  }) => {
    setRunbookPermissions(permissions)
    setIsTemplate(runbook.is_template)
  }

  const updateAppViewsState = (response: AppsChannelResponse) => {
    const appId = response.app_id
    const resourceId = response.resource_id
    const view = response.view
    const type = view.type
    const context = `${resourceId}-${appId}`

    componentPropsStateHandler({ response, context, setComponentProps })

    if (!operations.includes(type)) {
      switch (type) {
        case 'header':
          setHeaderApp({ view, appId, resourceId })
          break
        case 'panel':
          setAppViews(views => {
            const existingView = views[context]
            if (existingView.allow_bulk_refresh) {
              const updatedView = {
                ...response.view,
                appId,
                resourceId,
                visible: existingView.visible,
                order: existingView?.order
              }
              return { ...views, [context]: updatedView }
            } else {
              return views
            }
          })
          break
        default:
          console.warn(`App resource channel does not accept ${type} type nodes.`)
      }
    }
  }

  return <>{children}</>
}

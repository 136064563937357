import { selectorFamily, Snapshot, useRecoilCallback, useRecoilValue } from 'recoil'

import { runbookPermission, tasksPermission } from '../permissions'
import { taskListTaskState } from './task-list'
import { isVersionCurrentState, isVersionEditable, runbookVersionState } from '../runbook-version/runbook-version'
import { teamsStateLookup } from '../runbook-version/teams'
import { currentUserIdState } from 'main/recoil/current-user'
import { runbookViewState_INTERNAL } from '../view/view'
import { runbookRunbookTypeState, runbookState } from '../runbook/runbook'
import { isStreamPermittedState, newTaskStreamState } from '../runbook-version/streams'

export const getTaskItemPermissionsState = selectorFamily({
  key: 'task:get-permissions:sync',
  get:
    (taskId: number) =>
    ({ getCallback }) => {
      /* --------------------------- Task started successors --------------------------- */

      const hasStartedSuccessors =
        ({ snapshot }: { snapshot: Snapshot }) =>
        () => {
          const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
          const allowedAddBelowStages = ['default', 'startable']
          for (const sId of task.successor_ids) {
            const successorTask = snapshot.getLoadable(taskListTaskState(sId)).getValue()
            if (!allowedAddBelowStages.includes(successorTask.stage)) return true
          }

          return false
        }
      const getTaskHasStartedSuccessors = getCallback(hasStartedSuccessors)

      /* ------------------------------- Task admin ------------------------------- */

      const isTaskAdmin =
        ({ snapshot }: { snapshot: Snapshot }) =>
        () => {
          return snapshot.getLoadable(runbookPermission({ attribute: 'update' })).getValue()
        }

      /* ------------------------------ Task user ids ----------------------------- */

      const taskUserIds =
        ({ snapshot }: { snapshot: Snapshot }) =>
        () => {
          const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
          const teamLookup = snapshot.getLoadable(teamsStateLookup).getValue()

          return task.runbook_team_ids.flatMap(teamId => teamLookup[teamId]?.user_ids ?? []).concat(task.user_ids)
        }

      /* ------------------------- Task start permissions ------------------------- */

      const canTaskStartWhenStartable =
        ({ snapshot }: { snapshot: Snapshot }) =>
        () => {
          const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()

          // base requirements
          if (!snapshot.getLoadable(isVersionCurrentState).getValue()) return false
          const { loadingIds } = snapshot.getLoadable(runbookViewState_INTERNAL).getValue()
          if (loadingIds[taskId]) return false

          if (isTaskAdmin({ snapshot })()) return true

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const currentUserId = snapshot.getLoadable(currentUserIdState).getValue()!
          const userIds = taskUserIds({ snapshot })()
          if (!userIds.includes(currentUserId)) return false

          if (task.start_requirements === 'any_can_start') return true
          if (!task.started_user_ids?.includes(currentUserId)) return true

          return false
        }

      const getCanStartTaskWhenStartable = getCallback(canTaskStartWhenStartable)

      const canTaskStart =
        ({ snapshot }: { snapshot: Snapshot }) =>
        () => {
          const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
          if (!canTaskStartWhenStartable({ snapshot })()) return false
          return task.stage === 'startable'
        }

      /* -------------------------- Task end permissions -------------------------- */

      const taskCanFinish =
        ({ snapshot }: { snapshot: Snapshot }) =>
        () => {
          const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()

          // base requirements
          if (task.stage !== 'in-progress') return false
          if (!snapshot.getLoadable(isVersionCurrentState).getValue()) return false
          const { loadingIds } = snapshot.getLoadable(runbookViewState_INTERNAL).getValue()
          if (loadingIds[taskId]) return false

          if (!!task.linked_resource?.id) return false

          if (isTaskAdmin({ snapshot })()) return true

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const currentUserId = snapshot.getLoadable(currentUserIdState).getValue()!
          const userIds = taskUserIds({ snapshot })()
          if (!!currentUserId && !userIds.includes(currentUserId)) return false

          if (task.end_requirements === 'any_can_end') return true
          if (task.end_requirements === 'all_must_end' && !task.ended_user_ids?.includes(currentUserId)) return true
          if (task.end_requirements === 'same_must_end' && task.started_user_ids?.includes(currentUserId)) return true

          return false
        }

      /* ---------------------- Task create after permissions --------------------- */

      /* --------------------- Without started successor check -------------------- */

      // we need this check related to the visual of the icon. We show the icon with an add + on hover
      // even in the case it isn't valid to add due to having started successors. In that case it is
      // clicked to add, we show a toast warning.

      const canTaskCreateAfterWithoutSuccessorCheck =
        ({ snapshot }: { snapshot: Snapshot }) =>
        () => {
          const isRunbookEditable = snapshot.getLoadable(isVersionEditable).getValue()
          if (!isRunbookEditable) return false

          const { loadingIds } = snapshot.getLoadable(runbookViewState_INTERNAL).getValue()
          if (loadingIds[taskId]) return false

          const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
          const isTaskStreamPermitted = snapshot
            .getLoadable(isStreamPermittedState({ streamId: task.stream_id }))
            .getValue()
          if (!isTaskStreamPermitted) return false

          return true
        }
      const getCanCreateTaskAfterWithoutSuccessorCheck = getCallback(canTaskCreateAfterWithoutSuccessorCheck)

      /* ---------- Full check for atempting create after (used in menu) ---------- */

      const canTaskCreateAfter =
        ({ snapshot }: { snapshot: Snapshot }) =>
        () => {
          const createCreateIfNoStartedSuccessors = canTaskCreateAfterWithoutSuccessorCheck({ snapshot })()
          if (!createCreateIfNoStartedSuccessors) return false

          const hasStartedSuccessorsCheck = hasStartedSuccessors({ snapshot })()
          if (hasStartedSuccessorsCheck) return false

          return true
        }
      const getCanCreateTaskAfter = getCallback(canTaskCreateAfter)

      /* ----------------- Task create after from icon permissions ---------------- */

      const getCanDisplayCreateAfterFromIcon = getCallback(({ snapshot }) => () => {
        if (!canTaskCreateAfterWithoutSuccessorCheck({ snapshot })()) return false

        const { dynamic: isDynamic } = snapshot.getLoadable(runbookRunbookTypeState).getValue()
        if (!isDynamic) return true

        const { stage: taskStage } = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
        const { stage: runbookStage } = snapshot.getLoadable(runbookVersionState).getValue()
        return taskStage === 'default' || runbookStage === 'paused'
      })

      /* --------------------- Task linked create permissions --------------------- */

      const getCanCreateLinkedTaskAfter = getCallback(({ snapshot }) => () => {
        if (!canTaskCreateAfter({ snapshot })()) return false

        const { template_type: templateType, linked_runbook_details: linkedRunbookDetails } = snapshot
          .getLoadable(runbookState)
          .getValue()

        const isSnippet = templateType === 'snippet'
        const isChildRunbook = Object.keys(linkedRunbookDetails || {}).length !== 0

        return !isChildRunbook && !isSnippet
      })

      /* ---------------------- Task snippet add permissions ---------------------- */

      const getCanAddSnippetAfter = getCallback(({ snapshot }) => () => {
        const canAddSnippet = snapshot.getLoadable(tasksPermission({ attribute: 'add_snippet' })).getValue()
        if (!canAddSnippet) return false

        const { template_type: templateType } = snapshot.getLoadable(runbookState).getValue()
        if (templateType === 'snippet') return false

        if (!snapshot.getLoadable(isVersionEditable).getValue()) return false
        if (hasStartedSuccessors({ snapshot })()) return false

        return true
      })

      /* ------------------------ Task delete permissions ------------------------ */

      const canDeleteTask =
        ({ snapshot }: { snapshot: Snapshot }) =>
        () => {
          const isRunbookEditable = snapshot.getLoadable(isVersionEditable).getValue()
          if (!isRunbookEditable) return false

          const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()
          const isTaskStreamPermitted = snapshot
            .getLoadable(isStreamPermittedState({ streamId: task.stream_id }))
            .getValue()
          if (!isTaskStreamPermitted) return false

          return task.stage === 'default' || task.stage === 'startable'
        }

      const getCanDeleteTask = getCallback(canDeleteTask)

      /* ---------------------- Task action permissions check --------------------- */

      const getCanProgressTask = getCallback(({ snapshot }) => () => {
        const { loadingIds } = snapshot.getLoadable(runbookViewState_INTERNAL).getValue()
        if (loadingIds[taskId]) return false

        const { run } = snapshot.getLoadable(runbookVersionState).getValue()
        const isActiveRun = run?.mode === 'active'
        if (!isActiveRun) return false

        const task = snapshot.getLoadable(taskListTaskState(taskId)).getValue()

        if (task.stage === 'complete') return false
        if (task.stage === 'default') return false
        if (task.stage === 'startable' && !canTaskStart({ snapshot })()) return false
        if (task.stage === 'in-progress' && !taskCanFinish({ snapshot })()) return false
        if (task.errors?.length) return false

        return true
      })

      return {
        getCanStartTaskWhenStartable,
        getCanCreateTaskAfter,
        getCanCreateTaskAfterWithoutSuccessorCheck,
        getCanDisplayCreateAfterFromIcon,
        getCanCreateLinkedTaskAfter,
        getCanAddSnippetAfter,
        getCanDeleteTask,
        getCanProgressTask,
        getTaskHasStartedSuccessors
      }
    }
})

export const useGetTaskItemPermissions = (id: number) => {
  return useRecoilValue(getTaskItemPermissionsState(id))
}

export const useCanStartTaskWhenStartable = (id: number) => {
  const { getCanStartTaskWhenStartable } = useRecoilValue(getTaskItemPermissionsState(id))
  return getCanStartTaskWhenStartable()
}

export const useGetCanCreateTask = () => {
  return useRecoilCallback(({ snapshot }) => (prevTaskStreamId?: number) => {
    const newTaskStreamId = snapshot.getLoadable(newTaskStreamState({ prevTaskStreamId })).getValue()
    const isTaskStreamPermitted = snapshot.getLoadable(isStreamPermittedState({ streamId: newTaskStreamId })).getValue()
    if (!isTaskStreamPermitted) return false
    const { stage: runStage } = snapshot.getLoadable(runbookVersionState).getValue()
    const { dynamic: isDynamic } = snapshot.getLoadable(runbookRunbookTypeState).getValue()
    return !!newTaskStreamId && (['planning', 'paused'].includes(runStage) || (runStage !== 'complete' && isDynamic))
  })
}

export const useCanCreateRootTask = () => {
  const canCreateTask = useGetCanCreateTask()
  return canCreateTask()
}

export const useNewTaskStreamId = ({ prevTaskStreamId }: { prevTaskStreamId?: number } = {}) => {
  return useRecoilValue(newTaskStreamState({ prevTaskStreamId }))
}

import { useCallback } from 'react'
import { selectorFamily, useRecoilCallback, useRecoilValue } from 'recoil'
import { fromUnixTime, isFuture } from 'date-fns'

import { useNotify } from '@cutover/react-ui'
import {
  ModalActiveType,
  runbookViewState_INTERNAL,
  useModalActiveValue,
  useSetLoadingIdsState,
  useSetModalActiveState
} from '../view/view'
import { taskListLookupState, taskListTaskState } from './task-list'
import { useLanguage } from 'main/services/hooks'
import { currentUserState } from 'main/recoil/current-user'
import { runbookIdState, runbookPermissionsState, runbookState } from '../runbook/runbook'
import { runbookVersionIdState, runbookVersionMetaState } from '../runbook-version/runbook-version'
import { runState } from '../runbook-version/run'
import { CurrentUser } from 'main/services/queries/use-get-validate-token'
import { accountResponseState_INTERNAL } from '../account/account'
import { runbookTypeState } from '../account/runbook-types'
import { finishTask, startTask, TaskFinishPayload, TaskStartPayload } from 'main/services/queries/use-task'
import { Account, CustomField, TaskListTask } from 'main/services/queries/types'
import { getServerErrorMessages } from 'main/services/api'
import { useProcessTaskFinishResponse, useProcessTaskStartResponse } from '../../updaters/task-operations'
import { RunbookTaskFinishResponse, RunbookTaskStartResponse } from 'main/services/api/data-providers/runbook-types'
import { useTaskNotifications } from '../../updaters/use-task-notifications'

export const useTaskProgression = () => {
  // :!: warning: do NOT listen to any state within this function :!:
  const { modalOpen, modalClose, modalHistoryValueCallback } = useSetModalActiveState()
  const { t } = useLanguage('tasks')
  const { loadingIdAdd, loadingIdRemove } = useSetLoadingIdsState()
  const processTaskStartResponse = useProcessTaskStartResponse()
  const processTaskFinishResponse = useProcessTaskFinishResponse()
  const { taskStartNotification, taskFinishNotification } = useTaskNotifications()
  const notify = useNotify()

  const onSkipTasks = useRecoilCallback(({ snapshot }) => async (ids?: number[]) => {
    const tasks = await snapshot.getPromise(taskListLookupState)
    const selectedIds = (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
    const skipIds = ids || selectedIds

    const error = skipIds.find(id => {
      const task = tasks[id]
      return task.stage === 'complete' || task.completion_type === 'complete_skipped'
    })

    if (error) {
      notify.warning(t('list.unableToSkipWarning.message'), { title: t('list.unableToSkipWarning.title') })
    } else {
      modalOpen({ type: 'tasks-skip', id: skipIds })
    }
  })

  const startOrFinishTask = useRecoilCallback(
    ({ snapshot }) =>
      async (id: number, payload: Partial<TaskStartPayload | TaskFinishPayload> = {}) => {
        const progressionState = await snapshot.getPromise(taskProgressionState(id))
        const modalHistory = await modalHistoryValueCallback()
        const runbookId = await snapshot.getPromise(runbookIdState)
        const runbookVersionId = await snapshot.getPromise(runbookVersionIdState)
        const override = !!modalHistory.find(modal => {
          if (modal.type === 'task-override-fixed-start') return true
          if (modal.type === 'task-override' && !progressionState?.optional) return true
          return modal.type === 'task-override' && (modal.context as { override: boolean } | undefined)?.override
        })

        modalClose() // Important: don't move this above anything that uses the modalHistory otherwise it will clear it

        try {
          loadingIdAdd(id)
          const startable = progressionState?.stage === 'startable'
          const request = startable ? startTask : finishTask
          const response = await request(runbookId, runbookVersionId, id, {
            override,
            field_values_attributes: [],
            selected_successor_ids: [],
            ...payload
          })
          if (response) {
            if (startable) {
              processTaskStartResponse(response as RunbookTaskStartResponse)
              taskStartNotification(response as RunbookTaskStartResponse)
            } else {
              processTaskFinishResponse(response as RunbookTaskFinishResponse)
              taskFinishNotification(response as RunbookTaskFinishResponse)
            }
          }
          loadingIdRemove(id)
        } catch (e: any) {
          const errorMessage = getServerErrorMessages(
            e,
            t(
              progressionState?.stage === 'startable'
                ? 'taskActionModal.errorStartMessage'
                : 'taskActionModal.errorFinishMessage'
            )
          )[0]
          loadingIdRemove(id)
          notify.error(errorMessage, {
            title: t(
              progressionState?.stage === 'startable'
                ? 'taskActionModal.errorStartTitle'
                : 'taskActionModal.errorFinishTitle'
            )
          })
        }
      },
    []
  )

  const resolveProgressionModalCallback = useCallback(
    ({
      accountName,
      customFields,
      task,
      progressionState,
      from,
      currentUser,
      runbookId
    }: {
      task: TaskListTask
      progressionState: TaskProgressionState
      accountName: Account['name']
      customFields: CustomField[]
      currentUser: CurrentUser
      runbookId: number
      from?: ModalActiveType
    }): ModalActiveType | undefined => {
      if (!progressionState) return

      if ((!from || !from.type || !from.type.includes('task-override')) && progressionState.override)
        return {
          type: progressionState.override === 'fixed-start' ? 'task-override-fixed-start' : 'task-override',
          id: task.id
        }

      if (progressionState.stage === 'startable') {
        const hasCfs = customFields.find(
          ({ archived, apply_to, account_name, constraint }) =>
            !archived &&
            apply_to.slug === 'task_start' &&
            (account_name === accountName || account_name === 'Global') &&
            (!constraint || (constraint?.task_type_id || []).includes(task.task_type_id))
        )

        return hasCfs ? { type: 'task-action', id: task.id } : undefined
      }

      if (progressionState.stage === 'finishable') {
        const hasCfs = customFields.find(
          cf =>
            !cf.archived &&
            cf.apply_to.slug === 'task_end' &&
            (cf.account_name === accountName || cf.account_name === 'Global') &&
            (!cf.constraint || (cf.constraint?.task_type_id || []).includes(task.task_type_id))
        )

        if (
          !hasCfs &&
          !from &&
          !currentUser?.frontend_user_setting?.data?.task_finish_confirm_hidden?.includes(runbookId)
        )
          return { type: 'task-finish-confirm', id: task.id }

        return hasCfs ? { type: 'task-action', id: task.id } : undefined
      }
    },
    []
  )

  const resolveProgressionModalRecoilCallback = useRecoilCallback(
    ({ snapshot }) =>
      async (id: number, { from }: { from?: ModalActiveType } = {}): Promise<ModalActiveType | undefined> => {
        const progressionState = await snapshot.getPromise(taskProgressionState(id))
        const {
          account,
          meta: { custom_fields }
        } = await snapshot.getPromise(accountResponseState_INTERNAL)
        const task = await snapshot.getPromise(taskListTaskState(id))
        const currentUser = (await snapshot.getPromise(currentUserState)) as CurrentUser
        const runbookId = await snapshot.getPromise(runbookIdState)

        const customFields = custom_fields?.map(cf => ({
          ...cf,
          constraint: cf.constraint ? JSON.parse(cf.constraint) : null
        }))

        return resolveProgressionModalCallback({
          task,
          progressionState,
          accountName: account.name,
          customFields,
          currentUser,
          runbookId,
          from
        })
      }
  )

  return { onSkipTasks, resolveProgressionModalCallback, resolveProgressionModalRecoilCallback, startOrFinishTask }
}

type TaskProgressionState =
  | {
      stage: 'startable' | 'finishable'
      override?: 'fixed-start' | 'users-assigned'
      optional?: boolean
    }
  | undefined
const taskProgressionState = selectorFamily<TaskProgressionState, number>({
  key: 'tasks:progression',
  get:
    id =>
    ({ get }) => {
      const currentUser = get(currentUserState) as CurrentUser
      const runbook = get(runbookState)
      const runbookPermissions = get(runbookPermissionsState)
      const { runbook_teams } = get(runbookVersionMetaState)
      const runbookType = get(runbookTypeState(runbook.runbook_type_id))
      const run = get(runState)
      const task = get(taskListTaskState(id))

      if (
        task.stage === 'startable' &&
        !!task.start_fixed &&
        isFuture(fromUnixTime(task.start_fixed)) &&
        run?.run_type !== 'rehearsal'
      )
        return {
          stage: 'startable',
          override: 'fixed-start'
        }

      const assignedTeams = runbook_teams.filter(t => task.runbook_team_ids.includes(t.id))
      const usersAssignedToTask = [...new Set([...task.user_ids, ...assignedTeams.flatMap(t => t.user_ids)])]
      const currentUserAssigned = usersAssignedToTask.includes(currentUser.id)
      const hasUpdatePermissions = !!runbookPermissions.update.length

      if ((runbook.stage !== 'active' && !runbookType.dynamic) || ['complete', 'default'].includes(task.stage))
        return undefined
      const canFinishTask = (() => {
        if (task.stage !== 'in-progress') return false
        if (task.linked_resource?.id) return false
        if (hasUpdatePermissions) return true
        if (!currentUserAssigned) return false
        if (task.end_requirements === 'any_can_end') return true
        if (task.end_requirements === 'all_must_end' && !task.ended_user_ids.includes(currentUser.id)) return true
        if (task.end_requirements === 'same_must_end' && task.started_user_ids.includes(currentUser.id)) return true
        return false
      })()
      const canStartTask = (() => {
        if (task.stage !== 'startable') return false
        if (hasUpdatePermissions || (!hasUpdatePermissions && run?.run_type === 'rehearsal')) return true
        if (!currentUserAssigned) return false
        if (task.start_requirements === 'any_can_start') return true
        if (!task.started_user_ids.includes(currentUser.id)) return true
        return false
      })()

      const anyUserCanAction =
        (task.stage === 'startable' && task.start_requirements === 'any_can_start') ||
        (task.stage === 'in-progress' && task.end_requirements === 'any_can_end')

      const currentUserHasActioned =
        (task.stage === 'startable' && task.started_user_ids.includes(currentUser.id)) ||
        (task.stage === 'in-progress' && task.ended_user_ids.includes(currentUser.id))

      if (hasUpdatePermissions) {
        if (
          usersAssignedToTask.length > 0 &&
          (!currentUserAssigned ||
            (currentUserAssigned && currentUserHasActioned) ||
            (currentUserAssigned &&
              task.stage === 'in-progress' &&
              task.end_requirements === 'same_must_end' &&
              !task.started_user_ids.includes(currentUser.id)))
        )
          return { stage: canStartTask ? 'startable' : 'finishable', override: 'users-assigned' }

        if (
          currentUserAssigned &&
          usersAssignedToTask.length > 0 &&
          !anyUserCanAction &&
          task.end_requirements !== 'same_must_end'
        )
          return { stage: canStartTask ? 'startable' : 'finishable', override: 'users-assigned', optional: true }
      }

      if (canFinishTask) return { stage: 'finishable' }
      if (canStartTask) return { stage: 'startable' }
      return undefined
    }
})
export const useTaskProgressionState = (id: number) => {
  return useRecoilValue(taskProgressionState(id))
}

export const useIsTaskActionModalTask = (id: number) => {
  const activeModal = useModalActiveValue()
  return activeModal?.type === 'task-action' ? id === activeModal.id : undefined
}

import { isValidElement, memo, ReactNode } from 'react'
import { ClickEvent, MenuDivider, MenuHeader as ReactMenuHeader, MenuItem as ReactMenuItem } from '@szhsin/react-menu'
import styled from 'styled-components/macro'
import { LiteralUnion } from 'type-fest'

import { IconName } from '../icon'
import { ListItem, ListItemIcon, ListItemText } from '../list-item'
import { ColorProp, themeColor } from '../theme'

// Add the more common/standard menu item customizations here e.g., groupings, headings
// WARNING: DO NOT use this to add props here to create menus items that deviate from the standard
// list item UI. We'll add a render prop to account for custom renders assuming that is what we want to supports

export type MenuListItemProps = {
  label: string
  width?: 'small' | 'medium'
  selected?: boolean
  type?: 'checkbox'
  checked?: boolean
  disabled?: boolean
  // NOTE: this is just a visual cue -- treats like a non selecting checkbox. The renderer adds the click hadler that handles the logic for whether the (flat) items includes its children
  /** visual cue to show a down arrow at the end indicating it has child items */
  expandable?: boolean
  expanded?: boolean
  hasNext?: boolean
  destructive?: boolean
  icon?: IconName | ReactNode
  level?: number
  color?: LiteralUnion<ColorProp, string>
  // props from menu lib
  onClick?: (props: ClickEvent) => void
  // prop from menu lib. It's useful for helping identify which menu item is clicked when you listen the onItemClick event on root menu component.
  value?: any
  'data-testid'?: string
  iconColorProp?: string
}

// TODO: refactor color prop stuff after most scenarios are implemented
export const MenuListItem = memo(
  ({
    label,
    selected,
    level,
    icon,
    color,
    hasNext,
    expandable,
    expanded,
    destructive,
    width = 'small',
    iconColorProp,
    ...props
  }: MenuListItemProps) => {
    return (
      <ReactMenuItem
        {...props}
        css={`
          &:hover {
            .circular-motion {
              background-color: ${themeColor('bg-1')};
              width: 15px;
            }
          }
        `}
      >
        {renderProps => {
          const { checked, type, disabled, hover } = renderProps

          const isCheckboxIcon = type === 'checkbox' && !expandable
          const hasStartIcon = icon || isCheckboxIcon
          const baseColor = (color || 'text-light') as any
          const iconColor = iconColorProp ? iconColorProp : disabled ? 'text-disabled' : (selected || isCheckboxIcon && checked) ? 'primary' : type === 'checkbox' ? 'text-light' : baseColor // prettier-ignore
          const startIcon = type === 'checkbox' && checked ? 'checkbox-checked' : type === 'checkbox' ? 'checkbox' : (icon as IconName) // prettier-ignore
          const textColor = disabled ? 'text-disabled' : selected && !isCheckboxIcon ? 'primary' : baseColor // prettier-ignore
          const backgroundColor = selected && !isCheckboxIcon ? 'primary-bg' : hover ? 'bg-1' : undefined
          const expandableIconColor = selected ? 'primary' : disabled ? 'text-disabled' : baseColor

          return (
            <ListItem
              size="medium"
              width={width === 'small' ? '196px' : '320px'}
              background={backgroundColor}
              level={level}
              hasNext={hasNext}
              startComponent={
                isValidElement(icon) ? (
                  icon
                ) : hasStartIcon ? (
                  <ListItemIcon color={destructive ? 'message-error' : iconColor} icon={startIcon} />
                ) : undefined
              }
              endComponents={
                expandable
                  ? [
                      <ListItemIcon
                        size="medium"
                        icon={expanded ? 'caret-down' : 'caret-right'}
                        color={expandableIconColor}
                      />
                    ]
                  : undefined
              }
            >
              <ListItemText color={destructive ? 'message-error' : textColor} weight={selected ? 500 : 'normal'}>
                {label}
              </ListItemText>
            </ListItem>
          )
        }}
      </ReactMenuItem>
    )
  }
)

export const MenuListItemDivider = styled(MenuDivider)`
  padding-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${themeColor('bg-2')};
`

export const MenuListItemHeader = memo(({ label, width = 'small', ...props }: MenuListItemProps) => {
  return (
    <ReactMenuHeader>
      <ListItem data-testid={props['data-testid']} size="medium" width={width === 'small' ? '196px' : '320px'}>
        {/* TODO: theming for weights vs numbers */}
        <ListItemText color="text-light" weight={700} size="13px">
          {label}
        </ListItemText>
      </ListItem>
    </ReactMenuHeader>
  )
})

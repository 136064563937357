import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { atom, selector, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { useUnmount } from 'react-use'

import { AvatarSubject } from '@cutover/react-ui'
import { toggleRightPanel, useToggleRightPanel } from 'main/components/layout/layout-hooks'
import { PeoplePanelScreen } from 'main/components/runbook/right-panels/people-panel/people-panel'
import { RunbookData, SelectedDates } from 'main/components/workspace/pages/runbooks-timeline/types'
import { FolderListFolder, RunbookBaseRunbook, RunbookEditRunbook } from 'main/services/queries/types'

/** @remarks panel name to follow convention `${object}-${action}` */
type RightPanelType =
  | {
      panel: 'runbook-edit'
      onClose?: () => void
      runbookId: number
    }
  | {
      panel: 'runbook-copies'
      onClose?: () => void
      runbook: RunbookBaseRunbook
    }
  | {
      panel: 'runbook-linked'
      onClose?: () => void
      runbook: RunbookEditRunbook
    }
  | {
      panel: 'runbook-dashboard-schedule'
      onClose?: () => void
      accountId?: number
      dashboardId?: number
      currentUserId?: number
    }
  | {
      panel: 'runbooks-bulk-edit'
      onClose?: () => void
      runbookIds: number[]
    }
  | {
      panel: 'runbooks-timeline-summary'
      onClose?: () => void
      runbooks: RunbookData[]
      selectedDates: SelectedDates
    }
  | {
      panel: 'folder-edit'
      onClose?: () => void
      folderId: number
      folders?: FolderListFolder[]
    }
  | {
      panel: 'task-edit'
      onClose?: () => void
      taskId: number
    }
  | {
      panel: 'stream-edit'
      onClose?: () => void
      streamId: number
    }
  | {
      panel: 'dashboard-runbook-list'
      onClose?: () => void
      params: { [key: string]: any }
    }
  | {
      panel: 'saml-configurations-edit'
      onClose?: () => void
      samlId: number
    }
  | {
      panel: 'data-sources-edit'
      onClose?: () => void
      dataSourceId: number
    }
  | {
      panel: 'user-team-details-edit'
      onClose?: () => void
      userOrTeam?: any
      initialScreen?: PeoplePanelScreen
      accountId: number
    }
  | {
      panel: 'integration-settings-edit'
      onClose?: () => void
      integrationSettingId: number
    }
  | {
      panel: 'system-parameters-edit'
      onClose?: () => void
      systemParameterId: number
    }
  | {
      panel: 'connect-settings-edit'
      onClose?: () => void
      connectSettingId: number
    }

type RightPanelContextType = {
  panel: RightPanelType | null
  setPanel: (panel: RightPanelType) => void
  clearPanel: () => void
}

const RightPanelContext = createContext<RightPanelContextType>({
  panel: null,
  setPanel: () => {},
  clearPanel: () => {}
})

export const RightPanelProvider = ({
  children,
  initialState
}: {
  children: ReactNode
  initialState?: RightPanelType
}) => {
  const toggleRightPanel = useToggleRightPanel()
  const [panel, setPanel] = useState<RightPanelType | null>(initialState || null)
  const clearRecoilRightPanel = useResetRecoilState(rightPanelAtom)
  const setRecoilRightPanel = useSetRecoilState(rightPanelAtom)

  const clearPanel = useCallback(() => {
    toggleRightPanel(false)
    panel?.onClose?.()
    setPanel(null)
    clearRecoilRightPanel()
  }, [panel, toggleRightPanel, clearRecoilRightPanel])

  const setPanelCallback = useCallback(
    (panel: RightPanelType | null) => {
      setRecoilRightPanel(prev => {
        if (prev.panel === panel?.panel || (!prev.panel && !panel)) {
          return prev
        } else {
          return panel ? panel : { panel: null, onClose: () => {} }
        }
      })
      setPanel(panel)
    },
    [setRecoilRightPanel]
  )

  return (
    <RightPanelContext.Provider value={{ panel, setPanel: setPanelCallback, clearPanel }}>
      {children}
    </RightPanelContext.Provider>
  )
}

export const useRightPanel = () => useContext(RightPanelContext)

export const useBulkEditRunbooksPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({ runbookIds, onClose }: { runbookIds: number[]; onClose?: () => void }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'runbooks-bulk-edit',
        onClose,
        runbookIds
      })
    },
    [setPanel, toggleRightPanel]
  )

  return {
    runbookIds: panel?.panel === 'runbooks-bulk-edit' ? panel.runbookIds : null,
    openPanel,
    panel,
    clearPanel
  }
}

export const useRunbookEditPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({ runbookId, onClose }: { runbookId: number; onClose?: () => void }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'runbook-edit',
        onClose,
        runbookId
      })
    },
    [setPanel, toggleRightPanel]
  )

  return {
    runbookId: panel?.panel === 'runbook-edit' ? panel.runbookId : null,
    openPanel,
    panel,
    clearPanel
  }
}

export const useRunbooksTimelineSummaryPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({
      runbooks,
      onClose,
      selectedDates
    }: {
      runbooks: RunbookData[]
      onClose?: () => void
      selectedDates: SelectedDates
    }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'runbooks-timeline-summary',
        onClose,
        runbooks,
        selectedDates
      })
    },
    [setPanel, toggleRightPanel]
  )

  return {
    runbooks: panel?.panel === 'runbooks-timeline-summary' ? panel.runbooks : null,
    openPanel,
    panel,
    clearPanel,
    selectedDates: panel?.panel === 'runbooks-timeline-summary' ? panel.selectedDates : null
  }
}

export const useEditFolderPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({ folderId, folders, onClose }: { folderId: number; folders?: FolderListFolder[]; onClose?: () => void }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'folder-edit',
        onClose,
        folderId,
        folders
      })
    },
    [setPanel]
  )

  return {
    folderId: panel?.panel === 'folder-edit' ? panel.folderId : undefined,
    folders: panel?.panel === 'folder-edit' ? panel.folders : undefined,
    openPanel,
    panel,
    clearPanel
  }
}

export const useRunbookCopiesPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({ runbook, onClose }: { runbook: RunbookBaseRunbook; onClose?: () => void }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'runbook-copies',
        onClose,
        runbook
      })
    },
    [setPanel, toggleRightPanel]
  )

  return {
    runbook: panel?.panel === 'runbook-copies' ? panel.runbook : null,
    openPanel,
    panel,
    clearPanel
  }
}

export const useRunbookLinkedPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({ runbook, onClose }: { runbook: RunbookEditRunbook; onClose?: () => void }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'runbook-linked',
        onClose,
        runbook
      })
    },
    [setPanel, toggleRightPanel]
  )

  return {
    runbook: panel?.panel === 'runbook-linked' ? panel.runbook : null,
    openPanel,
    panel,
    clearPanel
  }
}

export const useRunbookDashboardSchedulePanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({
      accountId,
      dashboardId,
      currentUserId,
      onClose
    }: {
      accountId?: number
      dashboardId?: number
      currentUserId?: number
      onClose?: () => void
    }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'runbook-dashboard-schedule',
        onClose,
        accountId,
        dashboardId,
        currentUserId
      })
    },
    [setPanel]
  )

  return {
    accountId: panel?.panel === 'runbook-dashboard-schedule' ? panel.accountId || null : null,
    dashboardId: panel?.panel === 'runbook-dashboard-schedule' ? panel.dashboardId || null : null,
    currentUserId: panel?.panel === 'runbook-dashboard-schedule' ? panel.currentUserId || null : null,
    openPanel,
    panel,
    clearPanel
  }
}

export const useDashboardRunbookListPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({ params, onClose }: { params: { [key: string]: any }; onClose?: () => void }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'dashboard-runbook-list',
        onClose,
        params
      })
    },
    [setPanel, toggleRightPanel]
  )

  return {
    params: panel?.panel === 'dashboard-runbook-list' ? panel.params : null,
    openPanel,
    panel,
    clearPanel
  }
}

export const useSamlConfigEditPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({ samlId, onClose }: { samlId: number; onClose?: () => void }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'saml-configurations-edit',
        onClose,
        samlId
      })
    },
    [setPanel, toggleRightPanel]
  )

  return {
    samlId: panel?.panel === 'saml-configurations-edit' ? panel.samlId : null,
    openPanel,
    panel,
    clearPanel
  }
}

export const useDatasourceEditPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({ dataSourceId, onClose }: { dataSourceId: number; onClose?: () => void }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'data-sources-edit',
        onClose,
        dataSourceId
      })
    },
    [setPanel, toggleRightPanel]
  )

  return {
    dataSourceId: panel?.panel === 'data-sources-edit' ? panel.dataSourceId : null,
    openPanel,
    panel,
    clearPanel
  }
}

export const useIntegrationSettingEditPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({ integrationSettingId, onClose }: { integrationSettingId: number; onClose?: () => void }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'integration-settings-edit',
        onClose,
        integrationSettingId
      })
    },
    [setPanel, toggleRightPanel]
  )

  return {
    integrationSettingId: panel?.panel === 'integration-settings-edit' ? panel.integrationSettingId : null,
    openPanel,
    panel,
    clearPanel
  }
}

// ********* Recoil Panels

type EmptyPanelContent = {
  panel: null
  onClose?: () => void
}

type RightPanelAtom = RightPanelType | EmptyPanelContent

const rightPanelAtom = atom<RightPanelAtom>({
  key: 'right-panel-content',
  default: {
    panel: null,
    onClose: () => {}
  }
})

export const rightPanelState = selector({
  key: 'right-panel',
  get: ({ get, getCallback }) => {
    const openPanel = getCallback(({ set }) => (panelData: RightPanelAtom) => {
      toggleRightPanel(true)
      set(rightPanelAtom, panelData)
    })

    const closePanel = getCallback(({ set, snapshot }) => () => {
      const rightPanel = snapshot.getLoadable(rightPanelAtom).contents
      rightPanel.onClose?.()
      toggleRightPanel(false)
      set(rightPanelAtom, { panel: null })
    })

    const resetPanel = getCallback(({ reset }) => () => {
      reset(rightPanelAtom)
    })

    const panelAtom = get(rightPanelAtom)

    const isOpen = !!panelAtom.panel

    return {
      panel: panelAtom,
      isOpen,
      openPanel,
      closePanel,
      resetPanel
    }
  }
})

export const useEditTaskPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { clearPanel: closePanel } = useRightPanel()
  const { panel, openPanel: openPanelRecoil, resetPanel } = useRecoilValue(rightPanelState)

  const openPanel = useCallback(
    ({ taskId, onClose }: { taskId: number; onClose?: () => void }) => {
      toggleRightPanel(true)

      const panelData = {
        panel: 'task-edit' as const,
        onClose,
        taskId
      }

      openPanelRecoil(panelData)
    },
    [openPanelRecoil, toggleRightPanel]
  )

  return {
    taskId: panel?.panel === 'task-edit' ? panel.taskId : null,
    panel: panel?.panel === 'task-edit' ? panel : null,
    openPanel,
    closePanel,
    resetPanel
  }
}

export const useEditStreamPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { clearPanel: closePanel } = useRightPanel()
  const { panel, openPanel: openPanelRecoil, resetPanel } = useRecoilValue(rightPanelState)

  const openPanel = useCallback(
    ({ streamId, onClose }: { streamId: number; onClose?: () => void }) => {
      toggleRightPanel(true)

      const panelData = {
        panel: 'stream-edit' as const,
        onClose,
        streamId
      }

      openPanelRecoil(panelData)
    },
    [openPanelRecoil, toggleRightPanel]
  )

  return {
    streamId: panel?.panel === 'stream-edit' ? panel.streamId : null,
    panel: panel?.panel === 'stream-edit' ? panel : null,
    openPanel,
    closePanel,
    resetPanel
  }
}

export const useUserOrTeamDetailsEditPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { clearPanel: closePanel } = useRightPanel()
  const { panel, openPanel: openPanelRecoil, resetPanel } = useRecoilValue(rightPanelState)

  const openPanel = useCallback(
    ({
      userOrTeam,
      onClose,
      initialScreen
    }: {
      userOrTeam?: AvatarSubject | AvatarSubject[]
      onClose?: () => void
      initialScreen?: PeoplePanelScreen
    }) => {
      toggleRightPanel(true)

      const panelData = {
        panel: 'user-team-details-edit',
        onClose,
        userOrTeam,
        initialScreen
      }

      // @ts-ignore
      openPanelRecoil(panelData)
    },
    [openPanelRecoil, toggleRightPanel]
  )

  return {
    userOrTeam: panel?.panel === 'user-team-details-edit' ? panel.userOrTeam : null,
    initialScreen: panel?.panel === 'user-team-details-edit' ? panel.initialScreen : undefined,
    panel: panel?.panel === 'user-team-details-edit' ? panel : null,
    openPanel,
    closePanel,
    resetPanel
  }
}

export const useConnectSettingEditPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({ connectSettingId, onClose }: { connectSettingId: number; onClose?: () => void }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'connect-settings-edit',
        onClose,
        connectSettingId
      })
    },
    [setPanel, toggleRightPanel]
  )

  return {
    connectSettingId: panel?.panel === 'connect-settings-edit' ? panel.connectSettingId : null,
    openPanel,
    panel,
    clearPanel
  }
}

export const useSystemParameterEditPanel = () => {
  const toggleRightPanel = useToggleRightPanel()
  const { panel, setPanel, clearPanel } = useRightPanel()

  const openPanel = useCallback(
    ({ systemParameterId, onClose }: { systemParameterId: number; onClose?: () => void }) => {
      toggleRightPanel(true)

      setPanel({
        panel: 'system-parameters-edit',
        onClose,
        systemParameterId
      })
    },
    [setPanel, toggleRightPanel]
  )

  return {
    systemParameterId: panel?.panel === 'system-parameters-edit' ? panel.systemParameterId : null,
    openPanel,
    panel,
    clearPanel
  }
}

export const useClearRightPanelOnUnmount = () => {
  const { clearPanel } = useRightPanel()

  useUnmount(() => clearPanel())
}

import { forwardRef, memo, MouseEvent as ReactMouseEvent, ReactNode } from 'react'
import { CheckBoxExtendedProps, CheckBox as GrommetCheckBox } from 'grommet'
import styled, { css } from 'styled-components/macro'

import { useDeferTooltipTextTruncation } from '../../utilities'
import { Text } from '../../typography'
import { IconButton } from '../../button'
import { HelpText } from '../../form/internal/form-field'
import { Icon } from '../../icon'
import { Box } from '../../layout'
import { ListItem, ListItemText } from '../../list-item'
import { focusRingOutlineCss, themeColor } from '../../theme'

export type CheckboxProps = Omit<
  CheckBoxExtendedProps,
  'pad' | 'reverse' | 'toggle' | 'children' | 'label' | 'prefix'
> & {
  label?: string
  tipLabel?: string
  suffix?: ReactNode
  editIconProps?: {
    onClick?: (e: ReactMouseEvent, option: CheckBoxExtendedProps) => void
  }

  prefix?: ReactNode
  level?: number
  /** renders the help icon and tooltip text */
  helpText?: string
  'data-testid'?: string
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, disabled, suffix, editIconProps, prefix, tipLabel, helpText, level, ...props }, ref) => {
    return (
      // TODO: figure out if this is just on my machine
      // @ts-ignore
      <CheckBoxOverrides>
        <GrommetCheckBox
          aria-checked={props.checked ? true : props.indeterminate ? 'mixed' : false}
          ref={ref}
          a11yTitle={label}
          disabled={disabled}
          {...props}
        >
          {({ checked, indeterminate }: { checked?: boolean; indeterminate?: boolean }) => (
            <CheckboxGroupItem
              level={level}
              label={label}
              checked={checked}
              indeterminate={indeterminate}
              disabled={disabled}
              suffix={suffix}
              editIconProps={editIconProps}
              value={props.value}
              prefix={prefix}
              tipLabel={tipLabel}
              helpText={helpText}
            />
          )}
        </GrommetCheckBox>
      </CheckBoxOverrides>
    )
  }
)

type CheckboxItemProps = {
  label?: string
  disabled?: boolean
  checked?: boolean
  indeterminate?: boolean
  editIconProps?: {
    onClick?: (e: ReactMouseEvent, option: CheckBoxExtendedProps) => void
  }
  suffix?: ReactNode
  prefix?: ReactNode
  tipLabel?: string
  level?: number
  helpText?: string
  className?: string
  onMouseDown?: (e: ReactMouseEvent) => void
} & Omit<CheckBoxExtendedProps, 'pad' | 'reverse' | 'toggle' | 'children' | 'label' | 'prefix'>

export const CheckboxGroupItem = memo(CheckboxGroupItemInner)

function CheckboxGroupItemInner({
  label,
  disabled,
  checked,
  indeterminate,
  editIconProps,
  suffix,
  prefix,
  tipLabel,
  level,
  helpText,
  className,
  onMouseDown,
  ...props
}: CheckboxItemProps) {
  const truncate = useDeferTooltipTextTruncation('tip')

  return (
    <CheckboxListItem
      level={level}
      unchecked={!checked && !indeterminate}
      disabled={disabled}
      size="small"
      tabIndex={-1}
      className={className}
      onClick={props.onClick}
      onMouseDown={onMouseDown}
      startComponent={
        prefix ? (
          <Box direction="row" gap="2px">
            <CheckboxIcon checked={checked} $indeterminate={indeterminate} disabled={disabled} />
            {prefix}
          </Box>
        ) : (
          <CheckboxIcon checked={checked} $indeterminate={indeterminate} disabled={disabled} />
        )
      }
      endComponents={[
        ...(editIconProps?.onClick
          ? [
              <HoverableIcon
                label={`Edit ${label}`}
                icon="edit"
                size="small"
                tipPlacement="top"
                onClick={e => {
                  e.preventDefault()
                  editIconProps.onClick?.(e, { label, ...props })
                }}
              />
            ]
          : []),
        ...(suffix ? [suffix] : []),
        helpText && <HelpText text={helpText} />
      ]}
    >
      <Box>
        <Text truncate={truncate} tip={tipLabel} color={disabled ? 'text-disabled' : checked ? 'text' : 'text-light'}>
          {label}
        </Text>
      </Box>
    </CheckboxListItem>
  )
}

const HoverableIcon = styled(IconButton)`
  display: none;
  pointer-events: none;
`

const CheckboxListItem = styled(ListItem)<{ unchecked: boolean; disabled?: boolean; level?: number }>`
  &:hover,
  &:focus-within {
    ${HoverableIcon} {
      display: flex;
      pointer-events: all;
    }
  }

  ${props =>
    props.unchecked &&
    !props.disabled &&
    css`
      &:hover,
      &:focus-within {
        ${CheckboxText}, span {
          color: ${themeColor('text')};
        }

        ${CheckboxIcon} {
          fill: ${themeColor('text')};
        }
      }
    `}
`

const CheckboxIcon = styled(Icon).attrs(
  (props: { checked?: boolean; $indeterminate?: boolean; disabled?: boolean }) => ({
    color: props.disabled ? 'text-disabled' : props.checked || props.$indeterminate ? 'primary' : 'text-light',
    icon: props.$indeterminate ? 'checkbox-deselect' : props.checked ? 'checkbox-checked' : 'checkbox'
  })
)<{
  checked?: boolean
  $indeterminate?: boolean
  disabled?: boolean
}>``

const CheckboxText = styled(ListItemText).attrs((props: { unchecked?: boolean; disabled?: boolean }) => ({
  color: props.disabled ? 'text-disabled' : !props.unchecked ? 'text' : 'text-light'
}))<{ unchecked?: boolean; disabled?: boolean }>``

export const checkboxFocusCss = css`
  // grab checkbox if focused by the keyboard
  input[type='checkbox']:focus-visible {
    box-shadow: none !important;
    // set focus outline if using a custom checkbox
    & + .custom-checkbox-group-toggle-option {
      ${focusRingOutlineCss}
      outline-offset: -1px !important;
    }
    // grab the first sibling div of the checkbox
    & + div {
      // use aria-labels to access the checkbox icon and apply focus styles
      svg[aria-label='Checkbox'],
      svg[aria-label='CheckboxChecked'],
      svg[aria-label='CheckboxDeselect'] {
        ${focusRingOutlineCss}
        border-radius: 6px;
        outline-offset: -1px !important;
      }
      // if the checkbox item contains an icon that would show on hover, display it on focus as well
      ${HoverableIcon} {
        opacity: 1;
        pointer-events: all;
      }

      span {
        color: ${themeColor('text')};
      }
    }
    // when focused, give the list item a darker text color to match hover functionality
    & + ${CheckboxListItem} ${CheckboxText} {
      color: ${themeColor('text')};
    }
    & + ${CheckboxListItem} ${CheckboxIcon}:not([aria-label='CheckboxChecked']):not([aria-label='CheckboxDeselect']) {
      fill: ${themeColor('text')};
    }
  }
`

const CheckBoxOverrides = styled(Box)`
  width: 100%;

  label {
    width: 100%;

    > div {
      width: 100%;
    }
  }
  ${checkboxFocusCss}
`

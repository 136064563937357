import { useEffect } from 'react'

import { useDatadog } from './use-datadog'
import { useHeap } from './use-heap'
import { useIntercom } from './use-intercom'

// This is an empty rendering component rather than a hook so that we can optionally render it
// depending on the react_login feature flag
export const SupportAndAnalytics = () => {
  const { initializeUserAnalytics } = useHeap()
  useIntercom()
  useDatadog()

  useEffect(() => {
    initializeUserAnalytics()
  }, [])

  return <></>
}

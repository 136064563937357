import { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { Button, IconProps, Modal, useNotify } from '@cutover/react-ui'
import { schema, ShareRunbookDashboardEmailForm } from './share-runbook-dashboard-email-form'
import {
  OpenShareRunbookDashboardData,
  RunbookDashboardShareEmail,
  RunbookDashboardShareEmailFormType
} from './share-runbook-dashboard-types'
import { useSendRunbookDashboardEmailMutation } from './use-send-runbook-dashboard-email'
import { useLanguage } from 'main/services/hooks'
import {
  CreateUserSettingParams,
  useCreateEmailScheduleUserSetting
} from 'main/services/queries/use-email-schedule-user-setting'
import { FilterResponse, useFiltersQuery } from 'main/services/queries/use-filters'

type ShareRunbookDashboardProps = {
  onClose: () => void
} & OpenShareRunbookDashboardData

export const ShareRunbookDashboardModal = ({ onClose, ...modalData }: ShareRunbookDashboardProps) => {
  // data that we get from the event manager when clicking the angular button to open the modal
  const [showSchedulingOptions, setShowSchedulingOptions] = useState(Boolean(modalData.advancedOpen))

  const { data: filterData, isLoading: isFilterDataLoading } = useFiltersQuery('Runbook', modalData.runbookId)

  const { t } = useLanguage()
  const notify = useNotify()

  const sendEmailMutation = useSendRunbookDashboardEmailMutation()
  const scheduleEmailMutation = useCreateEmailScheduleUserSetting()
  const methods = useForm<RunbookDashboardShareEmailFormType>({
    mode: 'all',
    resolver: yupResolver(schema),
    context: { scheduling: showSchedulingOptions },
    defaultValues: {
      recipients: null,
      message: '',
      name: '',
      dashboardView: null,
      frequencyType: null,
      frequencyDays: null,
      startsAt: null,
      timezone: 'default'
    }
  })
  const { formState, getValues, trigger, watch, setValue } = methods
  const { isDirty, errors } = formState
  const frequencyType = watch('frequencyType')

  const handleClosed = () => {
    sendEmailMutation.reset()
    setShowSchedulingOptions(false)
    onClose()
  }

  useEffect(() => {
    trigger() // triggers validation when advanced section is toggled
  }, [showSchedulingOptions])

  useEffect(() => {
    // resets the frequencyDays when changing frequency from weekly
    if (frequencyType !== 'weekly') setValue('frequencyDays', null)
    trigger()
  }, [frequencyType])

  // Since there are two submit buttons, this creates a settled handler that will have the proper
  // behavior whether sending a test or real email
  const createSettledHandler =
    ({ isSendingTest, result }: { isSendingTest: boolean; result: 'Success' | 'Error' }) =>
    () => {
      if (isSendingTest) {
        // wait 2 seconds before setting the sendEmailMutation status back to 'idle' so that we can
        // visually indicate the success/failure of sending the email within the modal content.
        setTimeout(sendEmailMutation.reset, 2000)
      } else {
        const notifyType = result === 'Success' ? 'success' : 'error'
        onClose()
        notify[notifyType](t(`dashboard:shareModal:${showSchedulingOptions ? 'schedule' : 'share'}${result}Text`), {
          title: t(`dashboard:shareModal:share${result}Title`)
        })
      }
    }
  // for sending a test we only care about the contents of the message field and we can't can't
  // submit the form in a way that validations are run so this access just the current value
  // of the message field
  const sendTest = async () => {
    if (!modalData) return
    const message = getValues('message')
    const transformed = buildSendShareEmailForPost({ form: { message }, data: modalData, test: true })

    sendEmailMutation.mutate(transformed, {
      onSuccess: createSettledHandler({ isSendingTest: true, result: 'Success' }),
      onError: createSettledHandler({ isSendingTest: true, result: 'Error' })
    })
  }
  const sendEmail = async (form: RunbookDashboardShareEmailFormType) => {
    if (!modalData) return

    const transformed = buildSendShareEmailForPost({ form, data: modalData, test: false })

    sendEmailMutation.mutate(transformed, {
      onSuccess: createSettledHandler({ isSendingTest: false, result: 'Success' }),
      onError: createSettledHandler({ isSendingTest: false, result: 'Error' })
    })
  }

  const scheduleEmail = async (form: RunbookDashboardShareEmailFormType) => {
    if (!modalData) return
    if (!filterData) return

    const data = buildScheduleEmailForPost({ form, data: modalData, filterData })
    scheduleEmailMutation.mutate(data, {
      onSuccess: createSettledHandler({ result: 'Success', isSendingTest: false }),
      onError: createSettledHandler({ result: 'Error', isSendingTest: false })
    })
  }

  return (
    <Modal
      open
      onClose={onClose}
      onAfterClose={handleClosed}
      title={showSchedulingOptions ? t('dashboard:shareModal:advancedTitle') : t('dashboard:shareModal:title')}
      confirmText={showSchedulingOptions ? t('dashboard:shareModal:advancedSubmit') : t('dashboard:shareModal:submit')}
      onClickConfirm={
        !isDirty || Object.keys(errors).length > 0
          ? undefined
          : () => methods.handleSubmit(form => (showSchedulingOptions ? scheduleEmail(form) : sendEmail(form)))()
      }
      customButton={
        <SendTestButton
          showSchedulingOptions={showSchedulingOptions}
          sendTest={sendTest}
          sendEmailMutation={sendEmailMutation}
        />
      }
    >
      {modalData && (
        <ShareRunbookDashboardEmailForm
          methods={methods}
          runbookId={modalData.runbookId}
          accountId={modalData.accountId}
          runbookVersionId={modalData.runbookVersionId}
          showAdvancedOptions={showSchedulingOptions}
          onAdvancedOptionsClick={() => {
            setShowSchedulingOptions(!showSchedulingOptions)
          }}
          filterData={filterData}
          isFilterDataLoading={isFilterDataLoading}
        />
      )}
    </Modal>
  )
}

type SendTestButtonProps = {
  showSchedulingOptions: boolean
  sendTest: () => Promise<void>
  sendEmailMutation: any
}

type MutationStatus = ReturnType<typeof useMutation>['status']

const MODAL_TEST_BUTTON_ICON: Record<MutationStatus, IconProps['icon']> = {
  idle: 'add',
  error: 'report-problem',
  loading: 'spinner',
  success: 'check'
}

const MODAL_TEST_BUTTON_TEXT_KEY: Record<MutationStatus, string> = {
  idle: 'default',
  loading: 'sending',
  success: 'sent',
  error: 'error'
}

const SendTestButton = ({ showSchedulingOptions, sendTest, sendEmailMutation }: SendTestButtonProps) => {
  const { t } = useLanguage()
  const [sendingTest, setSendingTest] = useState(false)

  // Only use the sendEmailMutation progress to change the button content when sending a test, otherwise
  // the button will change icons as the modal is closing
  const [testButtonIcon, testButtonText] = useMemo(() => {
    const testStatus: MutationStatus = sendingTest ? sendEmailMutation.status : 'idle'
    return [
      MODAL_TEST_BUTTON_ICON[testStatus],
      t(`dashboard:shareModal:test-${MODAL_TEST_BUTTON_TEXT_KEY[testStatus]}`)
    ]
  }, [sendEmailMutation.status, t])

  useEffect(() => {
    if (sendEmailMutation.status === 'idle') {
      setSendingTest(false)
    }
  }, [sendEmailMutation.status])
  const handleClickSendTest = () => {
    sendTest()
    setSendingTest(true)
  }
  return (
    <>
      {!showSchedulingOptions && (
        <Button
          data-testid="send-test-button"
          tertiary
          onClick={handleClickSendTest}
          label={testButtonText}
          icon={testButtonIcon}
        />
      )}
    </>
  )
}

export function buildSendShareEmailForPost({
  form,
  data,
  test
}: {
  form: Partial<RunbookDashboardShareEmailFormType>
  data: OpenShareRunbookDashboardData
  test: boolean
}): RunbookDashboardShareEmail & { id: number } {
  return {
    id: data.dashboardId,
    params: { ...data.filters },
    account_id: data.accountId,
    runbook_id: data.runbookId,
    user_recipients: form.recipients?.users,
    team_recipients: form.recipients?.teams,
    message: form.message || '',
    test,
    timezone: data.timezone
  }
}

function buildScheduleEmailForPost({
  form,
  data,
  filterData
}: {
  form: RunbookDashboardShareEmailFormType
  data: OpenShareRunbookDashboardData
  filterData: FilterResponse
}): CreateUserSettingParams {
  const frequencyNameMap: {
    [key: string]: 'Every 15 minutes' | 'Every 30 minutes' | 'Hourly' | 'Once' | 'Daily' | 'Weekly'
  } = {
    every_15_min: 'Every 15 minutes',
    every_30_min: 'Every 30 minutes',
    hourly: 'Hourly',
    once: 'Once',
    daily: 'Daily',
    weekly: 'Weekly'
  }

  let selectedFilter

  if (form.filter) {
    selectedFilter = filterData.filters.filter(filter => form.filter && filter.id === parseInt(form.filter, 10))
  }

  return {
    resource_id: data.dashboardId,
    resource_type: 'Dashboard',
    type: 'EmailScheduleUserSetting',
    user_id: data.currentUserId,
    data: {
      runbook_id: data.runbookId,
      account_id: data.accountId,
      enabled: true,
      timezone: form.timezone === 'default' ? data.timezone : form.timezone,
      schedules: [
        {
          name: form.name,
          ...(form.frequencyType === 'weekly' && {
            day_options: form.frequencyDays?.map(day => Number(day))
          }),
          message: form.message || '',
          schedule_time: form.startsAt ? form.startsAt.toISOString() : undefined,
          user_recipients: form.recipients?.users || [],
          team_recipients: form.recipients?.teams || [],
          ...(form.frequencyType && {
            frequency: {
              option: form.frequencyType,
              name: frequencyNameMap[form.frequencyType]
            }
          }),
          ...(selectedFilter && {
            filter_name: selectedFilter[0].name,
            filter_id: selectedFilter[0].id,
            filter_query_string: selectedFilter[0].query_string
          })
        }
      ]
    }
  }
}

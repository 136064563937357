import { useCallback, useEffect, useMemo, useState } from 'react'

import { ColorProp, ColumnConfig, Pill, RagIndicator, Table } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ConnectSetting, ConnectStatus } from 'main/services/queries/types/connect'
import { useConnectSettingsQuery } from 'main/services/queries/use-connect-settings-query'
import { useCutoverConnectWebsocket } from 'main/services/hooks/websockets/use-cutover-connect-websocket'
import { usePermissions } from 'main/services/queries/use-permissions'
import { CutoverConnectChannelResponse } from 'main/services/api/data-providers/cutover-connect/cutover-connect-channel-response-types'
import { useConnectSettingEditPanel } from 'main/context/panel-context'

export const ConnectSettingsList = () => {
  const { t } = useLanguage('connectSettings')
  const { data: connectSettingData, isLoading: connectSettingsLoading } = useConnectSettingsQuery()
  const connectSettingItems = connectSettingData?.connect_settings || []
  const { listen } = useCutoverConnectWebsocket()

  const [currentConnectSettingItems, setCurrentItems] = useState<ConnectSetting[]>([])

  const permissions = usePermissions('connect-settings')
  const userCanEditConnectSettings = permissions('update')

  const { openPanel } = useConnectSettingEditPanel()

  const handleClickRow = useCallback(
    ({ datum }: { datum: ConnectSetting }) => {
      openPanel({ connectSettingId: datum?.id as number })
    },
    [openPanel]
  )

  function statusColorMap(status: ConnectStatus): ColorProp | undefined {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'warning'
      case 'inactive':
        return undefined
      case 'active':
        return 'success'
    }
  }

  function daysToExpiryColorMap(days: number, threshold: number): ColorProp | undefined {
    return days <= threshold / 2 ? 'error' : days <= threshold ? 'warning' : 'success'
  }

  useEffect(() => {
    setCurrentItems(connectSettingItems)
  }, [connectSettingData])

  useEffect(() => {
    listen((webSocketData: CutoverConnectChannelResponse | undefined) => {
      if (webSocketData && webSocketData.connect_setting) {
        const updatedItem = { ...webSocketData.connect_setting, online: webSocketData.connect_setting.online }
        setCurrentItems(connectSettingItems => {
          return connectSettingItems?.map(item => {
            return item.id === updatedItem.id ? updatedItem : item
          })
        })
      }
    })
  }, [])

  const columns: ColumnConfig<ConnectSetting>[] = useMemo(() => {
    return [
      {
        header: t('table.columnHeaders.online'),
        property: 'online',
        sortable: true,
        render: (connectSetting: ConnectSetting) => {
          return (
            <RagIndicator
              status={t(
                connectSetting.status.toLowerCase() !== 'active' ? 'off' : connectSetting.online ? 'green' : 'red'
              )}
              tip={t(connectSetting.online ? 'Online' : 'Offline')}
              tipPlacement={'top'}
              css={`
                display: inline-block;
                position: relative;
                top: 1px;
                margin-right: 3px;
              `}
            />
          )
        }
      },
      {
        header: t('table.columnHeaders.name'),
        property: 'display_name',
        sortable: true
      },
      {
        header: t('table.columnHeaders.status'),
        property: 'status',
        sortable: true,
        render: (connectSetting: ConnectSetting) => {
          return <Pill color={statusColorMap(connectSetting.status)} label={t(`statuses.${connectSetting.status}`)} />
        }
      },
      {
        header: t('table.columnHeaders.daysToExpiry'),
        property: 'days_to_expiry',
        sortable: true,
        render: (connectSetting: ConnectSetting) => {
          return (
            <Pill
              color={daysToExpiryColorMap(connectSetting.days_to_expiry, connectSetting.notification_threshold)}
              label={t(`${connectSetting.days_to_expiry} days`)}
            />
          )
        }
      },
      {
        header: t('table.columnHeaders.connectVersion'),
        property: 'connect_version',
        sortable: true
      }
    ]
  }, [userCanEditConnectSettings])
  return (
    <>
      <Table
        columns={columns}
        data={currentConnectSettingItems}
        isLoading={connectSettingsLoading}
        onClickRow={handleClickRow}
      />
    </>
  )
}

import { keyBy } from 'lodash'
import { selector, selectorFamily, useRecoilValue } from 'recoil'

import { accountMetaState } from './account'
import { equalSelector } from 'main/recoil/shared'
import { TaskType } from 'main/services/queries/types'

export const accountTaskTypes = selector<TaskType[]>({
  key: 'account:task-types',
  get: ({ get }) => {
    return get(accountMetaState).task_types
  }
})

export const accountTaskTypeLookup = selector<Record<number, TaskType>>({
  key: 'account:task-type:lookup',
  get: ({ get }) => {
    const taskTypes = get(accountTaskTypes)
    return keyBy(taskTypes, 'id')
  }
})

export const getAccountTaskType = selectorFamily({
  key: 'account:task-type:id',
  get:
    (id: number) =>
    ({ get }) => {
      const lookup = get(accountTaskTypeLookup)
      return lookup[id]
    }
})

export const milestoneTaskType = selector({
  key: 'account:milestone-task-type',
  get: ({ get }) => {
    const taskTypes = get(accountTaskTypes)
    return taskTypes.find(tt => tt.name.toLowerCase() === 'milestone')
  }
})

const accountGlobalTaskTypeDefaultState = equalSelector<TaskType>({
  key: 'account:task-type:default',
  get: ({ get }) => {
    const taskTypes = get(accountTaskTypes)
    // Sets to the "Normal" global task type in default seeded database
    const primaryTaskType = taskTypes.find(tt => tt.key === 'normal')

    // The fallback condition check should never happen but JUST IN CASE someone
    // removes the primary 'normal' task type, a different task type is returned.
    if (!primaryTaskType) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return taskTypes.find(tt => tt.is_primary && !tt.archived)!
    } else {
      return primaryTaskType
    }
  },
  equals: (prev, next) => prev?.id === next?.id
})

export const useTaskTypeOrDefault = (id?: number | null) => {
  // @ts-ignore fine here
  const taskType = useRecoilValue(getAccountTaskType(id))
  const defaultTaskType = useRecoilValue(accountGlobalTaskTypeDefaultState)

  return taskType ?? defaultTaskType
}

export const accountTaskTypesGroupedIntegrations = selector({
  key: 'account:task-types:grouped-integrations',
  get: ({ get }) => {
    const tts = get(accountTaskTypes)
    return tts.reduce<{ taskTypeIntegrations: TaskType[]; taskTypes: TaskType[] }>(
      (acc, tt) => {
        acc[
          (tt.integration_action_items && tt.integration_action_items.length > 0) ||
          tt.name.startsWith('Integrations::Apps::MountPoint')
            ? 'taskTypeIntegrations'
            : 'taskTypes'
        ].push(tt)

        return acc
      },
      { taskTypeIntegrations: [], taskTypes: [] }
    )
  }
})

import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Box, Button, colors, Heading, Message } from '@cutover/react-ui'
import { AuthenticationFormWrapper } from './authentication-form-wrapper'
import { LoginPageOption } from './login-page'
import { FooterLinkText } from './login-styled'
import { LoginWarningMessage } from './login-warning-message'
import { useLanguage } from 'main/services/hooks'
import { redirectToSSO } from 'main/services/queries/use-login'

export const SSOForm = ({
  showEmailLink,
  handleLoginPageOption,
  loginWarningMessage
}: {
  showEmailLink: boolean
  handleLoginPageOption: (option: LoginPageOption) => void
  loginWarningMessage: string
}) => {
  const [logoutState] = useState<{ success?: string[]; error?: string[] }>(
    JSON.parse(localStorage.getItem('reactLogout') ?? '{}')
  )
  const [resetPasswordSuccess] = useState(localStorage.getItem('resetPasswordSuccess'))
  const [userVerifySuccess] = useState(localStorage.getItem('userVerifySuccess'))
  const [showRedirectMessage] = useState(localStorage.getItem('showRedirectMessage'))
  const [reactForbidden] = useState(localStorage.getItem('reactForbidden'))
  const [blockLogin, setBlockLogin] = useState(Boolean(loginWarningMessage))
  const { t } = useLanguage('authentication')
  const [searchParams] = useSearchParams()

  const invalidSSO = searchParams.get('auth_type') === 'failure'

  const handleSSORedirect = () => {
    redirectToSSO()
  }

  const toggleBlockLogin = () => {
    setBlockLogin(!blockLogin)
  }

  useEffect(() => {
    localStorage.removeItem('reactLogout')
  }, [logoutState])

  useEffect(() => {
    localStorage.removeItem('showRedirectMessage')
  }, [showRedirectMessage])

  useEffect(() => {
    localStorage.removeItem('reactForbidden')
  }, [reactForbidden])

  useEffect(() => {
    localStorage.removeItem('userVerifySuccess')
  }, [userVerifySuccess])

  useEffect(() => {
    localStorage.removeItem('resetPasswordSuccess')
  }, [resetPasswordSuccess])

  return (
    <>
      <Box width="320px">
        <Heading
          as="h1"
          color={colors.white}
          css={`
            margin: 12px 0px 24px;
            text-align: center;
          `}
          weight={300}
        >
          {t('loginHeading')}
        </Heading>
        <AuthenticationFormWrapper>
          {reactForbidden && (
            <Box margin={{ bottom: '12px' }}>
              <Message message={t('forbiddenMessage')} type="error" />
            </Box>
          )}
          {showRedirectMessage && (
            <Box margin={{ bottom: '12px' }}>
              <Message message={t('redirectMessage')} type="error" />
            </Box>
          )}
          {logoutState?.success?.includes('user-updated') && (
            <Box margin={{ bottom: '12px' }}>
              <Message message={t('userUpdatedMessage')} type="success" />
            </Box>
          )}
          {logoutState?.success?.includes('logout') && (
            <Box margin={{ bottom: '12px' }}>
              <Message message={t('logOutMessage')} type="success" />
            </Box>
          )}
          {userVerifySuccess && (
            <Box margin={{ bottom: '12px' }}>
              <Message message={t('userVerifyMessage')} type="success" />
            </Box>
          )}
          {invalidSSO && (
            <Box margin={{ bottom: '12px' }}>
              <Message
                message={t('loginError.invalidCredentials')}
                type="error"
                css={`
                  margin-bottom: ${'12px'};
                `}
              />
            </Box>
          )}
          {resetPasswordSuccess && (
            <Box margin={{ bottom: '12px' }}>
              <Message message={t('passwordResetSuccess')} type="success" />
            </Box>
          )}
          <Message type="info" message={t('ssoInfo')} />
          {Boolean(loginWarningMessage) && (
            <LoginWarningMessage onClick={toggleBlockLogin} message={loginWarningMessage} />
          )}
          <Box pad="small">
            <Button
              primary
              full
              type="submit"
              onClick={handleSSORedirect}
              label={t('submitLogin')}
              loadingLabel={t('submittingLogin')}
              icon="arrow-forward"
              disabled={blockLogin}
            />
          </Box>
        </AuthenticationFormWrapper>
      </Box>
      <Box direction="row" gap="medium" alignSelf="center" margin={{ top: '12px' }} css="text-align: center;">
        {showEmailLink && (
          <FooterLinkText onClick={() => handleLoginPageOption('email')}>{t('loginWithEmail')}</FooterLinkText>
        )}
      </Box>
    </>
  )
}

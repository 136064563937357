import { apiClient } from '../api'
import { apiClient_UNSTABLE } from '../api/api-client-unstable'
import { RunbookUpdateResponse } from '../api/data-providers/runbook-types'
import { RunbookShowRunbook, RunbookUpdateRunbook } from 'main/services/queries/types'
import { Filter } from './use-filters'

export type GetRunbookResponseType = {
  runbook: RunbookShowRunbook
  meta: RunbookResponseMeta
}

export type SavedFilter = Filter & {
  account_id?: number
  created_at: string
  updated_at: string
  resource_id: number
  runbook_id?: number
  show_dashboard: boolean
}

export type RunbookResponseMeta = {
  filters: SavedFilter[]
  current_user_pir_viewed: boolean
  permissions: RunbookResponseMetaPermissions
}

export type RunbookResponseMetaPermissions = {
  read: number[]
  update: number[]
  archive: number[]
  destroy: number[]
  add_event: number[]
  leave_event: number[]
  duplicate: number[]
  create_snippet: number[]
}

let controller: AbortController | undefined

export const getRunbook = async (runbookId?: number | string) => {
  if (controller) controller.abort()
  controller = new AbortController()
  const signal = controller.signal

  try {
    const { data } = await apiClient_UNSTABLE.get<GetRunbookResponseType>(`runbooks/${runbookId}`, { signal })
    return data
  } catch (error: any) {
    // TODO: handle any errors
    throw error
  }
}

export type RunbookUpdatePayload = {
  runbook: RunbookUpdateRunbook
  timezone: string | null
  shift_time?: boolean
}

export const updateRunbook = async (runbookId: number | string, payload: RunbookUpdatePayload) => {
  const { data } = await apiClient.put<RunbookUpdatePayload, RunbookUpdateResponse>({
    url: `runbooks/${runbookId}`,
    data: payload
  })

  return data
}

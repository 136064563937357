import { useMemo } from 'react'
import * as yup from 'yup'
import { useRecoilValue } from 'recoil'
import { useFormContext } from 'react-hook-form'

import { useCustomFieldForm } from 'main/components/shared/custom-field-form'
import { CustomField } from 'main/services/queries/types'
import {
  getAccountTaskType,
  useAccountMetaProperty,
  useModalHistoryValue,
  useTaskListTask,
  useTaskProgression
} from 'main/recoil/runbook'
import { buildDefaultFieldValues } from 'main/components/shared/custom-field-form/custom-field-form-helper'
import { CustomFieldsGroupsForm } from 'main/components/shared/custom-field-form/custom-field-groups-form'
import { useLanguage } from 'main/services/hooks'
import { FormModal } from 'main/components/shared/form'
import {
  useAccountCustomFieldGroupsLookup,
  useAccountCustomFieldLookup,
  useAccountCustomFieldUsers
} from 'main/recoil/runbook/models/account/custom-fields'
import { useModalCheckTaskStageChanged } from './use-modal-check-task-stage-changed'

const formType = createValidationSchema(yup.object())
export type CustomFieldsFormSchema = yup.InferType<typeof formType>

type TaskActionProps = {
  id: number
  onClose: () => void
}

export const TaskActionModal = ({ id, onClose }: TaskActionProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskActionModal' })
  const { startOrFinishTask } = useTaskProgression()
  const { fields: customFieldsLookup } = useAccountCustomFieldLookup()
  const customFieldGroups = useAccountMetaProperty({ attribute: 'custom_field_groups' })
  const task = useTaskListTask(id as number)
  const initialTaskStage = useMemo(() => task.stage, [])
  const isTaskStart = task.stage === 'startable'
  const taskType = useRecoilValue(getAccountTaskType(task?.task_type_id))
  const modalHistory = useModalHistoryValue()

  const {
    fieldValueValidation,
    buildFieldValuesAttributesRequestData,
    data: { customFields, groupedCustomFields }
  } = useCustomFieldForm({
    applyToSlugs: isTaskStart ? ['task_start'] : ['task_end'],
    customFieldsLookup,
    customFieldGroups,
    constraintContext: { task_type_id: taskType?.id }
  })
  const defaultValues = {
    field_values: buildDefaultFieldValues(customFields, groupedCustomFields)
  }

  const transformer = (data: CustomFieldsFormSchema) => {
    return {
      field_values: data.field_values && buildFieldValuesAttributesRequestData(data.field_values)
    }
  }

  const {
    handleSubmit,
    handleClose,
    confirmIcon,
    confirmText,
    errors: stageChangedErrors,
    renderContent,
    hasCancelButton
  } = useModalCheckTaskStageChanged<CustomFieldsFormSchema>({
    task,
    onSubmit: data => startOrFinishTask(id, { field_values_attributes: data.field_values }),
    onClose,
    confirmIcon: 'chevron-right',
    confirmText: t('confirmText')
  })

  return (
    <FormModal<CustomFieldsFormSchema>
      data-testid="modal-task-action"
      animate={modalHistory.length > 0 ? 'out' : true}
      confirmIcon={confirmIcon}
      confirmText={confirmText}
      defaultValues={defaultValues}
      key="task-action-form"
      onClose={handleClose}
      onSubmit={handleSubmit}
      hasCancelButton={hasCancelButton}
      open
      schema={createValidationSchema(fieldValueValidation)}
      title={t(initialTaskStage === 'startable' ? 'titleStart' : 'titleFinish')}
      transformer={transformer}
      customErrors={stageChangedErrors}
    >
      {renderContent(<TaskActionInner customFields={customFields} groupedCustomFields={groupedCustomFields} />)}
    </FormModal>
  )
}

function createValidationSchema(fieldValuesValidation: any) {
  return yup.object({
    field_values: fieldValuesValidation
  })
}

type TaskActionInnerProps = {
  errorMessage?: string[] | null
  customFields: CustomField[]
  groupedCustomFields: Record<number, CustomField[]>
}

const TaskActionInner = ({ customFields, groupedCustomFields }: TaskActionInnerProps) => {
  const customFieldUsers = useAccountCustomFieldUsers()
  const customFieldGroupsLookup = useAccountCustomFieldGroupsLookup()
  const { formState } = useFormContext()
  const { errors } = formState

  return (
    <CustomFieldsGroupsForm
      initialActiveIndex={Array.from({ length: Object.entries(groupedCustomFields).length + 1 }, (_, index) => index)}
      customFieldUsers={customFieldUsers}
      customFieldGroupsLookup={customFieldGroupsLookup}
      groupedCustomFields={groupedCustomFields}
      customFields={customFields}
      errors={errors}
    />
  )
}

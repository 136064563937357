import { useMutation, useQueryClient } from 'react-query'

import { FieldValuesAttributes } from './types'
import { apiClient } from 'main/services/api'
import { RunbookMergeResponse } from '../api/data-providers/runbook-types'

type NewMergeRunbook = {
  name: string
  account_id: number
  field_values_attributes: FieldValuesAttributes[]
  project_id: number
  roles: any[]
  runbook_type_id: number
  runbook_versions_attributes: { start_scheduled: Date }[]
  timezone: string
}

export type MergeRunbooksPayload = {
  runbook_ids: number[]
  shift_time: boolean
  target_runbook_id?: number | string | null
  runbook?: NewMergeRunbook
}

export const useMergeRunbooks = () => {
  const queryClient = useQueryClient()

  return useMutation<RunbookMergeResponse, Error, MergeRunbooksPayload>(
    'merge-runbooks',
    async (payload: MergeRunbooksPayload) => {
      const { data } = await apiClient.post<MergeRunbooksPayload, RunbookMergeResponse>({
        url: 'runbooks/merge',
        data: payload
      })

      return data as RunbookMergeResponse
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('mergeRunbooks')
      }
    }
  )
}

import styled from 'styled-components/macro'

import { Link } from './link'
import { themeColor } from '../../../theme'

export const Element = ({ attributes, children, element }: any) => {
  const style = { textAlign: element.align }

  switch (element.type) {
    case 'bulleted-list':
      return (
        <ul
          style={{ ...style, listStyleType: 'disc', marginLeft: '16px', paddingLeft: '16px', lineHeight: '1.3em' }}
          {...attributes}
        >
          {children}
        </ul>
      )
    case 'numbered-list':
      return (
        <ol
          style={{ ...style, listStyleType: 'number', marginLeft: '16px', paddingLeft: '16px', lineHeight: '1.3em' }}
          {...attributes}
        >
          {children}
        </ol>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'code':
      return (
        <pre>
          <code {...attributes}>{children}</code>
        </pre>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'heading-three':
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      )
    case 'heading-four':
      return (
        <h4 style={style} {...attributes}>
          {children}
        </h4>
      )
    case 'heading-five':
      return (
        <h5 style={style} {...attributes}>
          {children}
        </h5>
      )
    case 'heading-six':
      return (
        <h6 style={style} {...attributes}>
          {children}
        </h6>
      )
    case 'image':
      return <img {...attributes} alt="" src={element.src} />
    case 'link':
      return <Link attributes={attributes} children={children} element={element} />
    case 'table':
      return (
        <table style={{ ...style, marginTop: '16px', marginBottom: '16px' }} {...attributes}>
          {children}
        </table>
      )
    case 'table-header':
      return <thead>{children}</thead>
    case 'table-header-cell':
      return <StyledTableHeader {...attributes}>{children}</StyledTableHeader>
    case 'table-body':
      return <tbody {...attributes}>{children}</tbody>
    case 'table-row':
      return <tr {...attributes}>{children}</tr>
    case 'table-cell':
      return <StyledTableCell {...attributes}>{children}</StyledTableCell>
    default:
      return (
        <p style={{ ...style, lineHeight: '1.3em' }} {...attributes}>
          {children}
        </p>
      )
  }
}

const StyledTableHeader = styled.th`
  padding: 6px;
  text-align: left;
  border-collapse: collapse;
  background-color: ${themeColor('bg-1')};
  border: 1px solid ${themeColor('bg-4')};
  min-width: 40px;
`

const StyledTableCell = styled.td`
  padding: 6px;
  border-collapse: collapse;
  border: 1px solid ${themeColor('bg-4')};
  min-width: 40px;
`

import { DragEvent, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import styled from 'styled-components'

import { UserListItem, UserListItemSelectedRole } from '@cutover/react-ui'
import { ErrorItem, FormValues, SelectedUser } from './types'
import { Role, Stream } from '../../right-panels/people-panel/types'
import { useLanguage } from 'main/services/hooks/use-language'
import { getAvailableRoleItems } from '../../right-panels/people-panel/shared'

type UserFormItemProps = {
  removeItem: (index: number) => void
  item: SelectedUser
  methods: UseFormReturn<FormValues>
  index: number
  errorList: ErrorItem[]
  setErrorList: (errorList: ErrorItem[]) => void
  streams: Stream[]
  templateType?: string
  setDisableModalFocusLock?: (shouldDisable: boolean) => void
}

export const UserFormItem = ({
  item,
  methods,
  removeItem,
  index,
  errorList,
  setErrorList,
  streams,
  templateType,
  setDisableModalFocusLock
}: UserFormItemProps) => {
  const { t } = useLanguage()

  const [selectedRoleItem, setSelectedRoleItem] = useState<UserListItemSelectedRole>({ role: 'Admin' })

  const onRoleSelect = (roleItem: UserListItemSelectedRole) => {
    setSelectedRoleItem(roleItem)
    methods.setValue(`usersTeamsSelect.${index}.role`, roleItem.role.toLowerCase())

    roleItem.streamIds && methods.setValue(`usersTeamsSelect.${index}.streamIds`, roleItem.streamIds)
  }

  const isErrorItem = (errorItem: ErrorItem) =>
    errorItem.itemId === item.id &&
    errorItem.itemType === item.type &&
    errorItem.message === t('runbook:peoplePanel:users:noStreamIds', { userName: item.name })

  useEffect(() => {
    const hasErrorItem = !!errorList.find(isErrorItem)

    if (
      selectedRoleItem.role.toLowerCase() === Role.StreamEditor.toLowerCase() &&
      (!selectedRoleItem.streamIds || selectedRoleItem.streamIds.length === 0)
    ) {
      if (!hasErrorItem) {
        const errorItem: ErrorItem = {
          message: t('runbook:peoplePanel:users:noStreamIds', { userName: item.name }),
          itemId: item.id,
          itemType: item.type
        }
        setErrorList([...errorList, errorItem])
      }
    } else if (hasErrorItem) {
      const updatedErrorList = errorList.filter(errorItem => !isErrorItem(errorItem))

      setErrorList(updatedErrorList)
    }
  }, [selectedRoleItem, item])

  const onDragStart = (event: DragEvent<HTMLLIElement>) => {
    event.dataTransfer && event.dataTransfer.setData('text', JSON.stringify({ user: item, index: index }))
  }

  const isInvalid = !!errorList.find(errorItem => errorItem.itemId === item.id && errorItem.itemType === item.type)

  const removeUserItem = () => {
    removeItem(index)
  }

  const possibleRoles = getAvailableRoleItems(false, templateType, streams)

  return (
    <Item
      onMouseDown={() => setDisableModalFocusLock?.(true)}
      onMouseUp={() => setDisableModalFocusLock?.(false)}
      onDragEnd={() => setDisableModalFocusLock?.(false)}
      onDragStart={onDragStart}
      draggable={!!onDragStart}
    >
      <UserListItem
        size="small"
        id={item.id}
        key={item.id}
        name={item.name}
        online={item.online}
        color={item.color}
        //status={item.status} NOTE this endpoint doesnt give us any status data
        hasError={isInvalid}
        role={selectedRoleItem}
        availableRoles={possibleRoles}
        onRoleSelect={onRoleSelect}
        onClickRemove={removeUserItem}
        draggable={false} // since drag is handled by wrapper
      />

      <input
        type="hidden"
        defaultValue={item.id}
        {...methods.register(`usersTeamsSelect.${index}.id` as const, { valueAsNumber: true })}
      />

      <input
        type="hidden"
        defaultValue={selectedRoleItem.role.toLowerCase()}
        {...methods.register(`usersTeamsSelect.${index}.role` as const)}
      />
      {selectedRoleItem.streamIds &&
        selectedRoleItem.streamIds.map((stream, streamIndex) => (
          <input
            key={stream}
            type="hidden"
            defaultValue={stream}
            {...methods.register(`usersTeamsSelect.${index}.streamIds.${streamIndex}` as const, {
              valueAsNumber: true
            })}
          />
        ))}

      <input type="hidden" defaultValue="user" {...methods.register(`usersTeamsSelect.${index}.type` as const)} />
    </Item>
  )
}

const Item = styled.li`
  cursor: move !important;
`

import { Editor } from 'slate'

import { Format, FormattedText } from '../text-editor-types'

export const isMarkActive = (editor: Editor, format: Format) => {
  let result = false
  const { selection } = editor

  if (!!selection) {
    var [node] = Editor.node(editor, selection.anchor.path)
    if ((node as FormattedText).text) {
      const marks = Editor.marks(editor)
      result = marks ? marks[format] === true : false
    }
  }
  return result
}

export const toggleMark = (editor: Editor, format: Format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

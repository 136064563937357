import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { flatMap, isEqual } from 'lodash'
import { UseFormReturn } from 'react-hook-form'

import {
  Box,
  InfiniteList,
  LoadingPanel,
  NoResourceFound,
  UserListItem,
  UserListItemSelectedRole
} from '@cutover/react-ui'
import { Nullish } from '@cutover/utility-types'
import { CreatedUserListItemSelectedRole } from '@cutover/react-ui/src/list-item/people/user-list-item'
import { RunbookUser, SelectedUser, Stream } from '../types'
import { useRunbookUsersQuery } from '../use-runbook-users'
import { useCurrentUser } from 'main/recoil/current-user'
import { useLanguage } from 'main/services/hooks/use-language'
import { getAvailableRoleItems } from '../shared'

export type UserListProps = {
  runbookId: number
  runbookVersionId: number
  search: Nullish<string>
  clearSearch: () => void
  setTeamsCount: (count: number) => void
  setUsersCount: (count: number) => void
  selectedUser: SelectedUser | undefined
  onUserSelect: (item: SelectedUser) => void
  methods: UseFormReturn<{ users: CreatedUserListItemSelectedRole[] }>
  streams?: Stream[]
  templateType?: string
}

export function UserList({
  runbookId,
  runbookVersionId,
  search,
  clearSearch,
  setTeamsCount,
  setUsersCount,
  selectedUser,
  onUserSelect,
  methods,
  streams,
  templateType
}: UserListProps) {
  const [isRefetching, setIsRefetching] = useState<boolean>(false)
  const [adminIds, setAdminIds] = useState<number[] | null>(null)
  const [errorUserId, setErrorUserId] = useState<number | null>(null)
  const { t } = useLanguage()
  const currentUser = useCurrentUser()
  const { isFetching, isFetchingNextPage, data, hasNextPage, fetchNextPage, refetch } = useRunbookUsersQuery(
    {
      runbookId,
      runbookVersionId,
      offset: 0,
      limit: 20,
      search
    },
    currentUser?.id,
    {
      useErrorBoundary: true
    }
  )

  const updateUserRole = (updatedItem: CreatedUserListItemSelectedRole) => {
    const users = methods.getValues('users')
    const updatedUsers = users.filter(user => user.id !== updatedItem.id)

    const defaultItem = items.find(item => updatedItem.id === item.id)

    // Check if the role has changed or if stream editor's streams have changed
    const isUpdatedItemChanged =
      defaultItem?.role !== updatedItem.role ||
      (defaultItem?.role === 'Stream Editor' &&
        updatedItem.role === 'Stream Editor' &&
        !isEqual(defaultItem?.streamIds, updatedItem.streamIds))

    if (isUpdatedItemChanged) {
      updatedUsers.push(updatedItem)
      methods.setValue('users', updatedUsers, { shouldDirty: true })
    }

    // Manually clear error on user roles change
    if (methods.formState?.errors?.users) {
      methods.clearErrors()
    }
  }

  const refetchUserList = async () => {
    setIsRefetching(true)
    await refetch()
    setIsRefetching(false)
    // reset user role update form
    methods.reset({ users: [] })
  }
  //why do we need to refetch
  useEffect(() => {
    const { isSubmitSuccessful, isValid } = methods.formState
    if (isSubmitSuccessful && isValid) {
      refetchUserList()
    }
  }, [methods.formState])

  useEffect(() => {
    // Need to refetch user list after team is created in case new users were added
    eventManager.on('runbook-teams-users-added', refetchUserList)
    eventManager.on('update-runbook-page-on-snippet-added', refetchUserList)

    return () => {
      eventManager.off('runbook-teams-users-added', refetchUserList)
      eventManager.off('update-runbook-page-on-snippet-added', refetchUserList)
    }
  }, [])

  useEffect(() => {
    if (data) {
      const meta = data.pages[0]?.meta

      setUsersCount(meta?.filteredRunbookUsersCount)
      setTeamsCount(meta?.filteredRunbookTeamsCount)
      setAdminIds(meta?.adminIds)
    }
  }, [data])

  const canUpdate = data?.pages[0]?.meta?.canUpdate

  const items = data ? flatMap(data.pages, r => r.users) : []

  const userValues = methods.watch('users')

  const adminValidation = () => {
    const newAdminIds = userValues.filter(user => user.role === 'Admin').map(user => user.id)

    const existingAdminIds =
      adminIds?.filter(
        adminId => !userValues.find(userValue => userValue.id === adminId && userValue.role !== 'Admin')
      ) || []

    const allAdminIds = [...newAdminIds, ...existingAdminIds]

    if (allAdminIds.length === 0 && !methods.formState?.errors?.users) {
      methods.setError('users.0.role', {
        message: t('runbook:peoplePanel:users:atLeastOneAdmin')
      })
    }
  }

  const streamIdsValidation = () => {
    const errorUserId =
      userValues.find(
        (user: CreatedUserListItemSelectedRole) =>
          user.role === 'Stream Editor' && (!user.streamIds || user.streamIds.length === 0)
      )?.id || null

    setErrorUserId(errorUserId)

    if (errorUserId && !methods.formState?.errors?.users) {
      const errorUser = items.find(item => item.id === errorUserId)

      methods.setError('users.0.role', {
        message: t('runbook:peoplePanel:users:noStreamIds', {
          userName: errorUser?.name
        })
      })
    }
  }

  // Note: what is the point of this FE validation - can we not simplify by doing on BE?
  useEffect(() => {
    // Custom frontend validation for at least one admin in the runbook
    if (userValues.length > 0) {
      adminValidation()
      streamIdsValidation()
      // On reset
    } else if (userValues.length === 0 && errorUserId) {
      setErrorUserId(null)
    }
  }, [items, userValues, errorUserId])

  // Show spinner for the first time load
  if (isFetching && !isFetchingNextPage && !isRefetching) {
    return <LoadingPanel />
  }

  if (items.length === 0) {
    return <NoResourceFound context="user" clearAllFilters={clearSearch} />
  }

  return (
    <Box
      as="form"
      aria-label="form"
      css={`
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      `}
    >
      <InfiniteList<RunbookUser>
        items={items}
        initialIndex={selectedUser?.index}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        renderItem={(index, user) => {
          const possibleRoles = getAvailableRoleItems(user.hasTeam, templateType, streams)
          const userRole = {
            role: user.role,
            ...(user.streamIds && user.streamIds.length && { streamIds: user.streamIds })
          } as UserListItemSelectedRole

          return (
            <UserListItem
              size="small"
              id={user.id}
              key={user.id}
              name={user.name}
              online={user.online}
              color={user.color}
              status={user.status}
              hasError={errorUserId === user.id}
              role={userRole}
              disabled={!canUpdate || methods.formState.isSubmitting || isRefetching}
              availableRoles={possibleRoles}
              onRoleSelect={updateUserRole}
              onClick={() =>
                onUserSelect({
                  index,
                  ...user
                })
              }
              data-testid="user-item"
            />
          )
        }}
      />
      <input type="hidden" {...methods.register('users' as const)} />
    </Box>
  )
}

import { forwardRef, ReactNode } from 'react'
import { Heading } from 'grommet'
import styled from 'styled-components/macro'

import { IconButton } from '../../button/button/button'
import { themeColor, themeEdgeSize } from '../../theme/grommet-utils'
import { Box } from '../box/box'
import { LoadingPanel } from '../loading-panel/loading-panel'

const PANEL_HEADER_HEIGHT = 64
export const RIGHT_PANEL_SCROLL_CONTAINER_ID = 'right-panel-scroll-container'

type RightPanelProps = {
  children?: ReactNode
  title: string
  onBack?: () => void
  headerItems?: ReactNode[]
  onClose?: () => void
  loading?: boolean
  footer?: ReactNode
}

export const RightPanel = forwardRef<HTMLDivElement, RightPanelProps>((props, ref) => {
  return (
    <PanelContainer ref={ref}>
      <RightPanelHeader title={props.title} onBack={props.onBack} items={props.headerItems} onClose={props.onClose} />
      <PanelContentWrapper>{props.loading ? <LoadingPanel /> : props.children}</PanelContentWrapper>
      {props.footer && (
        <PanelFooter>
          <Box width="100%">{props.footer}</Box>
        </PanelFooter>
      )}
    </PanelContainer>
  )
})

type RightPanelHeaderProps = {
  title: string
  onBack?: () => void
  items?: ReactNode[]
  onClose?: () => void
}

const RightPanelHeader = ({ title, onBack, onClose, items }: RightPanelHeaderProps) => {
  return (
    <Box
      direction="row"
      gap="xxsmall"
      align="center"
      border={{ side: 'bottom', size: '1px', color: 'bg-2' }}
      pad={{ horizontal: '16px' }}
      height={`${PANEL_HEADER_HEIGHT}px`}
      width={{ max: '100%' }}
      justify="between"
    >
      <Box gap="xsmall" direction="row" align="center">
        {onBack && <IconButton label="Back" icon="arrow-back" tipPlacement="top" onClick={onBack} />}
        <Heading truncate css="line-height: normal; font-size: 18px" level={3} margin="none">
          {title}
        </Heading>
      </Box>
      <Box gap="xxsmall" direction="row" align="center" flex={false}>
        {items && (
          <Box direction="row" gap="xxsmall" align="center" flex={false}>
            {items.map((item, i) => (
              <Box key={i}>{item}</Box>
            ))}
          </Box>
        )}

        {onClose && (
          <IconButton data-testid="button-close" label="Close" tipPlacement="top" icon="close" onClick={onClose} />
        )}
      </Box>
    </Box>
  )
}

const PanelContainer = styled(Box)`
  display: block;
  background-color: ${themeColor('bg')};
  white-space: nowrap;
  height: 100%;
  position: relative;
  flex-grow: 1;
  bottom: 0;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
`

const PanelContentWrapper = styled(Box).attrs({ id: RIGHT_PANEL_SCROLL_CONTAINER_ID })`
  display: block;
  position: absolute;
  top: ${PANEL_HEADER_HEIGHT}px;
  padding: 16px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
`

const PanelFooter = styled(Box)`
  z-index: 1000; // show over loading panel
  bottom: ${themeEdgeSize('medium')};
  position: absolute;
  left: ${themeEdgeSize('medium')};
  width: calc(100% - ${themeEdgeSize('medium')} * 2);
`

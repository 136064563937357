import styled from 'styled-components/macro'

import { Icon } from '../icon'

// TODO: Fix add aria-hidden for icon so that items don't get read with the icon name first,
// or require manual aria-labels
export const ListItemIcon = styled(Icon)`
  path: {
    fill: currentColor !important;
  }
`

import { useState } from 'react'

import { Button, IconButton, IconName } from '@cutover/react-ui'
import { useComponentProps } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type ButtonKind = 'primary' | 'secondary' | 'tertiary'
type ButtonSize = 'small' | 'medium' | 'large'

type ButtonNodeProps = AppComponentNodeProps & {
  value?: string
  kind?: ButtonKind
  size?: ButtonSize
  icon?: IconName
  expanded?: boolean
  fullWidth?: boolean
  loading?: boolean
}

export const ButtonNode = ({ appId, resourceId, id, ...props }: ButtonNodeProps) => {
  const [loading, setLoading] = useState(false)
  const {
    value: label,
    kind,
    icon,
    expanded = false,
    size = 'large',
    fullWidth
  } = useComponentProps(appId, resourceId, id, props) as ButtonNodeProps
  const ButtonComponent: any = icon && !expanded ? IconButton : Button
  const buttonProps = {
    tipPlacement: 'bottom',
    icon,
    label,
    size,
    full: fullWidth,
    loading: !(icon && !expanded) && loading,
    onClick: () => setLoading(true)
  }

  switch (kind) {
    case 'primary':
      return <ButtonComponent primary {...buttonProps} />
    case 'secondary':
      return <ButtonComponent secondary {...buttonProps} />
    case 'tertiary':
      return <ButtonComponent tertiary {...buttonProps} />
    default:
      return <ButtonComponent tertiary {...buttonProps} />
  }
}

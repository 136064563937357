import styled, { css } from 'styled-components/macro'

import { LegacyBox as Box, LegacyBoxProps } from '../layout'
import { theme } from '../theme/theme-deprecated'
import { LegacyText as Text, LegacyTextProps as TextProps } from '../typography'
import { px2rem, transition } from '../utils'

export type LegacyFieldStateProps = {
  error?: boolean
  disabled?: boolean
  readonly?: boolean
  animate?: boolean
  focused?: boolean
  required?: boolean
}

export const LegacyFieldLabel = styled(Text)<LegacyFieldStateProps & TextProps>`
  white-space: nowrap;
  pointer-events: none;
  ${props => props.animate && transition('xfast')};
  color: ${({ error }) =>
    css`
      ${error ? theme.formField.error.label?.color : theme.formField.label.color}
    `};

  ${({ disabled, readonly }) =>
    !readonly &&
    disabled &&
    css`
      color: ${theme.formField.disabled.label?.color};
    `}

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: ' *';
      }
    `}
`

export const LegacyFormField = styled(Box)<LegacyFieldStateProps & LegacyBoxProps>`
  width: 100%;
  min-height: ${px2rem(33)};
  position: relative;
  border-bottom: ${({ error, focused }) =>
    css`
      ${px2rem(1)} dashed ${error
        ? theme.formField.error.border?.color
        : focused
        ? theme.formField.focus.border?.color
        : theme.formField.border.color}
    `};

  &:focus,
  &:focus-within {
    border-bottom-color: ${({ error, disabled }) =>
      css`
        ${disabled
          ? theme.formField.border.color
          : error
          ? theme.formField.error.border?.color
          : theme.formField.focus.border?.color}
      `};

    ${LegacyFieldLabel} {
      color: ${({ error, disabled }) =>
        css`
          ${disabled
            ? theme.formField.disabled.label?.color
            : error
            ? theme.formField.error.label?.color
            : theme.formField.focus.label?.color}
        `};
    }
  }

  input[type='text' i] {
    padding: 0;
  }
`

import { startOfDay } from 'date-fns'
import { groupBy } from 'lodash'

import { ActivityVM } from 'main/services/queries/use-activities'

type SortedActivityData = {
  sortedActivities: {
    [key: string]: ActivityVM[]
  }
  groupCounts: number[]
  lastInGroupIndexes: number[]
}

const GROUPING_INTERVAL_MINUTES = 5
const MILLISECONDS_PER_MINUTE = 60 * 1000
const GROUPING_THRESHOLD = GROUPING_INTERVAL_MINUTES * MILLISECONDS_PER_MINUTE

/**
 * Helper functions for sorting and grouping activities in the activity feed.
 * These functions provide utilities for sorting and grouping activities,
 * as well as applying specific criteria for grouping and merging activities.
 *
 * Criteria:
 * - Excluding certain activities from grouping.
 * - Time frame considerations.
 * - Matching activity keys, activists, and task IDs.
 * - Handling of task comments.
 *
 * These helper functions are merged into two main functions:
 * - `sortActivityData`: Sorts activities into groups based on date and calculates group statistics.
 * - `groupActivities`: Groups activities while applying specific criteria.
 *
 * Additionally, the `handleCombinedActivity` function is used to merge properties
 * for a new combined activity when two activities are merged together.
 */

export const sortActivityData = (activities: ActivityVM[]): SortedActivityData => {
  const sortedActivities = groupBy(
    activities,
    activity => activity && startOfDay(new Date(activity.created_at)).toISOString()
  )
  const groupCounts = Object.keys(sortedActivities).map(key => sortedActivities[key].length)
  const lastInGroupIndexes = groupCounts.map((_, index) =>
    groupCounts.slice(0, index + 1).reduce((a, b) => {
      return a + b
    })
  )
  return { sortedActivities, groupCounts, lastInGroupIndexes }
}

export const groupActivities = (activities: ActivityVM[]): ActivityVM[] => {
  const sortedActivities = [...activities].sort(
    (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime() || a.key.localeCompare(b.key)
  )

  return sortedActivities.reduce((acc: ActivityVM[], activity: ActivityVM) => {
    const lastActivity = acc[acc.length - 1]

    // Check if activity should be excluded from grouping
    if (excludeFromGrouping(lastActivity, activity)) {
      acc.push(activity)
    } else if (haveMatchingChanges(lastActivity, activity) || withinSameTaskChanges(lastActivity, activity)) {
      if (
        runbookCommentActivity(lastActivity, activity) ||
        (taskCommentActivity(lastActivity, activity) && withinSameTaskChanges(lastActivity, activity))
      ) {
        activity['grouped'] = true
        acc.push(activity)
      } else {
        acc[acc.length - 1] = handleCombinedActivity(lastActivity, activity)
      }
    } else {
      acc.push(activity)
    }

    return acc
  }, [])
}

// Check if activities should be excluded from grouping based on specific criteria
const excludeFromGrouping = (lastActivity: ActivityVM, newActivity: ActivityVM) => {
  return (
    !lastActivity ||
    !newActivity ||
    preventGroupingByKey(newActivity) ||
    !withinGroupingPeriod(lastActivity, newActivity) ||
    !matchingActivityKey(lastActivity, newActivity) ||
    !matchingActivist(lastActivity, newActivity) ||
    (withinSameTaskChanges(lastActivity, newActivity) && observeNewValues(lastActivity, newActivity)) ||
    (!withinSameTaskChanges(lastActivity, newActivity) && taskCommentActivity(lastActivity, newActivity))
  )
}

// Check if changes arrays are the same to be able to merge multiple activities
const haveMatchingChanges = (lastActivity: ActivityVM, newActivity: ActivityVM): boolean => {
  const lastActivityChanges = lastActivity?.changes ?? []
  const newActivityChanges = newActivity?.changes ?? []

  if (!newActivityChanges.length && !lastActivityChanges.length) {
    return true
  }

  if (lastActivityChanges.length !== newActivityChanges.length) {
    return false
  }

  lastActivityChanges.sort((a, b) => (a.field ?? '').localeCompare(b.field ?? ''))
  newActivityChanges.sort((a, b) => (a.field ?? '').localeCompare(b.field ?? ''))

  // Check if all corresponding changes are the same
  return lastActivityChanges.every(
    (change, index) =>
      change.field === newActivityChanges[index].field && change.value === newActivityChanges[index].value
  )
}

const withinSameTaskChanges = (lastActivity: ActivityVM, newActivity: ActivityVM): boolean => {
  const lastTask = lastActivity.trackables?.find(t => t.type === 'Task')
  const newTask = newActivity.trackables?.find(t => t.type === 'Task')

  return !!lastTask && !!newTask && lastTask.properties?.internal_id === newTask.properties?.internal_id
}

// Check if some fields are overriden by new activity
const observeNewValues = (lastActivity: ActivityVM, newActivity: ActivityVM) => {
  const lastActivityChangesMap = (lastActivity.changes ?? []).map(change => change.field)
  const newActivityChangesMap = (newActivity.changes ?? []).map(change => change.field)

  return lastActivityChangesMap.some(item => newActivityChangesMap.includes(item))
}

// Check if two activities share same activist
const matchingActivist = (lastActivity: ActivityVM, newActivity: ActivityVM) => {
  return (
    newActivity.activist.type === lastActivity.activist.type &&
    newActivity.activist.properties.id === lastActivity.activist.properties.id
  )
}

// Check if two activities share same key
const matchingActivityKey = (lastActivity: ActivityVM, newActivity: ActivityVM) => {
  return lastActivity.key === newActivity.key
}

// Check if the time difference between two activities is within grouping preiod
const withinGroupingPeriod = (lastActivity: ActivityVM, newActivity: ActivityVM) => {
  return (
    Math.abs(new Date(newActivity.created_at).getTime() - new Date(lastActivity.created_at).getTime()) <=
    GROUPING_THRESHOLD
  )
}

const taskCommentActivity = (lastActivity: ActivityVM, newActivity: ActivityVM) => {
  return lastActivity.key && newActivity.key === 'task.commented'
}

const runbookCommentActivity = (lastActivity: ActivityVM, newActivity: ActivityVM) => {
  return lastActivity.key && newActivity.key === 'runbook.commented'
}

// Prevents snippets, stream updates and Apps activities from grouping
const preventGroupingByKey = (activity: ActivityVM) => {
  return activity.key === 'runbook.merged' || activity.key === 'stream.updated' || activity.key.startsWith('external')
}

// Merge properties for new combined activity
const handleCombinedActivity = (lastActivity: ActivityVM, newActivity: ActivityVM): ActivityVM => {
  const combinedActivity: ActivityVM = {
    ...lastActivity,
    trackables: [...(lastActivity.trackables ?? []), ...(newActivity.trackables ?? [])],
    display: {
      message: lastActivity.display?.message?.concat(`<p> ${newActivity?.display?.message} </p>`)
    },
    created_at: new Date(
      Math.min(new Date(lastActivity.created_at).getTime(), new Date(newActivity.created_at).getTime())
    ),
    changes: [...(lastActivity.changes ?? [])]
  }

  // Should have one trackable if the change is within one task
  if (withinSameTaskChanges(lastActivity, newActivity) || newActivity.key === 'runbook.commented') {
    combinedActivity.trackables = [...(newActivity.trackables ?? [])]
    combinedActivity.changes = [...(combinedActivity.changes ?? []), ...(newActivity.changes ?? [])]
  }

  return combinedActivity
}

import { CSSProperties, HTMLProps, isValidElement, MutableRefObject } from 'react'
import { FloatingContext, FloatingFocusManager, FloatingPortal } from '@floating-ui/react'

import { ListItem, ListItemText, OverlayBox, themeColor } from '@cutover/react-ui'
import { CSSDimensionConstraints } from '@cutover/react-ui/src/theme'
import { useLanguage } from 'main/services/hooks'
import { TaskShortcutMenuItem } from './use-task-list-item-menu-builder'

type TaskItemCreateMenuProps = {
  isMenuOpen: boolean
  menuItems?: TaskShortcutMenuItem[]
  getFloatingProps: (userProps?: HTMLProps<HTMLElement> | undefined) => Record<string, unknown>
  getItemProps: (userProps?: Omit<HTMLProps<HTMLElement>, 'selected' | 'active'> | undefined) => Record<string, unknown>
  context: FloatingContext
  setFloating: (node: HTMLElement | null) => void
  floatingStyles: CSSProperties
  listRef: MutableRefObject<(HTMLElement | null)[]>
  activeIndex: number | null
  onSelectMenu: ({ activeIndex, item }: { activeIndex?: number; item?: TaskShortcutMenuItem }) => void
  constraints?: CSSDimensionConstraints
}

export const TaskItemCreateMenu = ({
  isMenuOpen,
  menuItems,
  getFloatingProps,
  getItemProps,
  context,
  setFloating,
  floatingStyles,
  listRef,
  activeIndex,
  onSelectMenu,
  constraints
}: TaskItemCreateMenuProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'taskList' })

  if (!menuItems) return null

  return (
    <FloatingPortal>
      {isMenuOpen && (
        <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
          <OverlayBox
            {...getFloatingProps({
              ref: setFloating,
              style: {
                ...floatingStyles
              }
            })}
            constraints={constraints}
            css={`
              z-index: 1;
              border: 1px solid rgba(0, 0, 0, 0.05);
              overflow: auto;
            `}
          >
            {menuItems && menuItems.length > 0 ? (
              <>
                {menuItems.map((item, index) => {
                  const { label, icon, props } = item
                  return (
                    <ListItem
                      {...getItemProps({
                        key: `task-create-menu-${index}`,
                        ref(node) {
                          listRef.current[index] = node
                        }
                      })}
                      onMouseDown={e => {
                        e.preventDefault()
                        onSelectMenu?.({ item })
                      }}
                      aria-selected={activeIndex === index}
                      size="small"
                      startComponent={isValidElement(icon) && icon}
                      css={`
                        background-color: ${activeIndex === index ? themeColor('bg-1') : ''};
                        &:hover {
                          background-color: ${themeColor('bg-1')};
                          cursor: pointer;
                        }
                      `}
                    >
                      <ListItemText
                        css={`
                          padding-left: ${props?.stream?.depth ? props.stream.depth * 16 : 0}px;
                        `}
                      >
                        {label}
                      </ListItemText>
                    </ListItem>
                  )
                })}
              </>
            ) : (
              <ListItem size="small">
                <ListItemText>{t('noItemsFound')}</ListItemText>
              </ListItem>
            )}
          </OverlayBox>
        </FloatingFocusManager>
      )}
    </FloatingPortal>
  )
}

import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ContentWrapper } from '@cutover/react-ui'
import { IntegrationSettingListItem } from './integration-setting-list-item'
import { useIntegrationSettingsQuery } from '../../../services/queries/use-integration-settings'
import { useIntegrationSettingEditPanel } from 'main/context/panel-context'
import { useLanguage } from 'main/services/hooks'
import { IntegrationActionItem, IntegrationSetting } from 'main/services/queries/types'

export const IntegrationSettingsPage = () => {
  const { t } = useLanguage('integrationSettings')
  const { openPanel, clearPanel } = useIntegrationSettingEditPanel()
  const [searchParams, setSearchParams] = useSearchParams()
  const { data, isLoading, isError } = useIntegrationSettingsQuery()

  const settings = useMemo(
    () => data?.integration_settings.sort((a, b) => a.id - b.id).filter(e => !e.archived),
    [data]
  )

  useEffect(() => {
    const settingId = searchParams.get('setting_id')
    if (settingId) {
      openPanel({ integrationSettingId: parseInt(settingId, 10) })
    } else {
      clearPanel()
    }
  }, [searchParams])

  const handleSettingSelection = (setting: IntegrationSetting) => {
    const settingId = searchParams.get('setting_id')
    const actionItemId = searchParams.get('integration_action_item_id')
    if (!actionItemId && settingId && parseInt(settingId) === setting.id) {
      clearSearchParams()
    } else {
      setSearchParams({ setting_id: setting.id.toString() })
    }
  }

  const handleActionItemSelection = (actionItem: IntegrationActionItem) => {
    const setting = settings?.find((setting: IntegrationSetting) => setting.id === actionItem.integration_setting?.id)
    const actionItemId = searchParams.get('integration_action_item_id')
    if (actionItemId && parseInt(actionItemId) === actionItem.id) {
      clearSearchParams()
      clearPanel()
    } else {
      setSearchParams({
        setting_id: setting?.id.toString() || '',
        integration_action_item_id: actionItem.id.toString()
      })
    }
  }

  const clearSearchParams = () => {
    searchParams.delete('integration_action_item_id')
    searchParams.delete('setting_id')
    setSearchParams(searchParams)
  }

  return (
    <ContentWrapper loading={isLoading} error={isError} errorMessage={t('error')} css="padding: 8px; margin: 0;">
      {settings?.map((setting: IntegrationSetting) => {
        return (
          <IntegrationSettingListItem
            key={setting.id}
            setting={setting}
            handleSelection={handleSettingSelection}
            handleActionItemSelection={handleActionItemSelection}
          />
        )
      })}
    </ContentWrapper>
  )
}

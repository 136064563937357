import {
  KeyboardEventHandler,
  memo,
  MouseEventHandler,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef
} from 'react'
import { format, getUnixTime as toUnix } from 'date-fns'
import { Stack } from 'grommet'
import styled from 'styled-components'

import {
  Box,
  Button,
  ColorProp,
  Icon,
  IconButton,
  IconName,
  ProgressPie,
  resolveColor,
  TaskItemIcon,
  Text
} from '@cutover/react-ui'
import * as ListItem from 'main/components/shared/runbook-list-item/runbook-list-item-layout'
import { useLanguage } from 'main/services/hooks'
import { RunbookListRunbook, RunbookTypeType } from 'main/services/queries/types'

type RunbookItemRightContentProps = {
  runbook: RunbookListRunbook
  runbookType: RunbookTypeType | undefined
  loading?: boolean
  onClickDuplicate?: (id: number) => void
  onClickRestore?: (e: SyntheticEvent) => void
  onClickEdit?: (id: number) => void
  onClickArchive?: (id: number) => void
  showActions?: boolean
  timezone?: string
}

export const RunbookItemRightSideContent = memo(
  ({
    runbook,
    runbookType,
    loading,
    onClickDuplicate,
    onClickRestore,
    onClickArchive,
    onClickEdit,
    showActions
  }: RunbookItemRightContentProps) => {
    const { run_type, start_display, end_display, stage, template_type, archived, auto_start, completion } = runbook
    const { t } = useLanguage('runbooks')
    const linkedStatus = runbook?.meta?.linked_status?.linked_resource_status
    const canCreateOrDuplicate = !!onClickDuplicate
    const canRestore = !!onClickRestore

    const copyRef = useRef<HTMLButtonElement>(null)

    let text: ReactNode | null = null,
      button: ReactNode | null = null,
      icon: ReactNode | null = null,
      actions: ReactNode | null = null

    const handleKeyboardDuplicate: KeyboardEventHandler = useCallback(
      e => {
        if (e.key === ' ' || e.key === 'Spacebar') {
          e.preventDefault()
          onClickDuplicate?.(runbook.id)
        }
      },
      [runbook, onClickDuplicate]
    )

    const handleClickDuplicate: MouseEventHandler = useCallback(
      e => {
        e.preventDefault()
        onClickDuplicate?.(runbook.id)
      },
      [runbook, onClickDuplicate]
    )

    const handleKeyboardEdit: KeyboardEventHandler = useCallback(
      e => {
        if (e.key === ' ' || e.key === 'Spacebar') {
          e.preventDefault()
          e.stopPropagation()
          onClickEdit?.(runbook.id)
        }
      },
      [runbook, onClickEdit]
    )

    const handleClickEdit: MouseEventHandler = useCallback(
      e => {
        e.preventDefault()
        e.stopPropagation()
        onClickEdit?.(runbook.id)
      },
      [runbook, onClickEdit]
    )

    const handleKeyboardArchive: KeyboardEventHandler = useCallback(
      e => {
        if (e.key === ' ' || e.key === 'Spacebar') {
          e.preventDefault()
          onClickArchive?.(runbook.id)
        }
      },
      [runbook, onClickArchive]
    )

    const handleClickArchive: MouseEventHandler = useCallback(
      e => {
        e.preventDefault()
        onClickArchive?.(runbook.id)
      },
      [runbook, onClickArchive]
    )

    actions = useMemo(() => {
      return !showActions ? null : (
        <>
          {canCreateOrDuplicate && (
            <ListItem.RightItemActionContainer>
              <MemoizedIconButton
                ref={copyRef}
                icon="copy"
                data-testid="button-duplicate-runbook"
                label={
                  template_type === 'default'
                    ? t('list.listItem.rowAction.create')
                    : t('list.listItem.rowAction.duplicate')
                }
                onClick={handleClickDuplicate}
                onKeyUp={handleKeyboardDuplicate}
                tipPlacement="top"
                customStyle="secondary-alt"
              />
            </ListItem.RightItemActionContainer>
          )}
          <ListItem.RightItemActionContainer>
            <MemoizedIconButton
              icon="edit"
              data-testid="button-edit-runbook"
              label={t('list.listItem.rowAction.edit')}
              onClick={handleClickEdit}
              onKeyUp={handleKeyboardEdit}
              tipPlacement="top"
              customStyle="secondary-alt"
            />
          </ListItem.RightItemActionContainer>
          <ListItem.RightItemActionContainer>
            <MemoizedIconButton
              icon="archive"
              data-testid="button-archive-runbook"
              label={t('list.listItem.rowAction.archive')}
              onClick={handleClickArchive}
              onKeyUp={handleKeyboardArchive}
              tipPlacement="top"
              customStyle="secondary-alt"
            />
          </ListItem.RightItemActionContainer>
        </>
      )
    }, [template_type, t, handleClickDuplicate, handleClickArchive, handleClickEdit, showActions])

    if (loading) {
      icon = (
        <Box width={{ min: '24px' }} height="24px" animation="rotateRight" margin={{ right: '6px' }} alignSelf="center">
          <Icon icon="spinner" size="24px" color="text-light" />
        </Box>
      )

      return (
        <>
          <ListItem.RightItemIconContainer>{icon}</ListItem.RightItemIconContainer>
        </>
      )
    }

    const restoreButton = canRestore ? (
      <Button secondary label={t('list.listItem.rowAction.restore')} onClick={onClickRestore} />
    ) : null

    const createButton = canCreateOrDuplicate ? (
      <Button
        data-testid="create-from-template"
        secondary
        label={t('list.listItem.rowAction.create')}
        icon="copy"
        onClick={handleClickDuplicate}
      />
    ) : null

    if (template_type === 'snippet') {
      button = archived ? restoreButton : null
      icon = <CircleWithIcon icon="play-arrow" />
    }

    if (template_type === 'default') {
      button = archived ? restoreButton : createButton
    }

    if (template_type === 'off') {
      button = archived ? restoreButton : null
    }

    if (linkedStatus === 'skipped') {
      text = <RightItemText color="text-light">{t('list.listItem.stage.skipped')}</RightItemText>
      icon = <CircleWithIcon icon="skipped" />
    }

    if (linkedStatus === 'abandoned') {
      text = <RightItemText color="text-light">{t('list.listItem.stage.abandoned')}</RightItemText>
      icon = <CircleWithIcon icon="abandoned" />
    }

    if (run_type === 'live') {
      switch (stage) {
        case 'cancelled':
          text = <RightItemText color="text-disabled">{t('list.listItem.stage.cancelled')}</RightItemText>
          icon = <CircleWithIcon icon="abandoned" />
          break
        case 'active':
          text = (
            <RightItemText color="text">
              {runbookType?.incident ? `Started ${dateDisplay(start_display)}` : `Due ${dateDisplay(end_display)}`}
            </RightItemText>
          )
          icon = (
            <CircleWithIcon
              color={runbookType?.incident ? 'primary' : undefined}
              icon={runbookType?.incident ? undefined : 'play-arrow'}
              value={runbookType?.incident ? undefined : completion}
              active
              isIncident={runbookType?.incident}
            />
          )
          break
        case 'paused':
          text = <RightItemText color="text">{t('list.listItem.stage.paused')}</RightItemText>
          icon = <CircleWithIcon icon="pause" value={completion} />
          break
        case 'complete':
          text = <RightItemText color="text">{t('list.listItem.stage.complete')}</RightItemText>
          icon = <CircleWithIcon icon="check-solid" color="primary" />
          break
      }
    }

    const isActiveRehearsal = stage === 'active' && run_type === 'rehearsal'
    const isPausedRehearsal = stage === 'paused' && run_type === 'rehearsal'

    // rehearsal / planning
    const [planningModeRightLabel, planningModeColor]: [string, ColorProp] = !!start_display
      ? start_display < toUnix(new Date())
        ? [t('list.listItem.stage.startInPast'), 'text-light']
        : [(auto_start ? 'Auto Start ' : '') + dateDisplay(start_display), 'text']
      : [t('list.listItem.stage.unscheduled'), 'text-light']

    // set defaults
    icon = icon ?? (
      <CircleWithIcon
        icon={isPausedRehearsal ? 'pause' : auto_start ? 'fixed-start' : 'play-arrow'}
        active={isActiveRehearsal}
        value={isActiveRehearsal ? completion : undefined}
      />
    )
    text = text ?? <RightItemText color={planningModeColor}>{planningModeRightLabel}</RightItemText>

    // reset for template types
    text = template_type !== 'off' ? null : text
    icon = template_type === 'default' ? null : icon

    return (
      <>
        {actions && (
          <ListItem.RightItemActionsContainer
            data-testid="row-actions"
            width={{ min: `${40 * (canCreateOrDuplicate ? 3 : 2)}px` }}
          >
            {actions}
          </ListItem.RightItemActionsContainer>
        )}
        {template_type === 'off' && archived ? (
          <ListItem.RightItemHoverToggleContent>
            {text && <ListItem.RightItemTextContainer>{text}</ListItem.RightItemTextContainer>}
            {button && <ListItem.RightItemButtonContainer>{button}</ListItem.RightItemButtonContainer>}
          </ListItem.RightItemHoverToggleContent>
        ) : (
          <>
            {text && (
              <ListItem.RightItemTextContainer data-testid="right-display-content-text">
                {text}
              </ListItem.RightItemTextContainer>
            )}
            {button && <ListItem.RightItemButtonContainer>{button}</ListItem.RightItemButtonContainer>}
          </>
        )}
        {icon && <ListItem.RightItemIconContainer>{icon}</ListItem.RightItemIconContainer>}
      </>
    )
  }
)

const CircleWithIcon = ({
  color = 'bg-4',
  icon,
  value,
  active = false,
  isIncident = false
}: {
  color?: ColorProp
  icon?: IconName
  value?: number
  active?: boolean
  isIncident?: boolean
}) => {
  return (
    <Box flex={false} width="40px" height="40px" className="rb-list-item-stage-icon">
      {value !== undefined ? (
        active ? (
          <ProgressPie value={value} active data-testid="progress-pie-active" />
        ) : (
          <Stack anchor="center">
            <ProgressPie value={value} active={active} data-testid="progress-pie-inactive" />
            {!!icon && (
              <Box align="center" data-testid={`right-display-icon-${icon}`}>
                <Icon
                  icon={icon}
                  size="medium"
                  color={color}
                  css={`
                    display: flex;
                    align-items: center;
                  `}
                />
              </Box>
            )}
          </Stack>
        )
      ) : (
        <Circle borderColor={color} align="center" justify="center" data-testid={`right-display-icon-${icon}`}>
          {!!icon && !isIncident && <Icon icon={icon} size="medium" color={color} />}
          {isIncident && <TaskItemIcon icon="normal" color="primary" stageIcon="more-horizontal" inProgress />}
        </Circle>
      )}
    </Box>
  )
}

const Circle = styled(Box).attrs(() => ({
  width: { min: '40px' },
  height: '40px',
  round: 'full'
}))<{ borderColor: ColorProp }>`
  border: 4px solid ${({ theme, borderColor }) => resolveColor(borderColor, theme)};
`

// helper function
const dateDisplay = (seconds: number) => format(new Date(seconds * 1000), 'd MMM HH:mm')

export const RunbookRestoreButtonContainer = styled(Box)``

const MemoizedIconButton = memo(IconButton)

const RightItemText = styled(Text)`
  white-space: nowrap;
`

import 'react-app-polyfill/stable'
import { duration as formatDuration } from '@cutover/react-ui'
import { eventManager } from 'event-manager'
import { registerApplication, start } from 'single-spa'
import { AppTypes } from 'single-spa/app-types'
import { isAngularDisabled, isMatchLegacyReact } from 'single-spa/react-routes'

import angularLifecycles from './single-spa/angular-lifecycles'
import reactLifecyclesLegacy from './single-spa/react-legacy-lifecycles'
import reactLifecycles from './single-spa/react-lifecycles'
import reactAppNoSession from './single-spa/react-no-session-lifecycles'
import reactPortalComponents from './single-spa/react-portal-components-lifecycles'

////////////////////////
// BROWSER HASH SETUP //
////////////////////////
const browserHashLockLabel = 'browserHashLock'
const browserHashLabel = 'browserHash'
// browserHash is used to determine whether a message from the websocket is from the same tab, each tab
// should have a unique value.
window.addEventListener('beforeunload', () => {
  // before unloading page, remove browserHashLock. Is called when window is refreshed or closed
  window.sessionStorage.removeItem(browserHashLockLabel)
})

// session storage is copied if tab is duplicated. So, if present, clear browserHash and browserHashLock.
// Otherwise user could end up in a state of having multiple tabs with the same browserHash, which could
// have the effect of considering multiple tabs as 'active' and preventing websocket messages updating the UI
if (window.sessionStorage.getItem(browserHashLockLabel)) {
  window.sessionStorage.removeItem(browserHashLabel)
  window.sessionStorage.removeItem(browserHashLockLabel)
}

// set browserHashLock to true on first load or after the session storage has been cleared
window.sessionStorage.setItem(browserHashLockLabel, 'true')

// Check for current value to preserve previous browserHash on reload, otherwise set browserHash
if (!window.sessionStorage.getItem(browserHashLabel))
  window.sessionStorage.setItem(browserHashLabel, Math.random().toString(14).slice(2))

////////////////////////////
// BROWSER HASH SETUP END //
////////////////////////////

declare global {
  interface Window {
    angularJS: {
      settingsPanelOpen: boolean
      setAngularJSSettingsPanelOpenState: Function
      handleError?: Function
      openCreateRunbookModal?: Function
      openCreateRunbookFromTemplateModal?: Function
      redirectToRunbookListPage?: Function
      redirectToTaskComment?: Function
      redirectToRunbookComment?: Function
      addNewRunbook?: Function
      duplicateRunbook?: Function
      reloadAccounts?: Function
    }
    reactJS: {
      loadRunbookUsers?: Function
      openChooseRunbookTemplateModal?: Function
      openStartTimeRunbookModal?: Function
      openRunbookTeamModal?: Function
    }
    heap?: any
  }
}

////////////////////////////////////////////////////////////////////////
// REACT SSPA
////////////////////////////////////////////////////////////////////////

registerApplication(AppTypes.React, reactLifecycles, ({ hash }) => hash.match(/\/user-verify/gi) === null)

////////////////////////////////////////////////////////////////////////
// LEGACY: REACT LogicRoom SSPA
////////////////////////////////////////////////////////////////////////

/**
 * This is the legacy React application that's responsable for loading
 * a set of global settings page in the app. This application was initially
 * developed by a company called LogicRoom and follows an approach we've
 * since deprecated. It makes use of a global "Inversion of Control" container
 * (see IOC.ts) which is required to be loaded prior to anything else. This
 * pattern ultimately led to a very complex codebase that is both complex
 * to understand and difficult to scale as we're knocking down Angular in
 * favour of React.
 *
 * If you are writing React code, please look at the ReactAppTypes.Session
 * application and only touch this React app if you know what you're doing.
 */

registerApplication(AppTypes.ReactLegacySession, reactLifecyclesLegacy, isMatchLegacyReact)

////////////////////////////////////////////////////////////////////////
// LEGACY: REACT UNAUTHENTICATED SSPA
////////////////////////////////////////////////////////////////////////

/*
  This is the react application used for unauthorised access only, and it's
  only active on the user verification page.

  Note: this separate app is only needed currently because the CurrentPagePresenter
  and IOC rely on authenticated accesss, whilst this page is for unauthenticated
  users (e.g. those who don't have a session token stored in localStorage).
  We are planning to refactor this after which this app can be removed and
  folded into the main react application.
  See https://cutover.atlassian.net/browse/CFE-41 and other related issues.
*/
registerApplication(
  AppTypes.ReactLegacyNoSession,
  reactAppNoSession,
  ({ hash }) => hash.match(/\/user-verify/gi) !== null
)

////////////////////////////////////////////////////////////////////////
// REACT PORTAL COMPONENTS SSPA
////////////////////////////////////////////////////////////////////////

/*
  This is another approach to react portal components trialed by the ARO squad.
  It should not be used and will be deprecated in the future in favour of
  ReactAppTypes.Session (see above).

  Note: this is experimental for now and we should focus on migrating to react
  as part of the existing reactApp rather than adding additional microfrontends.
*/
registerApplication(AppTypes.ReactLegacyPortalComponents, reactPortalComponents, () => true)

////////////////////////////////////////////////////////////////////////
// ANGULAR SSPA
////////////////////////////////////////////////////////////////////////
if (!isAngularDisabled()) {
  registerApplication(
    AppTypes.AngularLegacy,
    angularLifecycles,
    // The angular application is always active
    () => true,
    // https://single-spa.js.org/docs/ecosystem-angularjs/#custom-props
    { eventManager, formatDuration }
  )
} else {
  console.log(
    '!!!\n\tWARNING: AngularJS is currently disabled. To reenable Angular please run the following command in your console:\n\n\tlocalStorage.removeItem("disableAngular")\n\n\tYou need the following non-default code flags to be enabled:\n\t- React workspace routes!!!'
  )
}

////////////////////////////////////////////////////////////////////////
// INIT
////////////////////////////////////////////////////////////////////////

window.angularJS = {
  settingsPanelOpen: false,
  setAngularJSSettingsPanelOpenState: function (state: boolean) {
    return null
  }
}

window.reactJS = {}

start()

import { useRecoilCallback } from 'recoil'

import { useProcessRunbookVersionResponse } from './runbook-version-operations'
import { useProcessStreamResponse } from './stream-operations'
import { RunbookResponse } from 'main/services/api/data-providers/runbook-types'
import { useProcessRunResponse } from './run-operations'
import { useProcessRunbookResponse } from './runbook-operations'
import { useProcessTaskResponse } from './task-operations'
import { isVersionCurrentState } from '../models'

export const useRunbookChannelResponseProcessor = () => {
  const processTaskResponse = useProcessTaskResponse()
  const processRunResponse = useProcessRunResponse()
  const processRunbookResponse = useProcessRunbookResponse()
  const processRunbookVersionResponse = useProcessRunbookVersionResponse()
  const processStreamResponse = useProcessStreamResponse()

  return useRecoilCallback(
    ({ snapshot }) =>
      async (response: RunbookResponse) => {
        const isCurrentVersion = await snapshot.getPromise(isVersionCurrentState)
        // Do not process any updates if the version being viewed is not the runbook current version,
        // unless the response meta has a runbook property, or this is a 'Runbook' type response,
        // in which case we want to update the runbook's state.
        if (
          !isCurrentVersion &&
          !response?.meta.hasOwnProperty('runbook') &&
          response?.meta.headers.request_class !== 'Runbook'
        )
          return

        switch (response?.meta.headers.request_class) {
          case 'Task':
            processTaskResponse(response)
            break
          case 'Run':
            await processRunResponse(response)
            break
          case 'Runbook':
            processRunbookResponse(response)
            break
          case 'RunbookVersion':
            processRunbookVersionResponse(response)
            break
          case 'Stream':
            processStreamResponse(response)
            break
          default:
            return
        }
      },
    [
      processTaskResponse,
      processRunResponse,
      processRunbookResponse,
      processRunbookVersionResponse,
      processStreamResponse
    ]
  )
}

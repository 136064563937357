import { useEffect, useRef } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { addMinutes, isPast } from 'date-fns'
import { Controller, useForm, useFormState } from 'react-hook-form'

import { DateTimePicker, RadioboxGroup, Select, TextEditor, TextInput, WeekdayPicker } from '@cutover/react-ui'
import { SchedulePanelContent } from './runbook-dashboard-schedule-panel-styles'
import { UpdateUserSettingParams, UserSetting } from './runbook-dashboard-schedule-types'
import { schema } from '../runbook-dashboard/share-runbook-dashboard-email-form'
import { RunbookDashboardShareEmailFormType } from '../runbook-dashboard/share-runbook-dashboard-types'
import { RecipientMultiselect, TeamRecipient, UserRecipient } from 'main/components/recipients-multiselect'
import { TimezoneSelect } from 'main/components/shared/form/timezone-select'
import { useLanguage } from 'main/services/hooks'
import { useInitialMount } from 'main/services/hooks/use-initial-mount'
import { Filter, FilterResponse, useFiltersQuery } from 'main/services/queries/use-filters'

type RunbookDashboardScheduleFormProps = {
  setting: UserSetting
  runbookId: number
  userId: number
  runbookVersionId: number
  onBack: () => void
  onSubmit: (setting: UpdateUserSettingParams) => void
  onClose: () => void
}

export const RunbookDashboardScheduleForm = ({
  setting,
  runbookId,
  userId,
  runbookVersionId,
  onBack,
  onSubmit,
  onClose
}: RunbookDashboardScheduleFormProps) => {
  const recipientMultiselectRef = useRef<{ reset: () => void }>()
  const isInitialMount = useInitialMount()

  const { t } = useLanguage('dashboards')
  const { control, register, watch, reset, handleSubmit } = useForm<RunbookDashboardShareEmailFormType>({
    mode: 'all',
    resolver: yupResolver(schema),
    context: { scheduling: true },
    defaultValues: getDefaultValues(setting)
  })
  const { isDirty, errors } = useFormState({ control })

  const frequencyType = watch('frequencyType')
  const dashboardView = watch('dashboardView')

  const { data: filterData, isLoading: isFilterDataLoading } = useFiltersQuery('Runbook', runbookId)

  useEffect(() => {
    if (isInitialMount) return
    resetForm()
  }, [setting])

  const resetForm = () => {
    reset(getDefaultValues(setting))
    recipientMultiselectRef.current?.reset?.()
  }

  return (
    <>
      <SchedulePanelContent
        isDirty={isDirty}
        onBack={onBack}
        onCancel={resetForm}
        onClose={onClose}
        onSave={() => handleSubmit(form => onSubmit(buildScheduleEmailForPut(form, setting, filterData)))()}
        title={t('dashboard:shareSchedulePanel.editHeading')}
      >
        <TextInput
          label={t('dashboard:shareSchedulePanel:titleLabel')}
          {...register('name')}
          required
          hasError={!!errors.name}
        />
        <Controller
          control={control}
          name="recipients"
          render={({ field: { onChange } }) => (
            <RecipientMultiselect
              getInitialRecipients
              ref={recipientMultiselectRef}
              users={setting.data.schedules[0].user_recipients.sort()}
              teams={setting.data.schedules[0].team_recipients.sort()}
              runbookId={runbookId}
              runbookVersionId={runbookVersionId}
              required
              hasError={!!errors.recipients}
              accountId={userId}
              onChange={recipients => {
                const users = (recipients || []).filter(recipient => recipient.type === 'user') as UserRecipient[]
                const teams = (recipients || []).filter(recipient => recipient.type === 'team') as TeamRecipient[]
                const value = {
                  users: users.map(user => user.id || user.handle).sort(),
                  teams: teams.map(team => team.id).sort()
                }
                onChange(value)
              }}
            />
          )}
        />

        <Controller
          name="dashboardView"
          control={control}
          defaultValue="entire"
          render={({ field: { name, onChange, value, onBlur, ref } }) => (
            <RadioboxGroup
              name={name}
              required
              onChange={onChange}
              value={value ?? 'entire'}
              label={t('dashboard:shareModal.dashboardViewLabel')}
              direction="row"
              onBlur={onBlur}
              ref={ref}
              options={[
                { value: 'entire', label: t('dashboard:shareModal.dashboardViewOption') },
                { value: 'savedFilters', label: t('dashboard:shareModal.savedFilterOption') }
              ]}
            />
          )}
        />

        {dashboardView === 'savedFilters' && (
          <Controller
            name="filter"
            control={control}
            render={({ field: { onChange, value, ref } }) => {
              return (
                <Select
                  filterKeys={['label']}
                  data-testid="dashboard-schedule-filter-select"
                  hasError={!!errors.filter}
                  inlineError={errors.filter?.message}
                  onChange={onChange}
                  placeholder={t('dashboard:shareModal.savedFilterOption')}
                  inputRef={ref}
                  label={t('dashboard:shareModal:savedFilterOption')}
                  icon="filter"
                  value={value || ''}
                  loading={isFilterDataLoading}
                  options={
                    !!filterData
                      ? filterData.filters.map((filter: Filter) => ({
                          label: filter.name,
                          value: filter.id.toString()
                        }))
                      : []
                  }
                />
              )
            }}
          />
        )}

        <Controller
          name="frequencyType"
          control={control}
          defaultValue="once"
          render={({ field: { name, onChange, value, onBlur, ref } }) => (
            <RadioboxGroup
              required
              label={t('dashboard:shareModal.frequencyLabel')}
              direction="column"
              name={name}
              onChange={onChange}
              value={value ?? 'once'}
              onBlur={onBlur}
              ref={ref}
              options={[
                { value: 'once', label: t('dashboard:shareModal.frequencyOptionOnce') },
                { value: 'every_15_min', label: t('dashboard:shareModal.frequencyOptionEvery15Min') },
                { value: 'every_30_min', label: t('dashboard:shareModal.frequencyOptionEvery30Min') },
                { value: 'hourly', label: t('dashboard:shareModal.frequencyOptionHourly') },
                { value: 'daily', label: t('dashboard:shareModal.frequencyOptionDaily') },
                { value: 'weekly', label: t('dashboard:shareModal.frequencyOptionWeekly') }
              ]}
            />
          )}
        />
        {frequencyType === 'weekly' && (
          <Controller
            control={control}
            name="frequencyDays"
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <WeekdayPicker
                required
                value={value ?? []}
                hasError={!!errors.frequencyDays}
                onChange={(event: any) => event && onChange(event.value)}
                label={t('dashboard:shareSchedulePanel:frequencyDayLabel')}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="startsAt"
          render={({ field: { onChange, value, ref, onBlur } }) => (
            <DateTimePicker
              required
              hasError={!!errors.startsAt}
              data-testid="runbook-dashboard-schedule-datepicker"
              value={value || null}
              onChange={val => {
                if (!val) return onChange(val)

                if (isPast(val)) return onChange(addMinutes(Date.now(), 1))
                onChange(val)
              }}
              label={t('dashboard:shareSchedulePanel:timeLabel')}
              inputRef={ref}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="timezone"
          render={({ field: { onChange, value } }) => (
            <TimezoneSelect
              required
              hasError={!!errors.timezone}
              label={t('dashboard:shareSchedulePanel:timezoneLabel')}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Controller
          name="message"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextEditor
              value={value || ''}
              defaultValue={setting.data.schedules[0].message || ''}
              label={t('dashboard:shareSchedulePanel:messageLabel')}
              onChange={onChange}
            />
          )}
        />
      </SchedulePanelContent>
    </>
  )
}

const getDefaultValues = (setting: UserSetting) => {
  const schedule = setting.data.schedules[0]
  return {
    name: schedule.name,
    recipients: {
      users: schedule.user_recipients.sort(),
      teams: schedule.team_recipients.sort()
    },
    dashboardView: schedule.filter_name ? 'savedFilters' : 'entire',
    filter: schedule.filter_id ? schedule.filter_id.toString() : null,
    frequencyType: schedule.frequency ? schedule.frequency.option : null,
    frequencyDays: schedule.day_options?.map(Number),
    startsAt: schedule.schedule_time ? new Date(schedule.schedule_time) : null,
    timezone: setting.data.timezone,
    message: schedule.message
  }
}

const buildScheduleEmailForPut = (
  form: RunbookDashboardShareEmailFormType,
  setting: UserSetting,
  filterData?: FilterResponse
): UpdateUserSettingParams => {
  const frequencyNameMap: {
    [key: string]: 'Every 15 minutes' | 'Every 30 minutes' | 'Hourly' | 'Once' | 'Daily' | 'Weekly'
  } = {
    every_15_min: 'Every 15 minutes',
    every_30_min: 'Every 30 minutes',
    hourly: 'Hourly',
    once: 'Once',
    daily: 'Daily',
    weekly: 'Weekly'
  }

  let selectedFilter

  if (form.dashboardView === 'savedFilters') {
    selectedFilter = filterData?.filters.filter(filter => form.filter && filter.id === parseInt(form.filter, 10))
  }

  return {
    id: setting.id,
    resource_id: setting.resource_id,
    resource_type: 'Dashboard',
    type: 'EmailScheduleUserSetting',
    user_id: setting.user_id,
    data: {
      runbook_id: setting.data.runbook_id,
      account_id: setting.data.account_id,
      enabled: true,
      timezone: form.timezone === 'default' ? setting.data.timezone : form.timezone,
      schedules: [
        {
          name: form.name,
          ...(form.frequencyType === 'weekly' && {
            day_options: form.frequencyDays?.map(day => Number(day))
          }),
          message: form.message || '',
          schedule_time: form.startsAt ? form.startsAt.toISOString() : undefined,
          user_recipients: form.recipients?.users || [],
          team_recipients: form.recipients?.teams || [],
          ...(form.frequencyType && {
            frequency: {
              option: form.frequencyType,
              name: frequencyNameMap[form.frequencyType]
            }
          }),
          ...(selectedFilter && {
            filter_name: selectedFilter[0].name,
            filter_id: selectedFilter[0].id,
            filter_query_string: selectedFilter[0].query_string
          })
        }
      ]
    }
  }
}

import { memo } from 'react'

import { Text } from '../../typography'
import { Box } from '../../layout'
import { ListItem, ListItemIcon } from '../../list-item'

// Add the more common/standard select item customizations here e.g., levels, groupings
// WARNING: DO NOT use this to add props here to create select items that deviate from the standard select
// list item UI. The select component allows for a separate custom render function for those cases and
// they should also be considered carefully.

type SelectListItemProps = {
  label: string
  selected?: boolean
  highlighted?: boolean
  level?: number
  disabled?: boolean
  suffix?: React.ReactNode
  prefix?: React.ReactNode
}

export const SelectListItem = memo(
  ({ label, selected, highlighted, level, disabled, suffix, prefix }: SelectListItemProps) => {
    const endComponents = selected
      ? [...(suffix ? [suffix] : []), <ListItemIcon icon="check" size="small" color="primary" />]
      : suffix
      ? [suffix]
      : undefined

    return (
      <ListItem
        size="small"
        startComponent={prefix}
        endComponents={endComponents}
        background={selected ? 'primary-bg-2' : highlighted ? 'bg-1' : 'bg'}
        level={level}
        css="width: 100%"
        prominence={disabled ? 'low' : selected ? 'high' : 'default'}
      >
        <Box>
          <Text truncate="tip" tip={label} tipPlacement="top" color={selected ? 'primary' : 'text-light'}>
            {label}
          </Text>
        </Box>
      </ListItem>
    )
  }
)

import { useCallback, useEffect, useId, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { createEditor, Descendant } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react'
import styled from 'styled-components/macro'

import { TextEditorToolbar } from './components/text-editor-toolbar'
import { Element, Leaf } from './elements'
import { withLinks, withTables } from './plugins'
import { CustomElement, CustomText, Format } from './text-editor-types'
import { toggleMark } from './utils'
import { deserializeHtml, serialize } from './utils/serializer'
import { FormField, FormFieldComponentProps, FormFieldLabelBox, StaticFormField } from '../../form/internal/form-field'
import { Box } from '../../layout/box'
import { themeColor } from '../../theme'
import { useInitialMount } from '../../utilities'

const pipe =
  (...fns: any) =>
  (x: any) =>
    fns.reduce((v: any, f: any) => f(v), x)

const withPlugins = pipe(withTables, withLinks, withReact, withHistory)

const HOTKEYS: { [x: string]: Format } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline'
}

export type TextEditorProps = {
  value: string
  defaultValue?: string
  inlineError?: string
  placeholder?: string
  hasError?: boolean
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  name?: string // TODO: how can we not support this? -- could pass this to the the textarea input element that holds the html input value even if it isn't visible, always.
  'data-testid'?: string // TODO: should we have this somewhere?
  a11yTitle?: string // TODO: confirm this is doing something here
  autoFocus?: boolean
  label?: string
  'aria-label'?: string
  onChange: (value: string) => void
  // when true, resets the editor contents to the currently stored value
  resetToValue?: boolean
  // don't wrap in a form field
  plain?: boolean
}

export const TextEditor = ({
  value,
  defaultValue,
  placeholder = 'Enter content here...',
  // name: fieldName,
  // "data-testid": dataTestId,
  onChange,
  resetToValue = false,
  autoFocus,
  readOnly = false,
  disabled,
  plain,
  label,
  a11yTitle,
  ...restFormFieldProps
}: TextEditorProps) => {
  const labelId = useId()
  const isInitialMount = useInitialMount()
  const editor = useMemo(() => withPlugins(createEditor()), [])
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [])
  const [htmlValue, setHtmlValue] = useState<string>(defaultValue ?? value ?? '<p></p>')
  const [codeEditorOpen, setCodeEditorOpen] = useState(false)
  const [localValue, setLocalValue] = useState<any>(() => {
    const initialHtmlVal = defaultValue ?? value
    if (!initialHtmlVal) {
      return [{ text: '' }]
    } else {
      return deserializeHtml(initialHtmlVal)
    }
  })

  function resetSelection() {
    const point = { path: [0, 0], offset: 0 }
    editor.selection = { anchor: point, focus: point }
  }

  function resetSelectionAndSetValues(htmlValue: string) {
    resetSelection()
    const deserializedValue = deserializeHtml(htmlValue)
    setHtmlValue(htmlValue)
    setLocalValue(deserializedValue)
    editor.children = deserializedValue as CustomElement[]
  }

  const toggleCodeEditor = () => {
    if (codeEditorOpen) {
      resetSelection()
      const value = deserializeHtml(htmlValue)
      editor.children = value as CustomElement[]
    }
    setCodeEditorOpen(!codeEditorOpen)
  }

  const handleHtmlValueChange = (htmlVal: string) => {
    setHtmlValue(htmlVal)
    setLocalValue(deserializeHtml(htmlVal))
    onChange(htmlVal ?? '')
  }

  const handleInnerValueChange = (value: Descendant[]) => {
    const htmlValue = serialize({ children: value } as CustomElement | CustomText)
    setHtmlValue(htmlValue)
    onChange(htmlValue ?? '')
    setLocalValue(value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      editor.insertBreak()
      return
    }

    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event as any)) {
        event.preventDefault()
        const mark = HOTKEYS[hotkey]
        toggleMark(editor, mark)
      }
    }
  }

  useEffect(() => {
    if (isInitialMount) return
    if (value === defaultValue) {
      resetSelectionAndSetValues(defaultValue)
    }
  }, [value])

  useEffect(() => {
    if (resetToValue) {
      resetSelectionAndSetValues(value)
    }
  }, [resetToValue])

  useEffect(() => {
    resetSelectionAndSetValues(defaultValue ?? value)
  }, [])

  const slateEditor = (
    <Slate editor={editor} value={localValue} onChange={handleInnerValueChange}>
      <SlateEditorContainer>
        {!readOnly && <TextEditorToolbar toggleCodeEditor={toggleCodeEditor} codeEditorOpen={codeEditorOpen} />}
        <Editor
          htmlValue={htmlValue}
          codeEditorOpen={codeEditorOpen}
          disabled={disabled}
          handleHtmlValueChange={handleHtmlValueChange}
          readOnly={readOnly}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          autoFocus={autoFocus}
          onKeyDown={handleKeyDown}
          aria-labelledby={labelId}
        />
      </SlateEditorContainer>
    </Slate>
  )

  return (
    <>
      {plain ? (
        slateEditor
      ) : (
        <>
          {/* give the static form field a tabIndex of -1 since we have to give it the onClick here for some reason
        related to overriding a slate focus state issue */}
          <SlateStaticFormField onClick={e => e.preventDefault()} tabIndex={-1}>
            <FormField
              readOnly={readOnly}
              disabled={disabled}
              {...restFormFieldProps}
              label={label}
              labelProps={{
                // otherwise the toolbar items are included
                'aria-label': a11yTitle ?? label,
                id: labelId
              }}
            >
              <Box width="100%">{slateEditor}</Box>
            </FormField>
          </SlateStaticFormField>
        </>
      )}
    </>
  )
}

const SlateStaticFormField = styled(StaticFormField)<FormFieldComponentProps>`
  ${FormFieldLabelBox} {
    &:focus-visible {
      box-shadow: none !important;
    }
  }
`

type PropsOf<TComponent> = TComponent extends React.ComponentType<infer P> ? P : never
type EditableProps = PropsOf<typeof Editable> & {
  htmlValue?: string | number | readonly string[]
  codeEditorOpen: boolean
  handleHtmlValueChange: (value: string) => void
  'aria-labelledBy'?: string
}

const Editor = ({
  htmlValue,
  codeEditorOpen,
  disabled,
  handleHtmlValueChange,
  readOnly,
  renderElement,
  renderLeaf,
  placeholder,
  autoFocus,
  onKeyDown,
  ...props
}: EditableProps) => {
  return (
    <>
      {codeEditorOpen && (
        <CodeArea value={htmlValue} onChange={e => handleHtmlValueChange(e.target.value)} readOnly={readOnly} />
      )}
      <Editable
        {...props}
        // NOTE: this is not conditionally rendered, but instead hidden when the code editor is shown. Per the docs,
        // The Slate component must include somewhere in its children the Editable component.
        // https://docs.slatejs.org/libraries/slate-react/slate
        style={codeEditorOpen ? { display: 'none' } : {}}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        tabIndex={!readOnly && !disabled ? 0 : -1}
        readOnly={readOnly || disabled}
        placeholder={((htmlValue as string | undefined) ?? '').trim().length === 0 ? placeholder : ''}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        data-autofocus={autoFocus}
      />
    </>
  )
}

const SlateEditorContainer = styled(Box)`
  [contenteditable='true'] {
    cursor: text;
  }

  font-size: 15px;
`

// TODO: replace with multiline textarea field?
const CodeArea = styled.textarea`
  width: 100%;
  height: 125px;
  border: none;
  resize: none;
  overflow-y: auto;
  padding: 7px;
  box-sizing: border-box;
  font-size: 15px;
  color: ${themeColor('text')};
  background-color: ${themeColor('bg')};
`

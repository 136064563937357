import { useCallback, useEffect, useMemo } from 'react'
import { format } from 'date-fns'

import { ColumnConfig, Table, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useSystemParametersQuery } from 'main/services/queries/use-system-parameters-query'
import { SystemParameter } from 'main/services/queries/types/system-parameters'
import { useToggleRightPanel } from 'main/components/layout/layout-hooks'
import { useSystemParameterEditPanel } from 'main/context/panel-context'

export const SystemParametersListPage = () => {
  const { t } = useLanguage('systemParameters')
  const toggleRightPanel = useToggleRightPanel()
  const { openPanel } = useSystemParameterEditPanel()
  const { data } = useSystemParametersQuery()
  const items = data?.system_parameters || []

  const systemParameterColumns: ColumnConfig<SystemParameter>[] = useMemo(() => {
    const columns = [
      {
        header: t('table.columnHeaders.name'),
        property: 'name',
        sortable: true
      },
      {
        header: t('table.columnHeaders.value'),
        property: 'value',
        sortable: true
      },
      {
        header: t('table.columnHeaders.lastUpdated'),
        property: 'updated_at',
        sortable: true,
        render: (datum: SystemParameter) => {
          return <Text>{format(new Date(datum.updated_at), 'E, do MMM yyyy HH:mm')}</Text>
        }
      },
      {
        header: t('table.columnHeaders.createdAt'),
        property: 'created_at',
        sortable: true,
        render: (datum: SystemParameter) => {
          return <Text>{format(new Date(datum.updated_at), 'E, do MMM yyyy HH:mm')}</Text>
        }
      }
    ]
    return columns
  }, [])

  const handleClickRow = useCallback(
    ({ datum }: { datum: SystemParameter }) => {
      openPanel({ systemParameterId: datum?.id as number })
    },
    [openPanel]
  )

  useEffect(() => {
    toggleRightPanel(false)
  }, [])

  return <Table columns={systemParameterColumns} data={items} onClickRow={handleClickRow} />
}

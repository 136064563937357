import { unescape } from 'lodash'
import { useQuery, UseQueryOptions } from 'react-query'

import { QueryKeys } from './query-keys'
import { RunbookEditRunbook } from './types'
import { useSetPermissions } from './use-permissions'
import { apiClient, ApiError } from 'main/services/api/api-client'

export type RunbookEditResponse = {
  runbook: RunbookEditRunbook
  // TODO: Needs additional work!!! The permissions here are important -- we need to use them to determine how to render the form UI (readonly or editable)
  // Could create a hook reach into the meta for a runbook edit response and return what is needed - See how done in sidebar nav's `useSavedViewPermissions`
  meta: {
    permissions: {
      add_event: number[]
      archive: number[]
      destroy: number[]
      duplicate: number[]
      leave_event: number[]
      read: number[]
      update: number[]
    }
  }
}

export const useRunbookEdit = (
  runbookId?: number | null,
  accountSlug?: string,
  options: UseQueryOptions<RunbookEditResponse, ApiError> = {}
) => {
  // TODO: need key convention
  const setPermissions = useSetPermissions('runbook-edit')

  return useQuery<RunbookEditResponse, ApiError>(
    // Account slug is required so that runbook data doesn't persist when navigating to another workspace
    [QueryKeys.Accounts, accountSlug, QueryKeys.Runbooks, String(runbookId), 'edit'],
    async () => {
      const { data } = await apiClient.get<RunbookEditResponse>({
        url: `runbooks/${runbookId}/edit`
      })
      if (data.runbook) {
        data.runbook = {
          ...data.runbook,
          name: unescape(data.runbook.name),
          description: data.runbook.description ? unescape(data.runbook.description) : null
        }
      }
      return data
    },
    {
      ...options,
      onSuccess: data => {
        options.onSuccess?.(data)
        setPermissions(data.meta.permissions)
      },
      enabled: !!runbookId && options.enabled,
      cacheTime: 0
    }
  )
}

export function useRunbookGenerateDescription(runbookId: number | null, options: any) {
  return useQuery<any, ApiError>(
    QueryKeys.RunbookGenerateDescription,
    async () => {
      const { data } = await apiClient.get<any>({
        url: `runbooks/${runbookId}/generate_description`
      })

      return data
    },
    options
  )
}

import { MouseEvent } from 'react'
import styled from 'styled-components'

import { Box, Button, Icon, IconButton, IconName, Pill, Text, themeColor } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { RunbookTemplateRunbook as Template } from 'main/services/queries/types'

const templateStatusMap = {
  template_draft: {
    color: 'info',
    text: 'draft'
  },
  template_pending: {
    color: 'warning',
    text: 'pending'
  },
  template_approved: {
    color: 'success',
    text: 'approved'
  },
  template_rejected: {
    color: 'error',
    text: 'rejected'
  }
}

export const TEMPLATE_LIST_HEIGHT = 200

export const LinkTemplateSelect = ({
  iconPrefix,
  isLoading,
  onClick,
  onMouseEnter,
  onMouseLeave,
  templates,
  selected,
  height = TEMPLATE_LIST_HEIGHT,
  isEmpty,
  noMatches,
  onFilterClear,
  ...props
}: {
  isLoading?: boolean
  iconPrefix?: (template: Template) => IconName | undefined
  onClick: (e: MouseEvent<HTMLLIElement, globalThis.MouseEvent>, template: Template) => void
  onMouseEnter?: (e: MouseEvent<HTMLLIElement, globalThis.MouseEvent>, template: Template) => void
  onMouseLeave?: (e: MouseEvent<HTMLLIElement, globalThis.MouseEvent>, template: Template) => void
  templates?: Template[]
  selected?: number | number[] | null
  height?: number
  isEmpty?: boolean
  id?: string
  'data-testid'?: string
  noMatches?: boolean
  onFilterClear?: () => void
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'linkTemplateModal' })
  return (
    <TemplateList height={height} {...props} aria-label="List of templates">
      {isLoading && (
        <Box justify="center" align="center" margin={{ top: 'xlarge' }}>
          <Icon data-testid="link-template-modal-loading" icon="spinner" />
        </Box>
      )}
      {!templates || templates?.length === 0 ? (
        <Box gap="small" align="center" justify="center" direction="column" margin="small">
          <Text color="text-light" textAlign="center">
            {isEmpty && !isLoading && t('noTemplates')}
          </Text>
          {noMatches && !isLoading && (
            <>
              <Text color="text-light" textAlign="center">
                {t('noMatches')}
              </Text>
              <Text color="text-light" textAlign="center">
                {t('noMatchesHelperText')}{' '}
                <ClearButton plain={true} onClick={onFilterClear} label={t('noMatchesFilterClearButton')}></ClearButton>
              </Text>
            </>
          )}
        </Box>
      ) : (
        templates?.map(template => (
          <TemplateItem
            data-testid={`template-${template.id}`}
            key={template.id}
            selected={Array.isArray(selected) ? selected.includes(template.id) : template.id === selected}
            onClick={
              template.meta?.linked_status?.is_parent || template.tasks_count === 0
                ? undefined
                : e => onClick(e, template)
            }
            onMouseEnter={e => onMouseEnter?.(e, template)}
            onMouseLeave={e => onMouseLeave?.(e, template)}
            isParent={template.meta?.linked_status?.is_parent || false}
            hasTasks={template.tasks_count > 0}
            aria-disabled={template.meta?.linked_status?.is_parent || template.tasks_count === 0 ? 'true' : undefined}
          >
            <Box direction="row" gap="xsmall">
              {typeof iconPrefix === 'function' ? (
                <Box height={{ min: '20px', max: '20px' }} width={{ min: '20px', max: '20px' }}>
                  {(() => {
                    const icon = iconPrefix(template)
                    return icon ? (
                      <Icon data-testid={`template-icon-${template.id}-${icon}`} color="primary" icon={icon} />
                    ) : (
                      <></>
                    )
                  })()}
                </Box>
              ) : (
                <></>
              )}
              <Box direction="row" gap="xsmall">
                <Text color="text-light">#{template.id}</Text>
                <Box width={{ max: '270px' }}>
                  <Text color="text-light" truncate={true}>
                    {template.name}
                  </Text>
                </Box>
              </Box>
            </Box>

            {template.meta?.linked_status?.is_parent || template.tasks_count === 0 ? (
              <IconButton
                icon="info"
                label={template.tasks_count === 0 ? t('noTasks') : t('containsLinkedTask')}
                size="medium"
              />
            ) : (
              <Pill
                size="medium"
                color={templateStatusMap[template.template_status]?.color}
                label={t(templateStatusMap[template.template_status]?.text)}
              />
            )}
          </TemplateItem>
        ))
      )}
    </TemplateList>
  )
}

const TemplateItem = styled.li.attrs({ role: 'option' })<{
  selected: boolean
  multiSelect?: boolean
  isParent: boolean
  hasTasks: boolean
}>`
  align-items: center;
  background-color: ${({ isParent, hasTasks }) =>
    isParent || !hasTasks ? themeColor('bg-3') : ({ selected }) => selected && themeColor('bg-2')};
  cursor: ${({ isParent, hasTasks }) => (isParent || !hasTasks ? 'not-allowed' : 'pointer')};
  display: flex;
  gap: 12px;
  padding: 10px 12px;
  transition: background-color 150ms;
  justify-content: space-between;

  &:hover {
    background-color: ${({ selected, isParent, hasTasks }) => !selected && !isParent && hasTasks && themeColor('bg-1')};
  }
`

const TemplateList = styled.ul.attrs({ role: 'listbox' })<{ height: number }>`
  border-radius: 8px;
  border: 1px solid ${themeColor('bg-2')};
  height: ${({ height }) => height}px;
  margin: 0;
  overflow-y: auto;
  padding: 0;
`
const ClearButton = styled(Button)`
  color: ${themeColor('primary')};
`

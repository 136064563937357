import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'
import { RunbookBaseRunbook } from 'main/services/queries/types'

// are all of these approval statuses?
export const APPROVAL_STATUS_FOR_TEMPLATE_EDIT_LOCK = ['open', 'approved', 'rejected', 'expired', 'expiring']

export const useType = (
  runbook: RunbookBaseRunbook,
  { excludeIncidentRunbook }: { excludeIncidentRunbook?: boolean } = {}
) => {
  const { runbookTypes } = useAccountRunbookTypes()
  // Ref CFE-560: incident runbook type should not be available
  const availableRunbookTypes = excludeIncidentRunbook
    ? runbookTypes?.filter(rt => rt.key !== 'incident-runbook')
    : runbookTypes

  const runbookType = availableRunbookTypes?.find(rt => rt.id === runbook.runbook_type_id)

  if (!runbookType) {
    throw new Error('Runbook type not found in account')
  }

  return runbookType
}

export const useChangeRequestTypes = (runbook: RunbookBaseRunbook) => {
  const type = useType(runbook)
  return type.change_request_types
}

// shouldn't want to use this without a runbook, but architecture of edit-runbook doesn't require a runbook.
// that file should be updated so this can be called with a runbook only
export const useIsTemplateLocked = (runbook?: RunbookBaseRunbook) => {
  // $scope.vm.runbookEdit.is_template &&
  //   approvalStatusForTemplateEditLock.includes($scope.vm.runbookEdit.current_version.approval_status)

  // TODO: type this so template runbooks know they have a current version with an approval status

  if (runbook?.current_version?.approval_status) {
    return (
      runbook.is_template && APPROVAL_STATUS_FOR_TEMPLATE_EDIT_LOCK.includes(runbook.current_version.approval_status)
    )
  } else {
    return false
  }
}

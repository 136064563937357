import { Box, ListItem, RunbookTypeIcon, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks/use-language'

type SnippetInfoProps = {
  id: number
  tasksCount: number
}

export const SnippetInfo = ({ id, tasksCount }: SnippetInfoProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'addSnippet' })

  return (
    <Text color="text-light" size="13px">
      {`#${id}`} &bull; {t('tasksCount', { count: tasksCount })}
    </Text>
  )
}

type SnippetItemProps = {
  id: number
  name: string
  selected?: boolean
  hasNext?: boolean
  tasksCount: number
  onClick: () => void
}

export const SnippetItem = ({ id, name, selected, tasksCount, onClick, hasNext }: SnippetItemProps) => {
  return (
    <ListItem
      key={id}
      role="option"
      size="large"
      startComponent={<RunbookTypeIcon icon="snippet" color="feature-blue-bright" template />}
      onClick={onClick}
      a11yTitle={name}
      selected={selected}
      hasNext={hasNext}
    >
      <Box direction="column">
        <Text truncate="tip" tipPlacement="top">
          {name}
        </Text>
        <SnippetInfo id={id} tasksCount={tasksCount} />
      </Box>
    </ListItem>
  )
}

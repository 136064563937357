import { Pill } from '@cutover/react-ui'
import { useComponentProps } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type PillKind = 'default' | 'info' | 'success' | 'error' | 'warning' | 'barbie'

export type PillNodeProps = AppComponentNodeProps & {
  value: string
  kind?: PillKind
}

/**
 * This is an example wrapper for a react-ui primitive and will likely be removed as we don't
 * necessarily want to allow users this level of granularity. Also, apps shouldn't
 * define custom colors but should likely retrive those from the Cutover theme.
 */
const pillKindColor: { [K in PillKind]: string } = {
  default: '#2A55C3',
  info: '#2A55C3',
  success: '#157F25',
  error: '#D62300',
  warning: '#B34D00',
  barbie: '#F057ED'
}

export const PillNode = ({ appId, resourceId, id, ...props }: PillNodeProps) => {
  const { value: label, kind } = useComponentProps(appId, resourceId, id, props) as PillNodeProps

  const color = kind ? pillKindColor[kind] : pillKindColor.default

  return <Pill label={label} color={color} />
}

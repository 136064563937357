import { useEffect } from 'react'

import { Box, Modal, SanitizedString } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks/use-language'

export type DeleteUserModalProps = {
  isOpen: boolean
  closeModal: () => void
  userName?: string
  onDelete: () => void
  isDeleting: boolean
}

export function DeleteUserModal({ isOpen, userName, closeModal, onDelete, isDeleting }: DeleteUserModalProps) {
  const { t } = useLanguage()

  useEffect(() => {
    if (!isDeleting) {
      closeModal()
    }
  }, [isDeleting])

  return (
    <Modal
      title={t('common:deleteUserButton')}
      open={isOpen}
      onClose={closeModal}
      confirmText={t('common:deleteButton')}
      confirmIcon="delete"
      onClickConfirm={onDelete}
      loading={isDeleting}
      loadingText={t('common:deletingButton')}
    >
      <Box>
        <SanitizedString
          input={t('runbook:peoplePanel:users:deleteUserConfirmation', {
            userName: userName
          })}
        />
      </Box>
    </Modal>
  )
}

import { useEffect } from 'react'

import { Box, Message, Modal, Text } from '@cutover/react-ui'
import { useRunbookTeamUnlink } from '../use-runbook-team-unlink'
import { useLanguage } from 'main/services/hooks/use-language'

export type TeamUnlinkModalProps = {
  isOpen: boolean
  runbookId: number
  runbookVersionId: number
  teamId: number
  onClose: () => void
  onCancel: () => void
  onSave: () => void
}

export function TeamUnlinkModal({
  isOpen,
  runbookId,
  runbookVersionId,
  teamId,
  onClose,
  onSave
}: TeamUnlinkModalProps) {
  const { t } = useLanguage()
  const mutation = useRunbookTeamUnlink()

  const handleSave = () => {
    mutation.mutate({
      runbookId,
      runbookVersionId,
      teamId
    })
  }

  useEffect(() => {
    if (mutation.isSuccess) {
      onSave()
    }
  }, [mutation.isSuccess])

  return (
    <Modal
      title={t('runbook:peoplePanel:teams:unlinkTitle')}
      open={isOpen}
      onClose={onClose}
      confirmText={t('runbook:peoplePanel:teams:unlinkContinue')}
      confirmIcon="arrow-forward"
      onClickConfirm={handleSave}
      loadingText={t('runbook:peoplePanel:teams:unlinkUnlinking')}
    >
      <Box direction="column" gap="large">
        {mutation.isError ? (
          <Box margin={{ top: '24px' }}>
            <Message type="error" message={[t('runbook:peoplePanel:teams:unlinkError')]} />
          </Box>
        ) : null}
        <Text>{t('runbook:peoplePanel:teams:unlinkDescription')}</Text>
      </Box>
    </Modal>
  )
}

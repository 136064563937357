import { Controller, FieldValues, useFormContext } from 'react-hook-form'

import { Box, FormFieldDisplay, MultiSelect, SelectProps } from '@cutover/react-ui'
import { FieldProps, getInputProps } from './form-fields'
import { TaskListTask } from 'main/services/queries/types'
import { TaskLineItem } from '../task-line-item'

type TaskSelectProps = Omit<
  SelectProps<TaskListTask, number>,
  'renderOption' | 'labelKey' | 'valueKey' | 'filterKeys' | 'options'
>

export type TaskFieldProps<TFieldValues extends FieldValues> = FieldProps<TaskSelectProps, TFieldValues> & {
  tasks: TaskListTask[]
  label: string
}

export const TaskSelectField = <TFieldValues extends FieldValues>({
  tasks,
  ...props
}: TaskFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })
  const readOnly = inputProps.readOnly

  if (tasks.length === 0 && readOnly) return null

  return (
    <>
      {readOnly ? (
        <FormFieldDisplay
          label={props.label}
          labelItems={
            <Box
              width="100%"
              tag="ul"
              a11yTitle={`${props.a11yTitle ?? props.label} selected options`}
              css={`
                margin: 0;
                padding: 0;
                text-indent: 0;
                list-style: none;
              `}
            >
              {tasks
                .sort((a, b) => a.internal_id - b.internal_id)
                .map(task => (
                  <TaskLineItem key={task.id} taskId={task.id} hasClickEvent role="option" />
                ))}
            </Box>
          }
        />
      ) : (
        <Controller
          name={props.name}
          control={formContext.control}
          render={({ field: { onChange, value, onBlur, ref } }) => {
            const tasksValue = tasks?.filter(task => value?.includes(task.id)) ?? []
            return (
              // @ts-ignore
              <MultiSelect<TaskListTask>
                {...props}
                {...inputProps}
                hideSelectedCount
                valueKey="id"
                filterKeys={['name', 'internal_id', 'label']}
                renderOption={(opt, { onDeselect, highlighted, selected }) => {
                  return (
                    <TaskLineItem
                      key={opt.id}
                      taskId={opt.id}
                      active={highlighted}
                      hasClickEvent={!!selected}
                      // @ts-ignore
                      onClickRemove={!readOnly && onDeselect ? () => onDeselect(opt.id) : undefined}
                      role="option"
                      disabled={props.disabled}
                    />
                  )
                }}
                icon="add"
                inputRef={ref}
                onBlur={onBlur}
                onChange={val => onChange(val?.map(v => v.id))}
                // required for searching by "#internal_id"
                options={tasks.map(task => ({ ...task, label: `#${task.internal_id}` }))}
                value={tasksValue}
              />
            )
          }}
        />
      )}
    </>
  )
}

import { Box, ListItem, TaskItemIcon, TaskItemIconProps, Text } from '@cutover/react-ui'
import { stageIconName, taskTypeIcon } from 'main/components/runbook/pages/task-list/task-item/task-list-item-props'
import { useEditTaskPanel } from 'main/context/panel-context'
import { useStream, useTaskListTask, useTaskListTaskTaskType } from 'main/recoil/runbook'

export const TaskLineItem = ({
  taskId,
  onClickRemove,
  active,
  hasClickEvent,
  role,
  disabled
}: {
  taskId: number
  onClickRemove?: () => void
  active?: boolean
  hasClickEvent?: boolean
  role?: string
  disabled?: boolean
}) => {
  const task = useTaskListTask(taskId)
  const { name, internal_id, id } = task
  const { openPanel } = useEditTaskPanel()
  const taskType = useTaskListTaskTaskType(id)
  const stream = useStream(task.stream_id)

  const iconProps = {
    color: stream.color,
    icon: taskTypeIcon(taskType.icon, task.stage),
    inProgress: task.stage === 'in-progress',
    isOpaque: task.stage === 'complete',
    stageIcon: stageIconName({
      completionType: task.completion_type,
      stage: task.stage,
      startFixed: task.start_fixed
    })
  }

  return taskType && stream ? (
    <ListItem
      tabIndex={undefined}
      size="small"
      startComponent={<TaskItemIcon iconSize="xsmall" {...(iconProps as unknown as TaskItemIconProps)} tabIndex={-1} />}
      onClickRemove={onClickRemove}
      active={active}
      onClick={hasClickEvent ? () => openPanel({ taskId: id }) : undefined}
      a11yTitle={name}
      role={role ?? 'listitem'}
      disabled={disabled}
    >
      <Box>
        <Text truncate="tip" tipPlacement="top">
          <Text color="text-light">#{internal_id}</Text> {name}
        </Text>
      </Box>
    </ListItem>
  ) : null
}

import * as yup from 'yup'

import { LanguageTranslate } from 'main/services/hooks/use-language'

export function createRunbookTypeSchema({ translate }: { translate: LanguageTranslate }) {
  return yup.object().shape({
    name: yup.string().required(translate('runbookTypes:fields:name:errors:required')),
    description: yup.string().required(translate('runbookTypes:fields:description:errors:required')),
    visibility: yup
      .string()
      .nullable()
      .test({
        name: 'is-valid-visibility',
        test: function (value) {
          if (!value) {
            return this.createError({
              message: 'The visibility is required.',
              path: 'visibility'
            })
          }

          if (!['global', 'account'].includes(value)) {
            return this.createError({
              message: 'The visiblity must be global or account.',
              path: 'visibility'
            })
          }

          return true
        }
      }),
    accountId: yup
      .string()
      .nullable()
      .test({
        name: 'is-valid-account',
        test: function (value, context) {
          const visibility = context.resolve(yup.ref('visibility'))

          if (visibility === 'account') {
            if (!value) {
              return this.createError({
                message: 'Workspace must be specified.',
                path: 'accountId'
              })
            }
          } else {
            if (value) {
              return this.createError({
                message: 'Workspace must not be specified when visibility is global.',
                path: 'accountId'
              })
            }
          }

          return true
        }
      }),
    iconName: yup
      .string()
      .nullable()
      .test({
        name: 'is-valid-icon-id',
        test: function (value) {
          if (!value) {
            return this.createError({
              message: 'The icon is required.',
              path: 'iconName'
            })
          }

          return true
        }
      }),
    iconColor: yup
      .string()
      .nullable()
      .test({
        name: 'is-valid-icon-color',
        test: function (value) {
          if (!value) {
            return this.createError({
              message: 'The icon color is required.',
              path: 'iconColor'
            })
          }

          return true
        }
      }),
    settings: yup.mixed().test({
      name: 'is-valid-settings',
      test: function (value) {
        if (!value) {
          return this.createError({
            message: 'The settings are required.',
            path: 'settings'
          })

          return true
        }

        if (!Array.isArray(value)) {
          return this.createError({
            message: 'The settings must be an array.',
            path: 'settings'
          })
        }

        return true
      }
    }),
    approvalFlowId: yup.string().nullable()
  })
}

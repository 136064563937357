import { MouseEventHandler, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Box, media, themeBreakpoint, themeColor } from '@cutover/react-ui'
import {
  FILTER_PANEL_WIDTH,
  IDS,
  LEFT_SIDEBAR_DEFAULT_WIDTH,
  LEFT_SIDEBAR_MIN_WIDTH,
  PAGE_NAV_DEFAULT_WIDTH,
  RIGHT_PANEL_DEFAULT_WIDTH,
  RIGHT_PANEL_MAX_WIDTH
} from './layout-constants'
import { useInitialMount } from 'main/services/hooks/use-initial-mount'

const DRAGGER_WIDTH = 10

export const ResizableRightPanel = ({
  children,
  hasRightNav: hasPageNav
}: {
  children: ReactNode
  hasRightNav?: boolean
}) => {
  const isFirstMount = useInitialMount()
  const panelRef = useRef<HTMLDivElement>(null)
  const draggerRef = useRef<HTMLDivElement>(null)
  const navPanelWidth = hasPageNav ? PAGE_NAV_DEFAULT_WIDTH : 0
  const maxAvailableRef = useRef(0)

  const [initialWindowWidth] = useState(window.innerWidth)

  const [storedWidth] = useState(() => {
    const stored = localStorage.getItem('sidebar-panel-width')

    if (stored) {
      return parseInt(stored, 10)
    } else {
      return RIGHT_PANEL_DEFAULT_WIDTH
    }
  })

  const findMaxAvailableWidth = () => {
    const navWidth = document.getElementById(IDS.LEFT_NAV)?.clientWidth ?? PAGE_NAV_DEFAULT_WIDTH
    const filterPanelWidth = document.getElementById(IDS.FILTER_PANEL)?.clientWidth ?? 0

    const maxAvailable = Math.min(
      window.innerWidth - navWidth - navPanelWidth - filterPanelWidth,
      RIGHT_PANEL_MAX_WIDTH
    )
    maxAvailableRef.current = maxAvailable
    return maxAvailable
  }

  const [maxWidth] = useState(findMaxAvailableWidth)

  const [panelWidth, setPanelWidth] = useState(Math.min(storedWidth, maxWidth))

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(e => {
    e.preventDefault()
    findMaxAvailableWidth()

    if (draggerRef.current) {
      draggerRef.current.style.left = ''

      if (panelRef.current) {
        draggerRef.current.style.right = panelRef.current.clientWidth - DRAGGER_WIDTH + 'px'
      }

      draggerRef.current.classList.add('dragging')
    }

    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('mousemove', handleMouseMove)
  }, [])

  const handleMouseMove = useCallback((e: MouseEvent) => {
    const xPos = ((e.currentTarget as Window)?.innerWidth ?? initialWindowWidth) - e.clientX

    if (draggerRef.current) {
      draggerRef.current.style.right =
        constrainedWidth(xPos - navPanelWidth, maxAvailableRef.current) - DRAGGER_WIDTH + 'px'
    }
  }, [])

  const handleMouseUp = useCallback((e: MouseEvent) => {
    const xPos = ((e.currentTarget as Window)?.innerWidth ?? initialWindowWidth) - e.clientX

    if (draggerRef.current) draggerRef.current.classList.remove('dragging')

    setPanelWidth(constrainedWidth(xPos - navPanelWidth, maxAvailableRef.current))

    if (draggerRef.current) draggerRef.current.style.left = 0 + 'px'
  }, [])

  useEffect(() => {
    if (isFirstMount) return

    localStorage.setItem('sidebar-panel-width', panelWidth.toString())

    window.removeEventListener('mouseup', handleMouseUp)
    window.removeEventListener('mousemove', handleMouseMove)
  }, [panelWidth])

  useEffect(() => {
    if (draggerRef.current) draggerRef.current.style.left = 0 + 'px'
  }, [])

  return (
    <Panel
      ref={panelRef}
      id="layout-right-panel"
      data-testid="layout-right-panel"
      background="bg"
      hasPageNav={hasPageNav}
      css={`
        flex: 0 0 ${panelWidth}px;
        width: ${panelWidth}px;

        #page-root:not(.right-panel-open) & {
          width: 0;
          flex: 0 0 0;
        }
      `}
    >
      {children}
      <Dragger ref={draggerRef} onMouseDown={handleMouseDown}>
        <Handle />
      </Dragger>
    </Panel>
  )
}

function constrainedWidth(width: number, maxAvailable?: number) {
  if (width < RIGHT_PANEL_DEFAULT_WIDTH) {
    width = RIGHT_PANEL_DEFAULT_WIDTH
  } else if (width > RIGHT_PANEL_MAX_WIDTH) {
    width = RIGHT_PANEL_MAX_WIDTH
  }
  return maxAvailable !== undefined ? Math.min(width, maxAvailable) : width
}

const MIN_ALL_PANELS_DEFAULT = LEFT_SIDEBAR_DEFAULT_WIDTH + FILTER_PANEL_WIDTH + RIGHT_PANEL_DEFAULT_WIDTH
const MIN_ALL_PANELS_NAV_MIN = LEFT_SIDEBAR_MIN_WIDTH + FILTER_PANEL_WIDTH + RIGHT_PANEL_DEFAULT_WIDTH

const Panel = styled(Box)<{ hasPageNav?: boolean }>`
  position: relative;
  height: 100%;
  min-height: 100%;

  .right-panel-open & {
    min-width: 320px !important;
    border-left: 1px solid ${themeColor('bg-3')};

    ${media.sm(css`
      width: 100vw !important;
      min-width: 100vw !important;
    `)}
  }

  ${media.gtSm(css`
    .right-panel-open.left-nav-panel-open & {
      max-width: calc(100vw - ${LEFT_SIDEBAR_DEFAULT_WIDTH}px) !important;
    }

    .right-panel-open.left-nav-panel-open.filter-panel-open & {
      ${(props: any) => css`
        max-width: calc(
          100vw - ${LEFT_SIDEBAR_DEFAULT_WIDTH}px - ${FILTER_PANEL_WIDTH}px - ${props.hasPageNav ? '64' : '0'}px
        ) !important;
      `}
    }

    .right-panel-open:not(.left-nav-panel-open) & {
      max-width: calc(100vw - ${LEFT_SIDEBAR_MIN_WIDTH}px) !important;
    }

    .right-panel-open:not(.left-nav-panel-open).filter-panel-open & {
      ${(props: any) => css`
        max-width: calc(
          100vw - ${LEFT_SIDEBAR_MIN_WIDTH}px - ${FILTER_PANEL_WIDTH}px - ${props.hasPageNav ? '64' : '0'}px
        ) !important;
      `}
    }
  `)}

  ${media.md(css`
    .right-panel-open.left-nav-panel-open & {
      width: calc(100vw - ${LEFT_SIDEBAR_DEFAULT_WIDTH}px) !important;
      min-width: calc(100vw - ${LEFT_SIDEBAR_DEFAULT_WIDTH}px) !important;
    }
  `)}

  @media screen and (min-width: ${themeBreakpoint('small')}px) and (max-width: ${MIN_ALL_PANELS_DEFAULT}px) {
    .right-panel-open.left-nav-panel-open & {
      width: calc(100vw - ${LEFT_SIDEBAR_DEFAULT_WIDTH}px) !important;
      min-width: calc(100vw - ${LEFT_SIDEBAR_DEFAULT_WIDTH}px) !important;
    }
  }

  @media screen and (min-width: ${themeBreakpoint('small')}px) and (max-width: ${MIN_ALL_PANELS_NAV_MIN}px) {
    .right-panel-open:not(.left-nav-panel-open) & {
      width: calc(100vw - ${LEFT_SIDEBAR_MIN_WIDTH}px) !important;
      min-width: calc(100vw - ${LEFT_SIDEBAR_MIN_WIDTH}px) !important;
    }
  }
`

const Dragger = styled(Box).attrs(() => ({
  id: 'right-panel-dragger'
}))`
  position: absolute;
  width: 10px;
  z-index: 4;
  top: 0;
  bottom: 0;
  border-left: 1px solid transparent;

  &:hover {
    border-color: ${themeColor('text-light')};
  }

  &.dragging {
    border-color: ${themeColor('primary')};
  }

  #page-root:not(.right-panel-open) & {
    display: none;
  }
`

const Handle = styled(Box)`
  display: none;
  cursor: col-resize;
  position: absolute;
  left: -4px;
  top: 50%;
  margin-top: -20px;
  height: 40px;
  width: 7px;
  border: 1px solid transparent;
  border-color: inherit;
  border-top: 0;
  border-bottom: 0;

  ${Dragger}:hover &, ${Dragger}.dragging & {
    display: block;
  }
`

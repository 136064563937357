import { atom, AtomEffect, DefaultValue, selector, useRecoilValue } from 'recoil'
import { produce } from 'immer'

import { getRunbookId, getRunbookVersionId } from 'main/recoil/shared/nav-utils'
import { RunbookVersion } from 'main/services/queries/types'
import {
  getRunbookVersion,
  GetRunbookVersionResponse,
  RunbookVersionMeta
} from 'main/services/queries/use-runbook-versions'
import { runbookRunbookTypeState, runbookState, useRunbookRunbookType } from '../runbook/runbook'

// *******************************
// Runbook version api response
// *******************************

const syncRunbookVersionResponseEffect: AtomEffect<GetRunbookVersionResponse> = ({ setSelf, resetSelf }) => {
  const getInitialRunbookVersion = async () => {
    const runbookId = getRunbookId()
    const runbookVersionId = getRunbookVersionId()

    if (runbookId && runbookVersionId) {
      const data = await getRunbookVersion(runbookId, runbookVersionId)
      setSelf(data)
    } else {
      return new DefaultValue()
    }
  }

  getInitialRunbookVersion()

  const handlePathChange = async (event: CustomEvent) => {
    const { pathname, previousPathname } = event.detail
    const previousRunbookId = getRunbookId(previousPathname)
    const runbookId = getRunbookId(pathname) as string
    const previousRunbookVersionId = getRunbookVersionId(previousPathname)
    const runbookVersionId = getRunbookVersionId(pathname) as string

    if (
      runbookId &&
      previousRunbookId === runbookId &&
      runbookVersionId &&
      previousRunbookVersionId === runbookVersionId
    ) {
      return
    }

    resetSelf()

    if (runbookId && runbookVersionId) {
      const data = await getRunbookVersion(runbookId, runbookVersionId)
      // because we swallow canceled errors this types as possibly undefined. however, this never
      // gets here if the request is canceled, so we can safely assert that it is not undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setSelf(data!)
    }
  }

  const handleRefresh = async (event: any) => {
    if (event.detail.type === 'runbook-version') {
      getInitialRunbookVersion()
    }
  }

  window.addEventListener('pathnamechanged', handlePathChange as any)
  window.addEventListener('refresh-data-store', handleRefresh as any)

  return () => {
    window.removeEventListener('pathnamechanged', handlePathChange as any)
    window.removeEventListener('refresh-data-store', handleRefresh as any)
  }
}

// this is what is updated, but via the runbookVersionState selector
export const runbookVersionResponseState_INTERNAL = atom<GetRunbookVersionResponse>({
  key: 'runbook-version:response',
  effects: [syncRunbookVersionResponseEffect]
})

// *******************************
// Runbook version
// *******************************

export const runbookVersionState = selector<RunbookVersion>({
  key: 'runbook-version',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const resp = get(runbookVersionResponseState_INTERNAL)
    return resp.runbook_version
  },
  set: ({ set, get }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return newValue
    }
    const prevRunbookResponse = get(runbookVersionResponseState_INTERNAL)

    set(
      runbookVersionResponseState_INTERNAL,
      produce(prevRunbookResponse, draft => {
        draft.runbook_version = newValue
      })
    )
  }
})

export const runbookVersionIdState = selector({
  key: 'runbook-version:id',
  get: ({ get }) => {
    return get(runbookVersionState).id
  }
})

export const runbookVersionStageState = selector({
  key: 'runbook-version:stage',
  get: ({ get }) => {
    return get(runbookVersionState).stage
  }
})

export const runbookVersionPermissionsState = selector({
  key: 'runbook-version:permissions',
  get: ({ get }) => {
    return get(runbookVersionMetaState).permissions
  }
})

export const isVersionCurrentState = selector({
  key: 'runbook-version:is-current',
  get: ({ get }) => {
    const runbookVersion = get(runbookVersionState)
    const runbook = get(runbookState)
    return runbookVersion.id === runbook.current_version.id
  }
})

export const isVersionEditable = selector({
  key: 'runbook-version:is-editable',
  get: ({ get }) => {
    const runbookVersion = get(runbookVersionState)
    if (runbookVersion.stage === 'planning' || runbookVersion.stage === 'paused') return true

    const { dynamic: isDynamic } = get(runbookRunbookTypeState)
    return isDynamic && runbookVersion.stage !== 'complete'
  }
})

export const runbookVersionStreamCountState = selector({
  key: 'runbook-version:stream-count',
  get: ({ get }) => {
    return get(runbookVersionState).streams_count
  },
  set: ({ set, get }, newValue) => {
    if (newValue instanceof DefaultValue) return newValue

    const prevRunbookVersionState = get(runbookVersionState)
    const nextRunbookVersionState = produce(prevRunbookVersionState, draft => {
      draft.streams_count = newValue
    })

    set(runbookVersionState, nextRunbookVersionState)
  }
})

// Use this hook in components to avoid re-renders when non-dependent properties change
export const useRunbookVersionProperty = <TKey extends keyof RunbookVersion>(args: { attribute: TKey }) =>
  useRecoilValue(runbookVersionState)[args.attribute]
export const useRunbookVersion = () => {
  return useRecoilValue(runbookVersionState)
}

// *******************************
// Runbook version meta
// *******************************

export const runbookVersionMetaState = selector<RunbookVersionMeta>({
  key: 'runbook-version:meta',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const resp = get(runbookVersionResponseState_INTERNAL)
    return resp.meta
  },
  set: ({ set, get }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return newValue
    }
    const prevRunbookResponse = get(runbookVersionResponseState_INTERNAL)

    set(
      runbookVersionResponseState_INTERNAL,
      produce(prevRunbookResponse, draft => {
        draft.meta = newValue
      })
    )
  }
})

// Use this hook in components to avoid re-renders when non-dependent properties change
export const useRunbookVersionMetaProperty = <TKey extends keyof RunbookVersionMeta>(args: { attribute: TKey }) =>
  useRecoilValue(runbookVersionMetaState)[args.attribute]

export const useRunbookVersionId = () => {
  return useRunbookVersionProperty({ attribute: 'id' })
}

export const useIsDynamicRunbook = () => {
  const { dynamic: isDynamic } = useRunbookRunbookType()
  return isDynamic
}

export const useIsEditableRunbook = () => {
  return useRecoilValue(isVersionEditable)
}

export const useIsCurrentRunbookVersion = () => {
  return useRecoilValue(isVersionCurrentState)
}

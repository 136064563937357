import { useEffect, useState } from 'react'

import { Checkbox, Loader, Modal } from '@cutover/react-ui'
import { SelectedApp } from './apps-types'
import { useLanguage } from 'main/services/hooks'

type AppsManagementModalProps = {
  isOpen: boolean
  onClose: () => void
  onClickConfirm: (availableApps: SelectedApp[] | undefined) => void
  availableApps?: SelectedApp[] | undefined
}

export const AppsManagementModal = ({ isOpen, onClose, onClickConfirm, availableApps }: AppsManagementModalProps) => {
  const [availableAppsState, setAvailableAppsState] = useState<SelectedApp[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(availableApps === undefined)
  const { t } = useLanguage('apps')

  useEffect(() => {
    setAvailableAppsState(availableApps)

    if (availableApps) {
      setIsLoading(false)
    }
  }, [availableApps, isOpen])

  const handleAppSave = () => {
    onClickConfirm(availableAppsState)
    onClose()
  }

  const handleAppSelection = (app: SelectedApp) => {
    setAvailableAppsState(availableAppsState?.map(a => (a.appId === app.appId ? { ...a, visible: !a.visible } : a)))
  }

  return (
    <Modal open={isOpen} onClose={onClose} onClickConfirm={handleAppSave} title={t('manageApps')}>
      <p>{t('manageAppsIntroduction')}</p>
      {isLoading && <Loader />}
      {availableAppsState && availableAppsState.length === 0 ? <p>{t('manageAppsEmpty')}</p> : null}
      {availableAppsState?.map(app => {
        return (
          <Checkbox
            key={app.appId}
            id={`app-${app.appId}`}
            label={app.name}
            checked={app.visible}
            onChange={() => handleAppSelection(app)}
          />
        )
      })}
    </Modal>
  )
}

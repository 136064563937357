import { Dispatch, SetStateAction, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'

import { Box, Modal, useNotify } from '@cutover/react-ui'
import { SamlConfigurationFormType } from '../right-panels/saml-configurations-edit-panel'
import { samlConfigFormValidationSchema } from '../saml-config-form-validation-schema'
import { SamlConfigurationForm } from '../saml-configuration-form'
import { queryClient } from 'main/query-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { useLanguage } from 'main/services/hooks'
import { SamlConfiguration } from 'main/services/queries/types'
import { useCreateSamlConfigurationsMutation } from 'main/services/queries/use-saml-configurations-query'

type CreateNewSamlConfigModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const CreateNewSamlConfigModal = ({ open, setOpen }: CreateNewSamlConfigModalType) => {
  const notify = useNotify()
  const { t } = useLanguage('samlConfigurations')
  const [apiError, setApiError] = useState<string | undefined>(undefined)
  const createMutation = useCreateSamlConfigurationsMutation()
  const { isLoading: isCreateLoading } = createMutation

  const defaultNewConfigValues = {
    custom_acs_url: '',
    allowed_clock_drift: 30,
    default: false,
    id: undefined,
    idp_cert_multi: { signing: [] },
    idp_sso_service_url: '',
    hidden: false,
    sp_entity_id: '',
    name: '',
    options: { active_roles: false, purge_roles: false },
    attribute_mappings: {},
    certificates_with_metadata: { signing: [], meta_data: [] },
    certificate: undefined
  } as unknown as SamlConfigurationFormType

  const [samlConfiguration, setSamlConfiguration] = useState<SamlConfigurationFormType>(defaultNewConfigValues)

  const validationSchema = samlConfigFormValidationSchema(t)

  const methods = useForm<SamlConfigurationFormType>({
    defaultValues: defaultNewConfigValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const onSubmitCreate = async (data: SamlConfiguration) => {
    createMutation.mutate(data, {
      onSuccess: () => {
        notify.success(t('newSamlConfiguration.toasters.success'))
        queryClient.invalidateQueries(['saml-configurations'])
        closeNewSamlConfigForm()
      },
      onError: error => {
        const castedError = error as ApiError
        if (castedError?.errors) {
          setApiError(castedError?.errors.join(', '))
        }
        notify.error(t('newSamlConfiguration.toasters.genericError'))
      }
    })
  }

  const closeNewSamlConfigForm = () => {
    setOpen(false)
    setSamlConfiguration(defaultNewConfigValues)
    methods.reset()
  }

  return (
    <Modal
      title={t('newSamlConfiguration.title')}
      open={open}
      onClose={closeNewSamlConfigForm}
      onClickConfirm={methods.handleSubmit(onSubmitCreate)}
      loading={isCreateLoading}
      loadingText={t('samlConfigurations.loading')}
    >
      <Box>
        <FormProvider {...methods}>
          <SamlConfigurationForm samlConfiguration={samlConfiguration} apiError={apiError} />
        </FormProvider>
      </Box>
    </Modal>
  )
}

import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useFocusVisible } from '@react-aria/interactions'
import { Button as GrommetButton, Stack } from 'grommet'
import { useDebounce } from 'react-use'
import styled, { css, keyframes } from 'styled-components'

import {
  Box,
  Icon,
  IconButton,
  resolveColor,
  TextInput,
  TextInputProps,
  ThemeContext,
  useTheme
} from '@cutover/react-ui'
import { useInitialMount } from 'main/services/hooks/use-initial-mount'

export type SubHeaderSearchProps = {
  tip?: string
  placeholder?: string
  loading?: boolean
  onSearch?: (value: string) => void
  onClose?: () => void
  minQuery?: number
  debounceDelay?: number
  initialValue?: string
  setIsSearchOpen?: (value: boolean) => void
  a11yTitle?: string
}

export type SearchType = {
  clear: () => void
}

export const SubHeaderSearch = forwardRef<SearchType, SubHeaderSearchProps>(
  (
    {
      onSearch,
      onClose,
      tip,
      loading,
      placeholder,
      minQuery = 0,
      initialValue,
      debounceDelay = 750,
      setIsSearchOpen,
      a11yTitle
    },
    ref
  ) => {
    const isInitialMount = useInitialMount()
    const [initialLoadValue, setInitialLoadValue] = useState(initialValue)
    const [query, setQuery] = useState(initialValue ?? '')
    const [debouncedQuery, setDebouncedQuery] = useState(initialValue ?? '')
    const [isSearchExpanded, setIsSearchExpanded] = useState(!!initialValue)
    const [showSearchInput, setShowSearchInput] = useState(false)

    const [, cancel] = useDebounce(
      () => {
        setDebouncedQuery(query)
      },
      debounceDelay,
      [query]
    )

    useEffect(() => {
      return () => {
        cancel()
      }
    }, [])

    useEffect(() => {
      if (initialValue) {
        setInitialLoadValue(initialValue)
        setIsSearchExpanded(true)
      }
    }, [initialValue])

    const handleInputChange = useCallback((event: any) => {
      setQuery(event.currentTarget.value)
    }, [])

    const clearAndClose = () => {
      onClose?.()
      setIsSearchExpanded(false)
      setInitialLoadValue(undefined)
      setQuery('')
    }

    useEffect(() => {
      if (isInitialMount) return

      if (debouncedQuery.length >= minQuery) {
        onSearch?.(debouncedQuery)
      }
    }, [debouncedQuery])

    useEffect(() => {
      if (isSearchExpanded) {
        setShowSearchInput(true)
        setIsSearchOpen?.(true)
      } else {
        setShowSearchInput(false)
        setIsSearchOpen?.(false)
      }
    }, [isSearchExpanded])

    useImperativeHandle(ref, () => ({
      clear: () => {
        clearAndClose()
      }
    }))

    return (
      <Box direction="row" css="position: relative; height: 40px; min-width: 40px;">
        <Box css="position: absolute;">
          {!isSearchExpanded && (
            <IconButton
              icon="search"
              label={tip ?? 'Search'}
              tipPlacement="top"
              onClick={() => setIsSearchExpanded(true)}
              a11yTitle={a11yTitle ?? tip ?? 'Search'}
              onKeyUp={e => {
                if (e.key === ' ' || e.key === 'Spacebar') {
                  setIsSearchExpanded(true)
                }
              }}
            />
          )}
        </Box>
        {isSearchExpanded && (
          <Stack anchor="right">
            {showSearchInput && (
              <SearchInput
                initialValue={initialLoadValue}
                placeholder={placeholder ?? 'Search'}
                setShowSearch={setShowSearchInput}
                onChange={handleInputChange}
                a11yTitle={a11yTitle ?? tip ?? 'Search'}
              />
            )}
            {loading ? (
              <LoadingSearchIcon icon="spinner" color="text-disabled" />
            ) : (
              <CloseSearchButton
                plain
                icon={<Icon icon="close" color="text-disabled" />}
                onClick={() => clearAndClose()}
                onKeyUp={e => {
                  if (e.key === ' ' || e.key === 'Spacebar') {
                    clearAndClose()
                  }
                }}
              />
            )}
          </Stack>
        )}
      </Box>
    )
  }
)

const suffixStyles = css`
  display: block !important;
  position: relative;
  right: 8px;
`

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const LoadingSearchIcon = styled(Icon)`
  ${suffixStyles}
  animation: ${rotate} 0.75s linear infinite;
`

const CloseSearchButton = styled(GrommetButton)`
  ${suffixStyles}
  visibility: 'hidden';
  animation: fadeIn 1s linear;
  transition: visibility 0.5s linear;
  &:hover {
    svg {
      fill: ${({ theme }) => resolveColor('text-light', theme)};
    }
  }
`

type SearchInputProps = {
  onChange?: TextInputProps['onChange']
  setShowSearch: (show: boolean) => void
  placeholder?: string
  initialValue?: string
  a11yTitle?: string
}

const SearchInput = memo(({ onChange, initialValue, placeholder, a11yTitle }: SearchInputProps) => {
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>(null)
  const [focused, setFocused] = useState(false)
  const { isFocusVisible } = useFocusVisible({ isTextInput: true })

  // FIXME: this is because it initially re-renders and blurs immediately
  const handleBlur = () => {
    setFocused(true)
  }

  useEffect(() => {
    if (initialValue && inputRef.current) {
      inputRef.current.value = initialValue
    }
  }, [initialValue])

  useEffect(() => {
    if (!initialValue && !focused) {
      inputRef.current?.focus()
    }
  }, [initialValue, focused])

  return (
    <ThemeContext.Extend
      value={{
        textInput: {
          extend: css`
            font-size: 15px;
            height: 40px;
            border-radius: 40px;
            padding-inline-start: 40px;
            padding-inline-end: 34px;
            font-weight: normal;
            outline: none;
            &:focus {
              outline: ${isFocusVisible ? `2px solid ${resolveColor('primary', theme)}` : 'none'};
            }
          `,
          container: {
            extend: css`
              background-color: ${resolveColor('bg', theme)};
              border-radius: 40px;
              svg {
                position: relative;
                left: -4px;
              }
            `
          }
        }
      }}
    >
      <TextInput
        defaultValue={initialValue}
        placeholder={placeholder ?? 'Search'}
        a11yTitle={a11yTitle}
        ref={inputRef}
        plain
        onBlur={handleBlur}
        onChange={onChange}
        icon="search"
      />
    </ThemeContext.Extend>
  )
})

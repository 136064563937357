import { forwardRef, ReactNode, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components/macro'

import {
  LegacyBox as Box,
  CheckboxGroup,
  LegacyFieldLabel as FieldLabel,
  LegacyFlex as Flex,
  Form,
  LegacyFormField as FormField,
  Message,
  RadioboxGroup,
  Select,
  TextInput
} from '@cutover/react-ui'
import { Nullish } from '@cutover/utility-types'
import { AccountListForm } from './account-list-form'
import { ColorPicker } from './color-picker'
import { createRunbookTypeSchema } from './runbook-type-schema'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'
import { useLanguage } from 'main/services/hooks'
import { RunbookTypeIcon } from 'main/services/hooks/config-types'
import { IconSelect } from 'Shared/Components/Molecules/IconSelect/IconSelect'
import { useInjection } from 'Shared/Helpers/InjectionHelpers'

// OBS-1071: This form is not used for the edit panel. See RunbookTypeFormComponent.tsx.

export type RunbookTypeForm = {
  visibility: 'global' | 'account'
  accountId: Nullish<number>
  name: Nullish<string>
  description: Nullish<string>
  iconName: Nullish<string>
  iconColor: Nullish<string>
  settings: string[]
  approvalFlowId?: number
}

export type RunbookTypeFormProps = {
  mode: 'create' | 'update'
  iconIds: RunbookTypeIcon[]
  iconColors: string[]
  defaultValues: any // TODO: type
  serverError?: string
  onSave: (data: any) => void
  children?: ReactNode
  isGlobalUser: () => boolean
  availableApprovalFlows: []
}

export const RunbookTypeForm = forwardRef<HTMLFormElement, RunbookTypeFormProps>(function (
  { mode, iconIds, iconColors, defaultValues, serverError, onSave, children, isGlobalUser, availableApprovalFlows },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref
) {
  const { translate } = useLanguage()

  const authenticationRepository = useInjection(AuthenticationRepository)
  const isCutoverEmployee = authenticationRepository?.user?.cutoverEmployee

  const formDefaultValues = {
    ...defaultValues,
    visibility: isGlobalUser() ? 'global' : 'account'
  }

  const methods = useForm<RunbookTypeForm>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(createRunbookTypeSchema({ translate })),
    defaultValues: formDefaultValues,
    shouldUnregister: false
  })

  const visibility = methods.watch('visibility')
  const iconColor = methods.watch('iconColor')
  const settings = methods.watch('settings')

  const isIncidentChecked = methods.getValues('settings').includes('incident')
  useEffect(() => {
    if (settings.includes('incident') && !settings.includes('dynamic')) {
      methods.setValue('settings', ['incident', 'dynamic'])
    }
  }, [methods.setValue, settings])

  useEffect(() => {
    if (visibility === 'global') {
      methods.setValue('accountId', null)
    }
  }, [methods.setValue, visibility])

  const formErrors = methods.formState?.errors
  const formErrorMessages = Object.values(formErrors)
    ?.map(error => (error as FieldError).message)
    .filter(Boolean) as string[]

  const errors = serverError || formErrorMessages

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSave)} aria-label="form">
        {errors ? (
          <ErrorMessageWrapper>
            <Message type="error" message={errors} />
          </ErrorMessageWrapper>
        ) : null}
        <TextInput
          {...methods.register('name')}
          label={translate('runbookTypes:fields:name:label')}
          required
          hasError={!!formErrors?.name}
          autoFocus
        />
        <TextInput
          {...methods.register('description')}
          label={translate('runbookTypes:fields:description:label')}
          maxLength={50}
          required
          hasError={!!formErrors?.description}
        />
        <Flex flexDirection="column" gap={20}>
          <Controller
            name="visibility"
            control={methods.control}
            defaultValue="global"
            render={({ field: { name, value, onChange, onBlur, ref } }) => (
              <RadioboxGroup
                name={name}
                label="Visibility"
                direction="row"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                options={[
                  { value: 'global', label: 'Global' }, // TODO: translate
                  { value: 'account', label: 'Select a workspace...' }
                ]}
              />
            )}
          />

          {visibility === 'global' ? null : (
            <AccountListForm
              methods={methods}
              hasError={!!formErrors?.accountId}
              defaultValue={defaultValues?.accountId?.toString()}
              setValue={methods.setValue}
            />
          )}

          <Controller
            name="iconName"
            control={methods.control}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormField
                css={`
                  padding-bottom: 8px;
                `}
              >
                <FieldLabel as="label" size={13}>
                  Icon
                </FieldLabel>
                <IconSelect
                  iconIds={iconIds}
                  value={value}
                  onSelect={onChange}
                  onBlur={onBlur}
                  defaultColor="#ccc"
                  selectedColor={iconColor || '#ccc'}
                />
              </FormField>
            )}
          />
          <Controller
            name="iconColor"
            control={methods.control}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormField
                css={`
                  padding-bottom: 8px;
                `}
              >
                <FieldLabel as="label" size={13}>
                  Icon color
                </FieldLabel>
                <Box
                  css={`
                    margin-top: 10px;
                  `}
                >
                  <ColorPicker colors={iconColors} value={value ?? undefined} onBlur={onBlur} onSelect={onChange} />
                </Box>
              </FormField>
            )}
          />
          <Controller
            name="approvalFlowId"
            control={methods.control}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                data-testid="approvalFlowId"
                onChange={onChange}
                inputRef={ref}
                clearable
                label="Approval Flow"
                value={value}
                options={availableApprovalFlows}
              />
            )}
          />

          {isCutoverEmployee || mode === 'update' ? (
            <Controller
              name="settings"
              control={methods.control}
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  value={isIncidentChecked ? ['incident', 'dynamic'] : value}
                  label={translate('runbookTypes:fields:additionalSettings:label')}
                  onChange={(event: any) => event && onChange(event.value)}
                  options={[
                    ...(isCutoverEmployee
                      ? [
                          {
                            value: 'incident',
                            option: 'incident',
                            label: translate('runbookTypes:fields:additionalSettings:checkboxLabels:incident:label'),
                            helpText: translate(
                              'runbookTypes:fields:additionalSettings:checkboxLabels:incident:helpText'
                            )
                          }
                        ]
                      : []),
                    ...(isCutoverEmployee
                      ? [
                          {
                            value: 'dynamic',
                            option: 'dynamic',
                            label: translate('runbookTypes:fields:additionalSettings:checkboxLabels:dynamic:label'),
                            helpText: translate(
                              'runbookTypes:fields:additionalSettings:checkboxLabels:dynamic:helpText'
                            ),
                            disabled: isIncidentChecked
                          }
                        ]
                      : [])
                  ]}
                />
              )}
            />
          ) : null}
        </Flex>
        {children}
      </Form>
    </FormProvider>
  )
})

const ErrorMessageWrapper = styled.div`
  margin-bottom: 24px;
`

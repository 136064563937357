import { ReactNode } from 'react'
import styled from 'styled-components'

import { Box, resolveColor, themeEdgeSize } from '@cutover/react-ui'
import { useGetConfigs } from 'main/services/queries/use-get-configs'

type AuthenticationWrapperProps = {
  children: ReactNode
}

export const AuthenticationFormWrapper = ({ children }: AuthenticationWrapperProps) => {
  const { data } = useGetConfigs()

  return (
    <AuthContainer pad="small">
      {!!data?.topRightLogo && <ClientLogo alt="Client logo" src={data.topRightLogo} />}
      {children}
    </AuthContainer>
  )
}

const ClientLogo = styled.img`
  display: block;
  max-height: 64px;
  max-width: 100%;
  margin: 0 auto;
`

const AuthContainer = styled(Box)`
  background-color: ${({ theme }) => resolveColor('bg', theme)};
  border-radius: ${themeEdgeSize('medium')};
  flex-shrink: 0;
`

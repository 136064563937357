import { colors, LegacyFlex as Flex, px2rem } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks/use-language'

type LabelNewProps = {
  size?: 'small' | 'medium'
}

export const LabelNew = ({ size = 'small' }: LabelNewProps) => {
  const { t } = useLanguage()

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      css={`
        background-color: ${colors.lightPink};
        color: ${colors.pink};
        text-transform: uppercase;
        width: ${size === 'small' ? px2rem(22) : px2rem(34)};
        height: ${size === 'small' ? px2rem(12) : px2rem(18)};
        font-size: ${size === 'small' ? px2rem(7) : px2rem(11)};
        font-weight: bold;
        border-radius: 4px;
      `}
    >
      {t('common:newLabel')}
    </Flex>
  )
}

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import * as yup from 'yup'
import { useRecoilValue } from 'recoil'

import { Modal, Text } from '@cutover/react-ui'
import { deleteStream, StreamDeletePayload } from 'main/services/queries/use-stream'
import {
  streamsFlattenedState,
  useRunbookId,
  useRunbookVersionId,
  useRunbookVersionProperty
} from 'main/recoil/runbook'
import { FormModal, StreamSelectField } from 'main/components/shared/form'
import { useEditStreamPanel } from 'main/context/panel-context'
import { RunbookStreamDestroyResponse } from 'main/services/api/data-providers/runbook-types'
import { useProcessStreamDeleteResponse } from 'main/recoil/runbook/updaters/stream-operations'

type StreamDeleteFormType = yup.InferType<typeof validationSchema>

const validationSchema = yup.object({
  new_stream_id: yup.number().required()
})

export const StreamDeleteModal = ({
  onClose,
  streamName,
  streamId,
  tasksCount
}: {
  onClose?: () => void
  streamName: string
  streamId: number
  tasksCount: number
}) => {
  const { closePanel } = useEditStreamPanel()
  const processResponse = useProcessStreamDeleteResponse()
  const runbookId = useRunbookId()
  const runbookVersionId = useRunbookVersionId()
  const primaryStreamId = useRunbookVersionProperty({ attribute: 'primary_stream_id' })
  const { t } = useTranslation('runbook', { keyPrefix: 'streamDelete' })
  const allStreams = useRecoilValue(streamsFlattenedState)

  const streamOptions = useMemo(
    () => (tasksCount === 0 ? [] : allStreams?.filter(stream => stream.id !== streamId)),
    [streamId, allStreams]
  )

  const handleSubmit = async (data: StreamDeletePayload) => {
    if (!data.new_stream_id) {
      data.new_stream_id = primaryStreamId
    }
    return await deleteStream({ runbookId, runbookVersionId, streamId, ...data })
  }

  const handleSuccess = (response: RunbookStreamDestroyResponse) => {
    processResponse(response)
    closePanel()
  }

  return (
    <FormModal<StreamDeleteFormType>
      title={t('title')}
      open
      schema={validationSchema}
      defaultValues={{ new_stream_id: primaryStreamId }}
      confirmText={t('delete')}
      confirmIcon="delete"
      onClose={onClose}
      successMessage={t('deleteSuccess')}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    >
      <Text css="margin-bottom: 8px;">{parse(t('areYouSure', { streamName: streamName }))}</Text>
      {tasksCount > 0 && (
        <>
          <Text css="margin-bottom: 16px;">{t('reassign', { count: tasksCount })}</Text>
          <StreamSelectField<StreamDeleteFormType>
            label={t('newStream')}
            streams={streamOptions ?? []}
            name="new_stream_id"
            placeholder={t('newStreamPlaceholder')}
          />
        </>
      )}
    </FormModal>
  )
}

export const StreamUnableToDeleteModal = ({ onClose }: { onClose?: () => void }) => {
  const { t } = useTranslation('runbook', { keyPrefix: 'streamDelete' })

  return (
    <Modal
      title={t('title')}
      open
      confirmText={t('acceptUnable')}
      confirmIcon="arrow-forward"
      onClose={onClose}
      onClickConfirm={onClose}
      hasCancelButton={false}
    >
      <Text css="margin-bottom: 8px;">{t('unable')}</Text>
    </Modal>
  )
}

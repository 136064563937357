import { ReactNode } from 'react'

import { ItemSize } from './size-config'
import { ListItem } from '..'
import { Box } from '../../layout'
import { Text } from '../../typography'

// Internal component used to render UserListItem and TeamListItem

export type PeopleListItemLayoutProps = {
  id: number
  size?: ItemSize
  title: string
  titleSuffix?: string
  subtitle?: string
  onClick?: () => void
  onClickRemove?: (id: number) => void
  avatar: ReactNode
  active?: boolean
  hasError?: boolean
  endComponents?: ReactNode[]
  prominence?: 'high' | 'default' | 'low'
  'data-testid'?: string
  a11yTitle?: string
  disabled?: boolean
}

export const PeopleListItemLayout = ({
  id,
  size = 'medium',
  title,
  titleSuffix,
  subtitle,
  onClick,
  onClickRemove,
  endComponents,
  disabled,
  active,
  hasError,
  prominence = 'default',
  avatar,
  'data-testid': testId,
  a11yTitle
}: PeopleListItemLayoutProps) => {
  const textContent = (
    <Box>
      <Text
        truncate="tip"
        a11yTitle={title}
        tipPlacement="top"
        color={hasError ? 'error' : 'text'}
        size={size === 'small' ? '13px' : '15px'}
        data-testid="people-item-name"
      >
        {title}
        {titleSuffix && (
          <Text color={hasError ? 'error' : disabled ? 'text' : 'text-light'} size={size === 'small' ? '13px' : '15px'}>
            {' '}
            {titleSuffix}
          </Text>
        )}
      </Text>
      {size !== 'small' && subtitle && (
        <Text size="small" color="text-light">
          {subtitle}
        </Text>
      )}
    </Box>
  )

  const handleClickRemoveItem = onClickRemove ? () => onClickRemove(id) : undefined

  return (
    <ListItem
      size={size}
      startComponent={avatar}
      endComponents={endComponents}
      onClick={onClick}
      disabled={disabled}
      onClickRemove={handleClickRemoveItem}
      a11yTitle={a11yTitle ?? title}
      active={active}
      prominence={prominence}
      data-testid={testId}
    >
      {textContent}
    </ListItem>
  )
}

import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { eventManager } from 'event-manager'
import { useToggle } from 'react-use'

import { useResponsiveContext } from '@cutover/react-ui'

type SidebarNavView = 'default' | 'settings' | 'search'

type ReactAppType = 'default' | 'legacy'

export type LeftSidebarSize = 'small' | 'default' | 'full' | 'hidden'

type SidebarNavContextType = {
  view: SidebarNavView
  setView: (value: SidebarNavView) => void
  leftSidebarSize: LeftSidebarSize
  isLeftSidebarOpen: boolean
  toggleLeftSidebar: (nextValue?: boolean, emit?: boolean) => void
}

export const SidebarNavContext = createContext<SidebarNavContextType>({
  view: 'default',
  setView: () => {},
  leftSidebarSize: 'default',
  isLeftSidebarOpen: true,
  toggleLeftSidebar: () => {}
})

export const SidebarNavProvider = ({
  children,
  reactApp = 'default',
  initialView
}: {
  reactApp?: ReactAppType
  children: ReactNode
  initialView?: SidebarNavView
}) => {
  const [view, setView] = useState<SidebarNavView>(initialView ?? 'default')
  const [isLeftSidebarOpen, toggleOpen] = useToggle(true)
  const screenSize = useResponsiveContext()

  const sidebarState = useMemo(() => {
    let leftSidebarSize: LeftSidebarSize = 'default'

    if (screenSize === 'small') {
      if (isLeftSidebarOpen) {
        leftSidebarSize = 'full'
      } else {
        leftSidebarSize = 'hidden'
      }
    } else {
      if (isLeftSidebarOpen) {
        leftSidebarSize = 'default'
      } else {
        leftSidebarSize = 'small'
      }
    }

    return {
      isLeftSidebarOpen,
      leftSidebarSize
    }
  }, [isLeftSidebarOpen, screenSize])

  const toggleLeftSidebarNavPanel = useCallback(
    (open?: boolean, emit: boolean = true) => {
      const root = document.getElementById('page-root')
      const shouldOpen = open === undefined ? !isLeftSidebarOpen : open

      if (shouldOpen) {
        if (root) root.classList.add('left-nav-panel-open')
        if (emit) eventManager.emit('react-toggle-main-nav', { open: true })
      } else {
        if (root) root.classList.remove('left-nav-panel-open')
        if (emit) eventManager.emit('react-toggle-main-nav', { open: false })
      }

      toggleOpen(shouldOpen)
    },
    [isLeftSidebarOpen]
  )

  useEffect(() => {
    const externalToggleNav = ({ open }: { open: boolean }) => toggleLeftSidebarNavPanel(open, false)

    eventManager.on('angular-toggle-main-nav', externalToggleNav)

    if (reactApp === 'default') {
      eventManager.on('legacy-react-toggle-main-nav', externalToggleNav)
    }

    if (screenSize === 'small') {
      toggleLeftSidebarNavPanel(false, true)
    } else {
      toggleLeftSidebarNavPanel(true, true)
    }

    return () => {
      eventManager.off('angular-toggle-main-nav', externalToggleNav)

      if (reactApp === 'default') {
        eventManager.off('legacy-react-toggle-main-nav', externalToggleNav)
      }
    }
  }, [])

  return (
    <SidebarNavContext.Provider
      value={{
        view,
        setView,
        ...sidebarState,
        toggleLeftSidebar: toggleLeftSidebarNavPanel
      }}
    >
      {children}
    </SidebarNavContext.Provider>
  )
}

export const useSidebarNavContext = () => useContext(SidebarNavContext)

export const useLeftSidebarSize = () => {
  const { leftSidebarSize } = useSidebarNavContext()
  return leftSidebarSize
}

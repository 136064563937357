import { LegacyFlex as Flex, Message, Page } from '@cutover/react-ui'
import { InstanceSettingsForm } from './instance-settings-form'
import { useInstanceSettingsQuery } from './use-instance-settings'
import { useLanguage } from 'main/services/hooks/use-language'

export const InstanceSettingsPage = () => {
  const { t } = useLanguage('instanceSettings')
  const { isLoading, data: settings, isError } = useInstanceSettingsQuery()

  return (
    <Page loading={isLoading} error={isError} errorMessage={t('error')}>
      <Flex gap={24} flexDirection="column">
        <Message type="warning" message={t('warning', { joinArrays: '<br><br>' })} />
        {settings && <InstanceSettingsForm settings={settings} />}
      </Flex>
    </Page>
  )
}

import { MouseEventHandler, useContext } from 'react'

import { Avatar, Box, Tooltip } from '@cutover/react-ui'
import { VolumeComponent } from './volume-table-wrapper'
import { useAngularRightSidePanel } from '../../../../../services/hooks/use-angular-right-side-panel'
import { DashboardContext } from '../../../runbooks-dashboard-page'
import { getFilterParams } from '../mrd-filter-util'
import { useDashboardRunbookListPanel } from 'main/context/panel-context'

type Interval = {
  count?: number
  end_date: number
  id: number
  label: string
  left: number
  width: number
}

type VolumeValue = {
  color: string
  id: number
  intervals: Interval[]
  parent_id: number
  name: string
  total: number
  displayName?: string
  outlinePath?: string
  path?: string
}

export type VolumeTableProps = VolumeTableData & {
  onSelect?: (data: any) => void
}

type VolumeTableData = {
  id: number
  name: string
  notes: string | null
  errors: string[] | null
  intervals: Interval[]
  parent_intervals: Interval[]
  total: number
  max_total_count: number
  max_row_count: number
  values: VolumeValue[]
  row_type: 'Project' | 'RunbookType' | 'Team' | 'Event' | 'CustomField'
  filter_key: string
}

export const VolumeTable = ({
  parent_intervals,
  intervals,
  id,
  total,
  max_total_count,
  max_row_count,
  values,
  row_type,
  filter_key,
  reactWorkspace = false
}: VolumeTableProps & { reactWorkspace?: boolean }) => {
  // path logic from previous angular logic
  const drawPath = (intervals: any, maxRowCount: number) => {
    let path = ''
    let previousY = null
    for (var ii = 0, nn = intervals.length; ii < nn; ii++) {
      var interval = intervals[ii]
      if (path.length) {
        path += ' L' + interval.left + ' ' + previousY
      } else {
        path += 'M0 100'
      }
      var y = 100 - (interval.count / maxRowCount) * 100
      path += ' L' + interval.left + ' ' + y

      previousY = y
    }
    path += ' L' + (interval.left + interval.width) + ' ' + previousY

    return path
  }

  const updatedValues = values.map(value => {
    value.displayName = value.name.length > 9 ? value.name.substr(0, 8) + '...' : value.name
    value.displayName = value.parent_id ? `\u00A0 \u00A0 \u00A0${value.displayName}` : value.displayName
    value.outlinePath = drawPath(value.intervals, max_row_count)
    value.path = value.outlinePath + ' L100 100 Z'
    return value
  })
  const totalOutlinePath = drawPath(intervals, max_total_count)
  const totalPath = totalOutlinePath + ' L100 100 Z'
  const fullWidth = !!updatedValues.length
  const isCustom = row_type === 'CustomField'

  const { openPanel: openDashboardRunbookListPanel } = useDashboardRunbookListPanel()
  const { openAngularSidePanel } = useAngularRightSidePanel()

  // obtain the dashboard info from DashboardContext (react context api) object (shared state coming from RunbookDashboardPage)
  const { accountId, dashboardId, filterParams } = useContext(DashboardContext)

  // volume table row click functionality
  const handleRowClick = (subFilterValue: number) => {
    const rowTypeMapping = {
      Project: 'project',
      RunbookType: 'type', // for how it's done in angular,
      Team: 'team',
      Event: 'event',
      CustomField: 'custom'
    }
    const rowType = rowTypeMapping[row_type] // this is needed for project[]=33 params in angular based on type of this volume table (e.g. project, team, event, runbookType)
    const contentId = {
      accountId,
      dashboardId,
      template_type: 'off',
      t: false,
      ...getFilterParams(filterParams),
      ...{ [rowType]: [subFilterValue] },
      subFilterKey: filter_key,
      subFilterValue,
      limit: 20
    }

    if (reactWorkspace) {
      openDashboardRunbookListPanel({ params: contentId })
    } else {
      // pass in as contentId
      // (in an object form so that angular can use the contentId object to make a request on the angular side)
      openAngularSidePanel({ content: 'filtered-changes', contentId })
    }
  }

  return (
    <VolumeComponent data-testid="volume-table">
      <Box className={`chart ${fullWidth ? 'fullWidth' : ''}`}>
        <ChartBackground
          intervals={intervals}
          parentIntervals={parent_intervals}
          isCustom={isCustom}
          reactWorkspace={reactWorkspace}
        />
        <AttributeRow
          id={id}
          values={updatedValues}
          onRowClick={handleRowClick}
          isCustom={isCustom}
          rowType={row_type}
        />
        <AttributeTotal id={id} total={total} totalOutlinePath={totalOutlinePath} totalPath={totalPath} />
      </Box>
    </VolumeComponent>
  )
}

type ChartBackgroundComponentProps = {
  parentIntervals: Interval[]
  intervals: Interval[]
  isCustom: boolean
}

export const ChartBackground = ({
  parentIntervals,
  intervals,
  isCustom,
  reactWorkspace
}: ChartBackgroundComponentProps & { reactWorkspace?: boolean }) => {
  const { openAngularSidePanel } = useAngularRightSidePanel()
  const { openPanel: openDashboardRunbookListPanel } = useDashboardRunbookListPanel()
  const { accountId, dashboardId, filterParams } = useContext(DashboardContext)

  const handleClickDate = (interval: Interval) => {
    const contentId = {
      accountId,
      dashboardId,
      template_type: ['off'],
      ...getFilterParams(filterParams),
      df: interval.id,
      dt: interval.end_date,
      t: false,
      limit: 20
    }

    if (reactWorkspace) {
      openDashboardRunbookListPanel({ params: contentId })
    } else {
      openAngularSidePanel({ content: 'filtered-changes', contentId })
    }
  }

  const intervalDisplay = (intervals: any, className: string) => {
    return intervals.map((interval: any, idx: number) => {
      const intervalDisplayStyle = { left: `${interval.left}%`, width: `${interval.width}%` }
      return (
        <Box
          key={`int-${intervals.id}-${idx}`}
          style={intervalDisplayStyle}
          onClick={() => handleClickDate(interval)}
          className={className}
          role="presentation"
        >
          <Box>{interval.label}</Box>
        </Box>
      )
    })
  }

  return (
    <Box className="background">
      {intervalDisplay(parentIntervals, `parent-interval ${isCustom ? 'non-clickable' : ''}`)}
      {intervalDisplay(intervals, `interval ${isCustom ? 'non-clickable' : ''}`)}
    </Box>
  )
}

type AttributeRowProps = {
  id: number
  values: VolumeValue[] // TODO: type
  onRowClick: (id: number) => void
  isCustom: boolean
  rowType: string
}

const AttributeRow = ({ id, values, onRowClick, isCustom, rowType }: AttributeRowProps) => {
  return (
    <>
      {values.map(value => (
        <Box
          role="presentation"
          className={`attribute-row ${isCustom ? 'non-clickable' : ''}`}
          key={`${value.id}-row`}
          onClick={() => onRowClick(value.id)}
          data-testid="volume-row"
        >
          {value.name.length > 9 ? (
            <Tooltip content={value.name} placement="right" delay={500}>
              <RowName
                color={value.color}
                rowType={rowType}
                total={value.total}
                displayName={value.displayName}
                name={value.name}
                onClick={() => onRowClick(value.id)}
              />
            </Tooltip>
          ) : (
            <RowName
              rowType={rowType}
              color={value.color}
              total={value.total}
              displayName={value.displayName}
              name={value.name}
            />
          )}
          <RowContent id={id} value={value} />
        </Box>
      ))}
    </>
  )
}

type RowNameProps = {
  rowType: string
  color: string
  total: number
  displayName: string | undefined
  name: string
  onClick?: MouseEventHandler<HTMLDivElement> | undefined
}

const RowName = ({ rowType, color, total, displayName, name, onClick }: RowNameProps) => {
  return (
    <Box className="name" onClick={onClick} direction="row" align="center">
      {rowType === 'Team' && (
        <Box margin={{ right: '4px' }}>
          <Avatar subject={{ name, color }} size="small" />
        </Box>
      )}

      <span>{`${displayName} (${total})`}</span>
    </Box>
  )
}

type RowContentProps = {
  id: number
  value: VolumeValue
}

const RowContent = ({ id, value }: RowContentProps) => {
  return (
    <>
      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
        <defs>
          <linearGradient id={`grd${id}:${value.id}`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: value.color, stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#ffffff', stopOpacity: 0.5 }} />
          </linearGradient>
          <clipPath id={`elem${id}:${value.id}`}>
            <path d={value.path} />
          </clipPath>
        </defs>
        <rect
          x="0"
          y="0"
          width="100"
          height="100"
          clipPath={`url(#elem${id}:${value.id})`}
          fill={`url(#grd${id}:${value.id})`}
        ></rect>
        <path
          d={value.outlinePath}
          fill="none"
          style={{ stroke: value.color }}
          strokeWidth="1.5"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </>
  )
}

type AttributeTotalProps = {
  id: number
  total: number
  totalOutlinePath: string
  totalPath: string
}

const AttributeTotal = ({ id, total, totalOutlinePath, totalPath }: AttributeTotalProps) => {
  return (
    <Box className="attribute-row total">
      <Box className="name">
        <span>Total ({total})</span>
      </Box>
      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
        <defs>
          <linearGradient id={`grd${id}`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: 'rgb(185,193,198)' }} />
            <stop offset="100%" style={{ stopColor: '#ffffff' }} />
          </linearGradient>
          <clipPath id={`elem-total${id}`}>
            <path d={totalPath} />
          </clipPath>
        </defs>
        <rect x="0" y="0" width="100" height="100" clipPath={`url(#elem-total${id})`} fill={`url(#grd${id})`}></rect>
        <path
          d={totalOutlinePath}
          fill="none"
          stroke="rgb(185,193,198)"
          strokeWidth="1.5"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </Box>
  )
}

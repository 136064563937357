import { useMutation } from 'react-query'

import { apiClient } from 'main/services/api'

export type UserPayload = { id: number; role: string }

export type RunbookTeamPayload = {
  teamId?: number
  name: string
  linked: boolean
  userIds?: Array<number>
  type?: 'team'
}

export type RunbookPeoplePayload = {
  users?: Array<UserPayload>
  runbookTeams?: Array<RunbookTeamPayload>
}

export const useRunbookPeopleCreate = (runbookId: number, runbookVersionId: number) => {
  return useMutation('runbookPeople', async (payload: RunbookPeoplePayload) => {
    const { data } = await apiClient.post({
      url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_people`,
      data: payload,
      convertCase: true
    })

    return data
  })
}

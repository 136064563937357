import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { ErrorBoundary } from 'react-error-boundary'
import { useForm } from 'react-hook-form'

import {
  Box,
  EditPanel,
  IconButton,
  Menu,
  MenuListItem,
  MenuListItemProps,
  Message,
  SearchBar,
  useNotify
} from '@cutover/react-ui'
import { CreatedUserListItemSelectedRole } from '@cutover/react-ui/src/list-item/people/user-list-item'
import { ExportUsersModal } from './export-users-modal'
import { PeoplePanelTabs } from './people-panel-tabs'
import { Permissions, Runbook, SelectedTeam, SelectedUser, Stream } from '../types'
import { useRunbookUsersUpdate } from '../use-runbook-users'
import { useFeature, useLanguage } from 'main/services/hooks'
import { usePermissions } from 'main/services/queries/use-permissions'

type HeaderContentProps = {
  permissions: Permissions
  setIsExportModalOpen: (open: boolean) => void
  runbook: Runbook
}

const HeaderContent = ({ permissions, setIsExportModalOpen, runbook }: HeaderContentProps) => {
  const { t } = useLanguage()
  const { isEnabled } = useFeature()
  const permissionsHook = usePermissions('runbook-users')
  const userCanManageGlobal = permissionsHook('manageGlobal')
  const canImportUsers =
    runbook.isIncident && runbook.templateType !== 'default' ? userCanManageGlobal : permissions?.canUpdateRunbook

  useEffect(() => {
    if (!isEnabled('react_runbok')) {
      eventManager.emit('right-panel-content-id-change', { contentId: 'usersAndTeams' })
    }
  }, [])

  const openImportUsersModal = () => {
    // TODO: will not work when react_runbook feature flag is on
    eventManager.emit('open-import-users-modal')
  }

  const openExportUsersModal = () => {
    setIsExportModalOpen(true)
  }

  const menuItems: MenuListItemProps[] = [
    canImportUsers && {
      icon: 'upload' as const,
      label: t('common:importUsersButton:title'),
      onClick: openImportUsersModal
    },
    {
      icon: 'download' as const,
      label: t('common:exportUsersButton:title'),
      onClick: openExportUsersModal
    }
  ].filter(Boolean) as MenuListItemProps[]

  return (
    <Menu
      trigger={
        <IconButton
          tertiary
          disableTooltip
          data-testid="users-teams-more-options"
          label={t('common:moreOptions')}
          icon="more-vertical"
        />
      }
    >
      {menuItems.map(item => (
        <MenuListItem icon={item.icon} label={item.label} key={item.label} onClick={item.onClick} />
      ))}
    </Menu>
  )
}

export type UsersAndTeamsProps = {
  runbook: Runbook
  runbookVersionId: number
  permissions: Permissions
  onClose: () => void
  selectedTab?: string | null
  onTabSelect: (id: string) => void
  selectedUser: SelectedUser | undefined
  onUserSelect: (item: SelectedUser) => void
  selectedTeam: SelectedTeam | undefined
  onTeamSelect: (item: SelectedTeam) => void
  openAddUsersTeamsModal: () => void
  streams?: Stream[]
}

export function UsersAndTeams({
  runbook,
  runbookVersionId,
  permissions,
  onClose,
  selectedTab,
  onTabSelect,
  selectedUser,
  onUserSelect,
  selectedTeam,
  onTeamSelect,
  openAddUsersTeamsModal,
  streams
}: UsersAndTeamsProps) {
  const { t } = useLanguage()

  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [isSearchCleared, setIsSearchCleared] = useState<boolean>(false)
  const [forceReRenderKey, setForceReRenderKey] = useState<number>(0)

  const permissionsHook = usePermissions('runbook-users')
  const userCanManageGlobal = permissionsHook('manageGlobal')

  const canEditPeoplePanel =
    runbook.isIncident && runbook.templateType !== 'default' ? userCanManageGlobal : permissions?.canCreateTeam

  const defaultValues = { users: [] }

  const methods = useForm<{ users: CreatedUserListItemSelectedRole[] }>({
    defaultValues
  })

  const mutation = useRunbookUsersUpdate({
    runbookId: runbook.id,
    runbookVersionId
  })

  const notify = useNotify()

  const onFormSubmit = async (data: any) => {
    await mutation.mutateAsync(data.users, {
      onSuccess: () => notify.success(t('runbook:peoplePanel:users:userRolesUpdated')),
      onError: () => methods.reset(defaultValues)
    })
  }

  const resetForm = () => {
    methods.reset(defaultValues)
    // Due to the way the 'form' is constructed, this is the only way to get 'discard' working without
    // a ton of extra complexity
    setForceReRenderKey(forceReRenderKey + 1)
  }

  const clearSearch = () => {
    setIsSearchCleared(true)
  }

  useEffect(() => {
    if (isSearchCleared) {
      setIsSearchCleared(false)
    }
  }, [search])

  const closeExportUserModal = () => {
    setIsExportModalOpen(false)
  }

  useEffect(() => {
    if (mutation.isError) {
      methods.setError('users.0.role', { message: mutation.error?.errors[0] })
    }
  }, [mutation.isError])

  return (
    <>
      <EditPanel
        title={t('runbook:peoplePanel:title')}
        onClose={onClose}
        headerItems={[
          ...(canEditPeoplePanel
            ? [
                <IconButton
                  icon="add"
                  secondary
                  data-testid="add-users-teams-button"
                  label={t('runbook:addUsersTeams:addButton')}
                  onClick={openAddUsersTeamsModal}
                />
              ]
            : []),
          <HeaderContent permissions={permissions} setIsExportModalOpen={setIsExportModalOpen} runbook={runbook} />
        ]}
        onSubmit={() => methods.handleSubmit(onFormSubmit)()}
        onReset={resetForm}
        isDirty={methods.formState.isDirty}
        isSubmitting={methods.formState.isSubmitting}
      >
        <Box height="100%" css="flex-grow: 1;">
          {methods.formState?.errors?.users?.[0]?.role?.message && (
            <Box margin={{ bottom: '16px' }} css={'flex: 0 0 auto;'}>
              <Message type="error" message={methods.formState?.errors?.users?.[0]?.role?.message} />
            </Box>
          )}

          <SearchBar
            onSearch={setSearch}
            placeholder={t('runbook:peoplePanel:searchFor')}
            open
            width="100%"
            isSearchCleared={isSearchCleared}
            css={'flex: 0 0 auto;'}
          />

          <ErrorBoundary
            FallbackComponent={() => (
              <Box
                css={`
                  margin-top: 8px;
                `}
              >
                <Message type="error" message={[t('runbook:peoplePanel:error')]} />
              </Box>
            )}
          >
            <PeoplePanelTabs
              key={forceReRenderKey}
              runbookId={runbook.id}
              runbookVersionId={runbookVersionId}
              search={search}
              clearSearch={clearSearch}
              selectedTab={selectedTab ?? undefined}
              onTabSelect={onTabSelect}
              selectedUser={selectedUser}
              onUserSelect={onUserSelect}
              selectedTeam={selectedTeam}
              onTeamSelect={onTeamSelect}
              methods={methods}
              streams={streams}
              templateType={runbook.templateType}
            />
          </ErrorBoundary>
        </Box>
      </EditPanel>

      <ExportUsersModal
        isOpen={isExportModalOpen}
        onClose={closeExportUserModal}
        runbookId={runbook.id}
        runbookVersionId={runbookVersionId}
        runbookName={runbook.name}
      />
    </>
  )
}

import { useHotkeys } from 'react-hotkeys-hook'
import { useRecoilCallback } from 'recoil'

import { useSetSelectedIds, useTaskProgression } from 'main/recoil/runbook'

/** @hotkey `meta+a` */
export const useTaskListHotkeys = () => {
  const { selectedIdsSelectAll, selectedIdsRemoveAll, selectedIdsValueCallback } = useSetSelectedIds()
  const { onSkipTasks } = useTaskProgression()

  useHotkeys('meta+a', selectedIdsSelectAll, { preventDefault: true }, [])
  useHotkeys('meta+d', selectedIdsRemoveAll, { preventDefault: true }, [])
  useHotkeys(
    'meta+k',
    useRecoilCallback(() => async () => {
      const selectedTasks = await selectedIdsValueCallback()
      if (selectedTasks.length > 0) onSkipTasks()
    })
  )
}

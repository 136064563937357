import { useCallback, useMemo } from 'react'
import { getTime } from 'date-fns'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, RightPanel } from '@cutover/react-ui'
import { RunbookListItem } from 'main/components/shared/runbook-list-item'
import { useRunbookEditPanel, useRunbookLinkedPanel } from 'main/context/panel-context'
import { useLanguage } from 'main/services/hooks'
import { RunbookEditRunbook } from 'main/services/queries/types'
import { useRouting } from 'main/services/routing/hooks'

export const RunbookLinkedPanel = () => {
  const { runbook, panel } = useRunbookLinkedPanel()

  return panel?.panel === 'runbook-linked' && runbook ? <RunbookLinked runbook={runbook} /> : null
}

const RunbookLinked = ({ runbook }: { runbook: RunbookEditRunbook }) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'linkedRunbooksPanel' })
  const { clearPanel } = useRunbookLinkedPanel()
  const { openPanel: openEditPanel } = useRunbookEditPanel()
  const { toRunbook } = useRouting()

  const linkedRunbook = useMemo(() => {
    const linked = runbook.linked_runbook_details?.linked_resource
    if (linked) {
      return {
        ...linked,
        start_display: getTime(new Date(linked.start_display)),
        end_display: getTime(new Date(linked.start_display))
      }
    }
    return null
  }, [runbook])

  const { accountId: accountSlug } = useParams<{ accountId: string }>()
  const navigate = useNavigate()

  const createLinkTo = useCallback(
    (id: number) => toRunbook({ accountSlug: accountSlug as string, runbookId: id }),
    [accountSlug]
  )

  return (
    <RightPanel
      title={t('title')}
      onClose={clearPanel}
      onBack={() => openEditPanel({ runbookId: runbook.id })}
      footer={
        <Button
          onClick={() => navigate(createLinkTo(runbook.id))}
          label={t('footerButton')}
          icon="arrow-forward"
          primary
          full
        />
      }
    >
      <>
        {linkedRunbook && (
          <RunbookListItem key={linkedRunbook.id} runbook={linkedRunbook} createLinkTo={createLinkTo} />
        )}
      </>
    </RightPanel>
  )
}

import { useCallback, useMemo, useState } from 'react'
import { unescape } from 'lodash'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { IconButton, LoadingPanel } from '@cutover/react-ui'
import { FolderArchiveModal } from '../modals/folder-archive-modal'
import { useFilters, useSetFilterParams } from 'main/components/shared/filter/filter-provider'
import { FormEditPanel, SelectField, TextInputField, UserSelectField } from 'main/components/shared/form'
import { useEditFolderPanel } from 'main/context/panel-context'
import { useAccount } from 'main/services/api/data-providers/account/account-data'
import { Account, FolderEditFolderPayload, FolderGetFolder, FolderListFolder } from 'main/services/queries/types'
import { useFolder, useFolderEdit } from 'main/services/queries/use-folders'

export const FolderEditPanel = () => {
  const { folderId, clearPanel, folders } = useEditFolderPanel()

  if (!folderId) return null
  return <FolderEditPanelWrapper folderId={folderId} onClose={clearPanel} folders={folders} />
}

type FolderEditPanelWrapperProps = {
  folderId: number
  folders?: FolderListFolder[]
  onClose: () => void
}

const FolderEditPanelWrapper = ({ folderId, ...folderEditProps }: FolderEditPanelWrapperProps) => {
  const { data, isLoading, isError } = useFolder(folderId)
  const { account } = useAccount()

  if (!account || isError || isLoading || !data) return <LoadingPanel />
  return <FolderEdit account={account} folder={data.project} {...folderEditProps} />
}

type FolderEditFormType = yup.InferType<typeof validationSchema>

type FolderEditProps = {
  folders?: FolderListFolder[]
  folder: FolderGetFolder
  onClose: () => void
  account: Account
}

export const FolderEdit = ({ folder, folders = [], onClose, account }: FolderEditProps) => {
  const { t } = useTranslation('runbooks', { keyPrefix: 'folder.editPanel' })
  const { filters } = useFilters()
  const setFilterParams = useSetFilterParams()

  const [showArchiveModal, setShowArchiveModal] = useState(false)

  const { mutateAsync } = useFolderEdit(folder.id)
  const handleSubmit = async (data: FolderEditFolderPayload) => mutateAsync(data)

  const canEdit = useMemo(() => !!folder?.permissions?.['update']?.[0], [folder])
  const canDelete = useMemo(() => !!folder?.permissions?.['destroy']?.[0], [folder])

  const defaultValues = { ...(folder ?? {}) }

  const handleArchive = useCallback(() => {
    const currentProjectIdIndex = Array.isArray(filters?.project) && filters.project.findIndex(id => id === folder.id)
    if (currentProjectIdIndex === false) return onClose()

    // Clear current id from browser params
    const newProjects = [...((filters?.project as string[]) || [])]
    newProjects.splice(currentProjectIdIndex, 1)
    setFilterParams({ project: newProjects })
    onClose()
  }, [filters.project, onClose, setFilterParams, folder.id])

  const { parentFolders, subFolders } = useMemo(() => {
    return (folders || []).reduce<{
      parentFolders: FolderListFolder[]
      subFolders: FolderListFolder[]
    }>(
      (acc, f) => {
        if (f.id === folder.id) return { ...acc }
        acc[f.parent_id ? 'subFolders' : 'parentFolders'].push(f)
        return acc
      },
      { parentFolders: [], subFolders: [] }
    )
  }, [folders, folder])

  const canChangeParentFolder = useMemo(() => {
    if (folder.parent_id) return true
    return !subFolders.find(subFolder => subFolder.parent_id === folder.id)
  }, [folder, subFolders])

  const parentFolderOptions = useMemo(() => {
    return canChangeParentFolder ? parentFolders.map(({ name, id }) => ({ label: unescape(name), value: id })) : []
  }, [parentFolders, canChangeParentFolder])

  const orderedRoleTypes = useMemo(
    () => (folder.role_types || []).sort((a, b) => a.order - b.order),
    [folder.role_types]
  )

  return (
    <>
      <FolderArchiveModal
        open={showArchiveModal}
        setOpen={setShowArchiveModal}
        folderId={folder.id}
        folderName={folder.name}
        onSuccess={handleArchive}
        folders={folders}
        hasRunbooks={!!folder.runbooks_count}
      />
      <FormEditPanel<FolderEditFormType, FolderEditFolderPayload>
        onClose={onClose}
        onSubmit={handleSubmit}
        transformer={dataTransformer}
        defaultValues={defaultValues}
        successMessage={t('successMessage')}
        readOnly={!canEdit}
        disabled={!canEdit}
        headerItems={
          canDelete
            ? [
                <IconButton
                  label="Archive"
                  tipPlacement="top"
                  icon="trash-o"
                  onClick={() => setShowArchiveModal(true)}
                />
              ]
            : undefined
        }
        title={t('title')}
        schema={validationSchema}
      >
        <TextInputField<FolderEditFormType> name="name" label={t('nameLabel')} autoFocus />
        {canChangeParentFolder && (
          <SelectField<FolderEditFormType>
            name="parent_id"
            options={parentFolderOptions}
            label={t('parentFolderLabel')}
            data-testid="workspace-parent-folder-select"
            placeholder={t('parentFolderPlaceholder')}
          />
        )}
        <TextInputField<FolderEditFormType> name="description" label={t('descriptionLabel')} />
        {orderedRoleTypes.map((roleType, index) => {
          return (
            <UserSelectField<FolderEditFormType>
              data-testid={`role-type-${roleType.id}`}
              key={`role_types.${roleType.id}`}
              name={`role_types.${index}.users`}
              accountId={account.id}
              defaultValue={roleType.users}
              label={roleType.name}
              helpText={roleType.description || undefined}
              // TODO: fix without hard coding required state https://cutover.atlassian.net/browse/CFE-1578
              required={false}
            />
          )
        })}
      </FormEditPanel>
    </>
  )
}

const dataTransformer = (data: FolderEditFormType): FolderEditFolderPayload => {
  const { role_types, ...form } = data
  return {
    ...form,
    roles:
      role_types
        ?.map(role =>
          (role.users || []).map(user => ({
            id: user.role_id,
            resource_id: form.id,
            resource_type: 'Project',
            role_type_id: role.id,
            subject_id: user.id,
            subject_type: 'User'
          }))
        )
        .flat() ?? []
  }
}

const validationSchema = yup.object({
  id: yup.number().required(),
  account_id: yup.number().required(),
  description: yup.string().nullable().notRequired(),
  name: yup.string().required(),
  parent_id: yup.number().nullable().notRequired(),
  role_types: yup.array().of(
    yup.object({
      id: yup.number().required(),
      users: yup.array().of(
        yup.object({
          role_id: yup.number(),
          id: yup.number().required()
        })
      )
    })
  )
})

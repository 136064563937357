import { RunbookIntegrationsPanelConnector } from './runbook-integrations-panel-connector'
import { ConnectionLostModal } from 'main/components/error/connection-lost-modal'
import { ActivitiesPageConnector } from 'main/connectors/activities-page-connector'
import { AppsConnector, HeaderAppConnector, MobileHeaderAppConnector } from 'main/connectors/apps-connector'
import { PeoplePanelConnector } from 'main/connectors/people-panel-connector'
import { RunbookDashboardSchedulePanelConnector } from 'main/connectors/runbook-dashboard-schedule-panel-connector'
import { RunbooksDashboardPageConnector } from 'main/connectors/runbooks-dashboard-page-connector'
import { RunbooksTimelineConnector } from 'main/connectors/runbooks-timeline-connector'
import { useFeature } from 'main/services/hooks'

export const ReactAppConnectors = () => {
  const { isEnabled } = useFeature()
  const reactWorkspace = isEnabled('react_workspace')

  return (
    <>
      <PeoplePanelConnector />
      <RunbookIntegrationsPanelConnector />
      <RunbookDashboardSchedulePanelConnector />
      <ActivitiesPageConnector />
      <AppsConnector />
      <HeaderAppConnector />
      <MobileHeaderAppConnector />
      {!reactWorkspace && (
        <>
          <RunbooksDashboardPageConnector />
          <RunbooksTimelineConnector />
        </>
      )}
      <ConnectionLostModal />
    </>
  )
}

import { useState } from 'react'

import { DuplicateRunbooksModal } from 'main/components/workspace/modals/duplicate-runbook/duplicate-runbooks-modal'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

type DuplicateSnippetsState = {
  runbook: { id: number; name: string } | null
  runbookIds: number[] | null
}

export const DuplicateSnippetsConnector = () => {
  const [state, setState] = useState<DuplicateSnippetsState>({
    runbook: null,
    runbookIds: null
  })

  const handleOnMount = ({ runbook, runbookIds }: DuplicateSnippetsState) => {
    setState({ runbook, runbookIds })
  }

  const handleOnUnmount = () => {
    setState({ runbook: null, runbookIds: null })
  }

  return (
    <ReactAngularConnector
      mountEvent="open-duplicate-snippets-modal"
      unmountEvent="close-duplicate-snippets-modal"
      onMount={handleOnMount}
      onUnmount={handleOnUnmount}
    >
      {state.runbook ? (
        <DuplicateRunbooksModal
          runbook={state.runbook}
          open={true}
          runbookIds={null}
          templateType={'snippet'}
          fromAngular={true}
        />
      ) : state.runbookIds && state.runbookIds.length > 0 ? (
        <DuplicateRunbooksModal
          runbook={state.runbook}
          open={true}
          runbookIds={state.runbookIds}
          templateType={'snippet'}
          fromAngular={true}
        />
      ) : null}
    </ReactAngularConnector>
  )
}

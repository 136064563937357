import { keyBy, mapValues } from 'lodash'
import { selector, selectorFamily } from 'recoil'

import { runbookVersionMetaState } from './runbook-version'
import { RunbookVersionUser } from 'main/services/queries/use-runbook-versions'
import { filteredTasksState } from '../tasks/task-list'
import { teamsState } from './teams'
import { RunbookTeam } from 'main/services/queries/types'

export const usersState = selector<RunbookVersionUser[]>({
  key: 'users:list',
  get: ({ get }) => {
    return get(runbookVersionMetaState).users
  }
})

export const usersLookupState = selector<Record<RunbookVersionUser['id'], RunbookVersionUser>>({
  key: 'users:lookup',
  get: ({ get }) => {
    return keyBy(get(usersState), 'id')
  }
})

export const userState = selectorFamily<RunbookVersionUser, { id: number }>({
  key: 'users:id',
  get:
    ({ id }) =>
    ({ get }) => {
      return get(usersLookupState)[id]
    }
})

export const userTasksCountState = selector<Record<number, number>>({
  key: 'users:tasks:count',
  get: ({ get }) => {
    const usersLookup = get(usersLookupState)
    const filteredTasks = get(filteredTasksState)

    return mapValues(usersLookup, user => {
      return filteredTasks.filter(task => task.user_ids?.includes(user.id)).length
    })
  }
})

export const userIdToTeamLookup = selector({
  key: 'users:user-team-lookup',
  get: ({ get }) => {
    const runbookTeams = get(teamsState)
    return runbookTeams.reduce<{ [key: string]: RunbookTeam }>((acc, team) => {
      team.user_ids.forEach(userId => {
        if (!acc[userId]) {
          acc[userId] = team
        }
      })

      return acc
    }, {})
  }
})

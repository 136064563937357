import { useEffect, useState } from 'react'

import { SearchBar, useInitialMount } from '@cutover/react-ui'
import { usePostAppEvents } from '../apps-api'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type SearchBarNodeProps = AppComponentNodeProps & {
  name: string
  value?: string
}

const COLLAPSED_WIDTH = 50
const EXPANDED_WIDTH = 150

export const SearchBarNode = ({ appId, resourceId, id, ...props }: SearchBarNodeProps) => {
  const { componentProps, state, setState } = useComponentPropsAndState(appId, resourceId, id, props)
  const { meta = {}, name, value = '' } = componentProps as SearchBarNodeProps

  const [searchValue, setSearchValue] = useState(value)
  const [open, setOpen] = useState(!!value)
  const [searchBarwidth, setSearchBarWidth] = useState<number>(!!value ? EXPANDED_WIDTH : COLLAPSED_WIDTH)

  const isInitialMount = useInitialMount()
  const postAppEvents = usePostAppEvents()

  useEffect(() => {
    if (isInitialMount) {
      setState({ name, value: value })
    } else {
      setState({ name, value: searchValue })
      postSearchBarEvent()
    }

    if (searchValue === undefined || searchValue === '') {
      setSearchBarWidth(COLLAPSED_WIDTH)
      setOpen(false)
    }
  }, [searchValue])

  const handleFocus = () => {
    setSearchBarWidth(EXPANDED_WIDTH)
  }

  const onSearch = (value: string) => {
    if (!isInitialMount) setSearchValue(value)
  }

  const postSearchBarEvent = () => {
    const payload = {
      app_id: appId,
      runbook_id: resourceId,
      events: [{ type: 'search_bar', name, id, meta }],
      state: { ...state, [name]: searchValue }
    }
    postAppEvents(payload)
  }

  return (
    <SearchBar
      onFocus={handleFocus}
      onSearch={onSearch}
      width={searchBarwidth}
      open={open}
      value={searchValue}
      placeholder={name}
    />
  )
}

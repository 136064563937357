import {
  atom,
  AtomOptions,
  ReadOnlySelectorOptions,
  ReadWriteSelectorOptions,
  RecoilState,
  RecoilValueReadOnly,
  selector
} from 'recoil'

type EqualsFunction<T> = (prev: T, next: T) => boolean
type EqualAtomOptions<T> = AtomOptions<T> & { equals: EqualsFunction<T> }

type ReadOnlyEqualsSelectorOptions<T> = ReadOnlySelectorOptions<T> & { set?: undefined; equals?: EqualsFunction<T> }
type ReadWriteEqualsSelectorOptions<T> = ReadWriteSelectorOptions<T> & { equals?: EqualsFunction<T> }
type EqualSelectorOptions<T> = ReadOnlyEqualsSelectorOptions<T> | ReadWriteEqualsSelectorOptions<T>

const DEFAULT_COMPARE_FUNCTION = <T>(a: T, b: T) => JSON.stringify(a) === JSON.stringify(b)

/**
 * Use a writable selector to prevent excess renders arising from referential inequality.
 * If the setting value is equal to the current value, don't change anything.
 */
export function equalAtom<T>({ equals = DEFAULT_COMPARE_FUNCTION, ...options }: EqualAtomOptions<T>): RecoilState<T> {
  const { key, ...innerOptions } = options
  const inner = atom({
    key: `${key}_inner`,
    ...innerOptions
  })

  return selector({
    key,
    get: ({ get }) => get(inner),
    set: ({ get, set }, newValue) => {
      const current = get(inner)
      if (!equals(current, newValue as T)) {
        set(inner, newValue)
      }
    }
  })
}

export function equalSelector<T>(options: ReadOnlyEqualsSelectorOptions<T>): RecoilValueReadOnly<T>
export function equalSelector<T>(options: ReadWriteEqualsSelectorOptions<T>): RecoilState<T>
export function equalSelector<T>({ equals = DEFAULT_COMPARE_FUNCTION, set, ...options }: EqualSelectorOptions<T>) {
  const { get, ...restOptions } = options

  const inner = selector({
    key: `${restOptions.key}_inner`,
    get
  })

  let prior: T | undefined

  const getFunction: ReadOnlyEqualsSelectorOptions<T>['get'] = ({ get }) => {
    const latest = get(inner)

    if (prior != null && equals(prior, latest)) {
      return prior
    }

    prior = latest
    return latest as T
  }

  if (set) {
    return selector({
      ...restOptions,
      get: getFunction,
      set
    })
  } else {
    return selector({
      ...restOptions,
      get: getFunction
    })
  }
}

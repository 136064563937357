import { atom, AtomEffect, DefaultValue, selector, useRecoilValue } from 'recoil'

import { getAccountSlug } from 'main/recoil/shared/nav-utils'
import {
  AccountResponseMeta,
  fetchAccountQuery as getAccount,
  GetAccountResponseType
} from 'main/services/api/data-providers/account/use-get-account-data'
import { Account } from 'main/services/queries/types'

// *******************************
// Account api response
// *******************************

const syncAccountResponseEffect: AtomEffect<GetAccountResponseType> = ({ setSelf, resetSelf }) => {
  const getInitialAccount = async () => {
    const accountSlug = getAccountSlug()

    if (accountSlug) {
      const data = await getAccount(accountSlug)
      setSelf(data)
    } else {
      return new DefaultValue()
    }
  }

  getInitialAccount()

  const handlePathChange = async (event: CustomEvent) => {
    const { pathname, previousPathname } = event.detail
    const previousAccountSlug = getAccountSlug(previousPathname)
    const accountSlug = getAccountSlug(pathname)

    if (accountSlug && previousAccountSlug === accountSlug) return

    resetSelf()

    if (accountSlug) {
      // TODO: investigate - should swallow canceled errors probably, like rb and rbv, but we use the
      // query client for this so the response type is slightly different.
      const data = await getAccount(accountSlug)
      setSelf(data)
    }
  }

  window.addEventListener('pathnamechanged', handlePathChange as any)

  return () => {
    window.removeEventListener('pathnamechanged', handlePathChange as any)
  }
}

export const accountResponseState_INTERNAL = atom<GetAccountResponseType>({
  key: 'account:response',
  effects: [syncAccountResponseEffect]
})

// *******************************
// Account
// *******************************

export const accountState = selector({
  key: 'account',
  get: ({ get }) => {
    const { account } = get(accountResponseState_INTERNAL)
    return account
  }
})

// Use this hook in components to avoid re-renders when non-dependent properties change
export const useAccountProperty = <TKey extends keyof Account>({ attribute }: { attribute: TKey }) =>
  useRecoilValue(accountState)[attribute]

// *******************************
// Account meta
// *******************************

export const accountMetaState = selector({
  key: 'account:meta',
  get: ({ get }) => {
    const { meta } = get(accountResponseState_INTERNAL)
    return meta
  }
})

// Use this hook in components to avoid re-renders when non-dependent properties change
export const useAccountMetaProperty = <TKey extends keyof AccountResponseMeta>({ attribute }: { attribute: TKey }) =>
  useRecoilValue(accountMetaState)[attribute]

export const useAccountId = () => {
  return useAccountProperty({ attribute: 'id' })
}

import { createContext, ReactNode } from 'react'

import { LoadingPage } from 'main/components/authentication/loading-page'
import { GlobalConfigType } from 'main/services/hooks'
import { useGetConfigs } from 'main/services/queries/use-get-configs'

export const GlobalConfigContext = createContext<GlobalConfigType>({} as unknown as GlobalConfigType)

type GlobalConfigProviderProps = {
  children: ReactNode
  config?: GlobalConfigType
}

export const GlobalConfigProvider = (props: GlobalConfigProviderProps) => {
  const { data, isLoading, isError } = useGetConfigs({ enabled: !props.config })
  const config = data || props.config

  return isLoading ? (
    <LoadingPage />
  ) : isError ? (
    <h1>Something went wrong.</h1>
  ) : config ? (
    <GlobalConfigContext.Provider value={config}>{props.children}</GlobalConfigContext.Provider>
  ) : null
}

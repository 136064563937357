import { useEffect, useMemo, useState } from 'react'

import { colors, LegacyFlex as Flex, Icon, IconName, Link, LegacyText as Text } from '@cutover/react-ui'
import { LinkTemplateSelect } from './link-template-select'
import { useLanguage } from 'main/services/hooks'
import { RunbookTemplateRunbook as Template } from 'main/services/queries/types'

export const LinkTemplateMultiSelect = ({
  isLoading,
  onChange,
  templates = [],
  selected = [],
  filteredTemplates = [],
  onFilterClear,
  isFiltered
}: {
  isLoading?: boolean
  onChange: (value: number[]) => void
  templates?: Template[]
  filteredTemplates?: Template[]
  selected?: number[]
  onFilterClear: () => void
  isFiltered?: boolean
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'linkTemplateModal' })
  const [hoverIndex, setHoverIndex] = useState<null | number>(null)
  const [highlightedUnselected, setHighlightedUnselected] = useState<number[]>([]) // the top box
  const [highlightedSelected, setHighlightedSelected] = useState<number[]>([]) // the bottom box

  useEffect(() => {
    if (highlightedUnselected.length > 0) setHighlightedUnselected([])
  }, [filteredTemplates])

  useEffect(() => {
    if (selected.length === filteredTemplates.length && highlightedUnselected.length > 0) setHighlightedUnselected([])
  }, [selected])

  const runbookLookup = useMemo(
    () =>
      templates.reduce<{ [key: Template['id']]: Template }>((acc, runbook) => {
        acc[runbook.id] = runbook
        return acc
      }, {}),
    [templates]
  )

  const { selectedTemplates, unselectedTemplates } = useMemo(() => {
    const selectedTemplates = selected.map(id => runbookLookup[id])
    const unselectedTemplates = filteredTemplates.filter(runbook => !selected.includes(runbook.id))
    return { selectedTemplates, unselectedTemplates }
  }, [runbookLookup, filteredTemplates, selected])

  const handleAddSelected = () => {
    onChange([
      ...selected,
      ...templates.reduce<number[]>((acc, runbook) => {
        if (highlightedUnselected.includes(runbook.id)) return [...acc, runbook.id]
        return acc
      }, [])
    ])
    setHighlightedUnselected([])
  }

  const handleRemoveSelected = () => {
    onChange(selected.filter(id => !highlightedSelected.includes(id)))
    setHighlightedSelected([])
  }

  const onRemoveAll = () => {
    onChange([])
    setHighlightedSelected([])
  }

  const getNewHighlighted = (id: number, highlighted: number[]) => {
    const idIndex = highlighted.findIndex(option => option === id)
    return idIndex === -1 ? [id, ...highlighted] : [...highlighted].filter(highlight => highlight !== id)
  }

  const getIcon = (template: Template, selected: number[]) =>
    selected.includes(template.id) || hoverIndex === template.id
      ? selected.includes(template.id)
        ? 'checkbox-checked'
        : 'checkbox'
      : undefined

  return (
    <Flex flexDirection="column" gap={12}>
      <LinkTemplateSelect
        id="link-template-modal-multiselect-unselected"
        data-testid="link-template-modal-multiselect-unselected"
        iconPrefix={template => getIcon(template, highlightedUnselected)}
        noMatches={isFiltered && unselectedTemplates.length === 0}
        isEmpty={templates.length === 0}
        isLoading={isLoading}
        onClick={(_, template) => setHighlightedUnselected(getNewHighlighted(template.id, highlightedUnselected))}
        onMouseEnter={(_, template) => setHoverIndex(template.id)}
        onMouseLeave={() => setHoverIndex(null)}
        templates={unselectedTemplates}
        selected={highlightedUnselected}
        onFilterClear={onFilterClear}
      />
      <Flex justifyContent="space-between" alignContent="center" css="padding: 0 12px">
        <SelectButton text={t('addSelected')} onClick={handleAddSelected} icon="chevron-down" />
        <SelectButton text={t('removeSelected')} onClick={handleRemoveSelected} icon="chevron-up" />
        <Link underline="always" color={colors.primary} colorHover={colors.primaryLight} onClick={onRemoveAll}>
          <Text size={14}>{t('removeAll')}</Text>
        </Link>
      </Flex>
      <LinkTemplateSelect
        id="link-template-modal-multiselect-selected"
        data-testid="link-template-modal-multiselect-selected"
        iconPrefix={template => getIcon(template, highlightedSelected)}
        onClick={(_, template) => setHighlightedSelected(getNewHighlighted(template.id, highlightedSelected))}
        onMouseEnter={(_, template) => setHoverIndex(template.id)}
        onMouseLeave={() => setHoverIndex(null)}
        templates={selectedTemplates}
        selected={highlightedSelected}
        isLoading={false}
      />
    </Flex>
  )
}

const SelectButton = ({ onClick, icon, text }: { onClick: () => void; icon: IconName; text: string }) => (
  <Link
    underline="none"
    onClick={onClick}
    color={colors.textLighter}
    colorHover={colors.textLightest}
    css="margin-top: 3px"
  >
    <Flex alignItems="center" gap={2}>
      <Icon icon={icon} color="text-light" size="small" />
      <Text size={14}>{text}</Text>
    </Flex>
  </Link>
)

import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { EditIntegrationSidebarPanel } from './edit-integration-sidebar-panel'
import { useIntegrationSettingEditPanel } from 'main/context/panel-context'
import { IntegrationSetting } from 'main/services/queries/types'
import { useIntegrationSettingsQuery } from 'main/services/queries/use-integration-settings'

export const IntegrationSettingsEditPanel = () => {
  const { integrationSettingId, clearPanel } = useIntegrationSettingEditPanel()
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get('setting_id')
  const settingsId = integrationSettingId || (id ? parseInt(id) : null)

  return (
    <>
      {settingsId ? (
        <IntegrationSettingsEdit
          integrationSettingId={settingsId}
          onClose={() => {
            clearPanel()
            setSearchParams({})
          }}
          key={settingsId}
        />
      ) : null}
    </>
  )
}

type IntegrationSettingsEditType = {
  integrationSettingId: number
  onClose: () => void
}

const IntegrationSettingsEdit = ({ integrationSettingId, onClose }: IntegrationSettingsEditType) => {
  const [_searchParams, setSearchParams] = useSearchParams()
  const { data } = useIntegrationSettingsQuery()

  const settings = useMemo(
    () => data?.integration_settings.sort((a, b) => a.id - b.id).filter(e => !e.archived),
    [data]
  )
  const [selectedSetting, setSelectedSetting] = useState<IntegrationSetting | undefined>()

  useEffect(() => {
    setSelectedSetting(settings?.find((setting: IntegrationSetting) => setting.id === integrationSettingId))
  }, [integrationSettingId, settings])

  return (
    <>
      {selectedSetting ? (
        <EditIntegrationSidebarPanel
          integrationSetting={selectedSetting}
          onClose={onClose}
          onAfterClose={() => setSearchParams({})}
        />
      ) : undefined}
    </>
  )
}

import { ReactNode } from 'react'
import { EdgeSizeType } from 'grommet/utils'

import { Box, breakpoints } from '@cutover/react-ui'
import { useComponentProps } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type EdgeType = EdgeSizeType | 'none' | string

type FlexRowNodeProps = AppComponentNodeProps & {
  children: ReactNode
  align: 'left' | 'right' | 'fill'
  gap: EdgeType
  pad: EdgeType
}

export const FlexRowNode = ({ appId, resourceId, id, ...props }: FlexRowNodeProps) => {
  const {
    children,
    align = 'fill',
    gap = 'small',
    pad = 'small'
  } = useComponentProps(appId, resourceId, id, props) as FlexRowNodeProps

  return (
    <Box
      direction="row"
      align="center"
      gap={gap}
      pad={pad}
      width="100%"
      style={{ position: 'relative' }}
      css={`
        @media ${breakpoints.sm} {
          flex-flow: row wrap;
          gap: 16px;
        }
      `}
    >
      {align === 'right' && <Box css={`flex 1 1 auto !important; @media ${breakpoints.sm} { display: none; }`} />}
      {children}
      {align === 'left' && <Box css={'flex 1 1 auto !important'} />}
    </Box>
  )
}

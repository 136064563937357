import { useState } from 'react'

import { RunbookActivityPage } from '../components/activities/runbook-activity-page'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

export const ActivitiesPageConnector = () => {
  const [runbookId, setRunbookId] = useState<number | null>(null)
  const [currentUserId, setCurrentUserId] = useState<number | null>(null)
  const [incidentId, setIncidentId] = useState<number | null>(null)
  const [timeZone, setTimeZone] = useState('')

  return (
    <ReactAngularConnector
      mountEvent="show-runbook-activities"
      unmountEvent="hide-runbook-activities"
      onMount={({ runbookId, currentUserId, incidentId, timeZone }) => {
        setRunbookId(runbookId)
        setCurrentUserId(currentUserId)
        setIncidentId(incidentId)
        setTimeZone(timeZone)
      }}
    >
      {runbookId && currentUserId && incidentId && (
        <RunbookActivityPage
          timeZone={timeZone}
          incidentId={incidentId}
          runbookId={runbookId}
          currentUserId={currentUserId}
        />
      )}
    </ReactAngularConnector>
  )
}

import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import Cookies from 'js-cookie'
import { useForm, useFormState } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

import { Box, Button, colors, Form, Heading, Message, TextInput } from '@cutover/react-ui'
import { AuthenticationFormWrapper } from './authentication-form-wrapper'
import { CutoverLogo, FooterLinkText, PageWrap } from './login-styled'
import { VerifyActiveSession } from './verify-active-session'
import { useHeap } from 'main/components/support-and-analytics/use-heap'
import { useLanguage } from 'main/services/hooks'
import { PasswordResetPayload, usePasswordReset } from 'main/services/queries/use-password-reset'

export const PasswordResetPage = () => {
  const navigate = useNavigate()
  const { t } = useLanguage('authentication')
  const { initializeAnonymousAnalytics } = useHeap()
  const [serverErrors, setServerErrors] = useState<string[] | undefined>()
  const [urlSearchParams] = useSearchParams()

  const navigateToLogin = () => {
    navigate('/login', { replace: true })
  }

  const { register, handleSubmit, control } = useForm<PasswordResetPayload>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      yup.object({
        password: yup.string().min(8).required(),
        password_confirmation: yup
          .string()
          .min(8)
          .required()
          .oneOf([yup.ref('password')])
      })
    )
  })
  const { errors, isSubmitted } = useFormState({ control })
  const resetPassword = usePasswordReset({
    onError: ({ status, errors }) => {
      if (status === 401) navigateToLogin()

      setServerErrors(errors)
    },
    onSuccess: () => {
      Cookies.remove('auth_headers')
      localStorage.setItem('resetPasswordSuccess', 'true')
      navigateToLogin()
    }
  })

  const onSubmit = (form: PasswordResetPayload) => {
    resetPassword.mutate(form)
  }

  const serverErrorMarkdown = () => {
    if (!serverErrors) return
    if (serverErrors.length === 1) return serverErrors[0]
    return `<strong>${t('errorsFound', { amount: serverErrors.length })}</strong><ul>
      ${serverErrors.map(err => `\n<li style="list-style-type:circle;margin-left:19px;">${err}</li>`).join('')}</ul>`
  }

  useEffect(() => {
    if (!serverErrors) return

    if (errors.password || errors.password_confirmation) setServerErrors(undefined)
  }, [errors])

  useEffect(() => {
    if (urlSearchParams.get('reset_password')) {
      initializeAnonymousAnalytics()
    } else {
      navigateToLogin()
    }
  }, [])

  return (
    <VerifyActiveSession>
      <PageWrap>
        <CutoverLogo src="/branding/cutover/cutover-logo.png" alt="Cutover" />
        <Box css="min-height: auto; padding-bottom: 30px;">
          <Heading
            as="h1"
            color={colors.white}
            css={`
              margin: 20px 0px 24px;
              text-align: center;
            `}
            weight={300}
          >
            {t('resetPasswordHeading')}
          </Heading>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <AuthenticationFormWrapper>
              <Box gap="medium" direction="column" width="320px">
                {(errors.password || errors.password_confirmation) && isSubmitted ? (
                  <Message type="error" message="The form is invalid. Please correct the fields in red and try again" />
                ) : serverErrors ? (
                  <Message type="error" message={serverErrorMarkdown()} />
                ) : null}
                <TextInput
                  {...register('password')}
                  required
                  label="New password"
                  type="password"
                  hasError={!!errors.password}
                  inlineError={!!errors.password ? 'Minimum 8 characters' : undefined}
                />
                <TextInput
                  {...register('password_confirmation')}
                  required
                  label="Confirm new password"
                  type="password"
                  hasError={!!errors.password_confirmation}
                  inlineError={!!errors.password_confirmation ? 'Must match password' : undefined}
                />
                <Message type="idea" message={`**${t('note')}:** ${t('passwordRequirements')}`} />
              </Box>
              <Box pad="small">
                <Button primary full type="submit" label={t('submit')} icon="arrow-forward" />
              </Box>
            </AuthenticationFormWrapper>
          </Form>
          <Box alignSelf="center" margin={{ top: '12px' }}>
            <FooterLinkText onClick={navigateToLogin}>{t('returnToLogin')}</FooterLinkText>
          </Box>
        </Box>
      </PageWrap>
    </VerifyActiveSession>
  )
}

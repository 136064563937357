import { useState } from 'react'
import { useMount } from 'react-use'
import { v4 as uuidv4 } from 'uuid'

import { Box, Menu, MenuListItem, resolveColor, useTheme } from '@cutover/react-ui'
import { usePostAppEvents } from '../apps-api'
import { AppsComponentParser } from '../apps-component-parser'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps, ContentNode, ContentNodeOption } from '../apps-types'

type MenuNodeProps = AppComponentNodeProps & {
  node: ContentNode
  options: ContentNodeOption[]
  keepOpen?: boolean
}

export const MenuNode = ({ appId, resourceId, id, ...props }: MenuNodeProps) => {
  const { componentProps, state, setState } = useComponentPropsAndState(appId, resourceId, id, props)
  const { options, node, keepOpen = false } = componentProps as unknown as MenuNodeProps

  const theme = useTheme()
  const postAppEvents = usePostAppEvents()
  const [selected, setSelected] = useState<ContentNodeOption['value'][]>([])
  const context = `${resourceId}-${appId}`
  const name = node.id || `menu-node-${context}`

  const openInNewTab = (href: string) => {
    window.open(href, '_blank', 'noreferrer')
  }

  const handleInputValueChange = (value: string, option: ContentNodeOption) => {
    const newSelection = new Set(selected)
    if (newSelection.has(value)) {
      newSelection.delete(value)
    } else {
      newSelection.add(value)
    }
    const arrayValue = Array.from(newSelection)
    setSelected(arrayValue)
    setState({ name, value: arrayValue })

    if (option.href) {
      openInNewTab(option.href)
    }

    const selectedOptions = options.filter(opt => newSelection.has(opt.value))
    const payload = {
      app_id: appId,
      runbook_id: resourceId,
      events: selectedOptions as ContentNodeOption[]
    }
    postAppEvents(payload)
  }

  useMount(() => {
    if (state && state[name]) {
      setSelected(state[name] as string[])
    }
  })

  return (
    <Menu
      trigger={
        <Box
          data-testid="menu-node-box"
          onClick={e => e.stopPropagation()}
          css={`
            min-width: auto;
            min-height: auto;
            ${selected.length > 0 &&
            `
            button svg {
              fill: ${resolveColor('primary', theme)}; 
            }
            `}
          `}
        >
          <AppsComponentParser content={node.content || []} appId={appId} resourceId={resourceId} />
        </Box>
      }
      keepOpen={keepOpen}
    >
      {options.map(option => (
        <Box key={`${node.type}-${uuidv4()}`} onClick={e => e.stopPropagation()}>
          <MenuListItem
            icon={option.icon}
            label={option.label}
            value={option.value}
            selected={selected.includes(option.value)}
            iconColorProp={option.iconColor}
            onClick={({ value }) => handleInputValueChange(value, option)}
            type={option.icon === 'checkbox' ? 'checkbox' : undefined}
            checked={selected.includes(option.value)}
          />
        </Box>
      ))}
    </Menu>
  )
}

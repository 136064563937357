import { RecoilValueReadOnly, SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil'

import { filterSelector } from './filter-state'
import { RunbookFilterType } from 'main/services/tasks/filtering'

const runbookLevelFilterState = filterSelector({ attribute: 'lv' }) as RecoilValueReadOnly<RunbookFilterType['lv']>
export const useRunbookLevelFilter = createFilterHook('lv', runbookLevelFilterState)

const runbookUserFilterState = filterSelector({ attribute: 'user' }) as RecoilValueReadOnly<RunbookFilterType['user']>
export const useRunbookUserFilter = createFilterHook('user', runbookUserFilterState)

const runbookTeamFilterState = filterSelector({ attribute: 'team' }) as RecoilValueReadOnly<RunbookFilterType['team']>
export const useRunbookTeamFilter = createFilterHook('team', runbookTeamFilterState)

const runbookTeamIncludeUsersState = filterSelector({ attribute: 'includeUsers' }) as RecoilValueReadOnly<
  RunbookFilterType['includeUsers']
>
export const useRunbookTeamIncludeUsersFilter = createFilterHook('includeUsers', runbookTeamIncludeUsersState)

const criticalPathFilterState = filterSelector({ attribute: 'critical' }) as RecoilValueReadOnly<
  RunbookFilterType['critical']
>
export const useCriticalPathFilter = createFilterHook('critical', criticalPathFilterState)

const milestoneFilterState = filterSelector({ attribute: 'm' }) as RecoilValueReadOnly<RunbookFilterType['m']>
export const useMilestoneFilter = createFilterHook('m', milestoneFilterState)

const dateWithinFilterState = filterSelector({ attribute: 'dd' }) as RecoilValueReadOnly<RunbookFilterType['dd']>
export const useDateWithinFilter = createFilterHook('dd', dateWithinFilterState)

const startNotificationFilterState = filterSelector({ attribute: 'sn' }) as RecoilValueReadOnly<RunbookFilterType['sn']>
export const useStartNotificationFilter = createFilterHook('sn', startNotificationFilterState)

const fixedStartFilterState = filterSelector({ attribute: 'fs' }) as RecoilValueReadOnly<RunbookFilterType['fs']>
export const useFixedStartFilter = createFilterHook('fs', fixedStartFilterState)

const fixedEndFilterState = filterSelector({ attribute: 'fe' }) as RecoilValueReadOnly<RunbookFilterType['fe']>
export const useFixedEndFilter = createFilterHook('fe', fixedEndFilterState)

const hasCommentsFilterState = filterSelector({ attribute: 'c' }) as RecoilValueReadOnly<RunbookFilterType['c']>
export const useHasCommentsFilter = createFilterHook('c', hasCommentsFilterState)

const lateFilterState = filterSelector({ attribute: 'l' }) as RecoilValueReadOnly<RunbookFilterType['l']>
export const useLateFilter = createFilterHook('l', lateFilterState)

const overRunningFilterState = filterSelector({ attribute: 'or' }) as RecoilValueReadOnly<RunbookFilterType['or']>
export const useOverRunningFilter = createFilterHook('or', overRunningFilterState)

const hasPredecessorsFilterState = filterSelector({ attribute: 'hp' }) as RecoilValueReadOnly<RunbookFilterType['hp']>
export const useHasPredecessorsFilter = createFilterHook('hp', hasPredecessorsFilterState)

const hasSuccessorsFilterState = filterSelector({ attribute: 'hs' }) as RecoilValueReadOnly<RunbookFilterType['hs']>
export const useHasSuccessorsFilter = createFilterHook('hs', hasSuccessorsFilterState)

const hasErrorsFilterState = filterSelector({ attribute: 'he' }) as RecoilValueReadOnly<RunbookFilterType['he']>
export const useHasErrorsFilter = createFilterHook('he', hasErrorsFilterState)

const myTasksFilterState = filterSelector({ attribute: 'mt' }) as RecoilValueReadOnly<RunbookFilterType['mt']>
export const useMyTasksFilter = createFilterHook('mt', myTasksFilterState)

const activeTasksFilterState = filterSelector({ attribute: 'at' }) as RecoilValueReadOnly<RunbookFilterType['at']>
export const useActiveTasksFilter = createFilterHook('at', activeTasksFilterState)

const dateFromFilterState = filterSelector({ attribute: 'df' }) as RecoilValueReadOnly<RunbookFilterType['df']>
export const useDateFromFilter = createFilterHook('df', dateFromFilterState)

const dateToFilterState = filterSelector({ attribute: 'dt' }) as RecoilValueReadOnly<RunbookFilterType['dt']>
export const useDateToFilter = createFilterHook('dt', dateToFilterState)

const completionTypeFilterState = filterSelector({ attribute: 'ct' }) as RecoilValueReadOnly<RunbookFilterType['ct']>
export const useCompletionTypeFilter = createFilterHook('ct', completionTypeFilterState)

const startRequirementsFilterState = filterSelector({ attribute: 'sr' }) as RecoilValueReadOnly<RunbookFilterType['sr']>
export const useStartRequirementsFilter = createFilterHook('sr', startRequirementsFilterState)

const endRequirementsFilterState = filterSelector({ attribute: 'er' }) as RecoilValueReadOnly<RunbookFilterType['er']>
export const useEndRequirementsFilter = createFilterHook('er', endRequirementsFilterState)

const assignedFilterState = filterSelector({ attribute: 'a' }) as RecoilValueReadOnly<RunbookFilterType['a']>
export const useAssignedFilter = createFilterHook('a', assignedFilterState)

const stageFilterState = filterSelector({ attribute: 'stage' }) as RecoilValueReadOnly<RunbookFilterType['stage']>
export const useStageFilter = createFilterHook('stage', stageFilterState)

const streamFilterState = filterSelector({ attribute: 'stream' }) as RecoilValueReadOnly<RunbookFilterType['stream']>
export const useStreamFilter = createFilterHook('stream', streamFilterState)

const searchQueryFilterState = filterSelector({ attribute: 'q' }) as RecoilValueReadOnly<RunbookFilterType['q']>
export const useSearchQueryFilter = createFilterHook('q', searchQueryFilterState)

const taskTypeFilterState = filterSelector({ attribute: 'type' }) as RecoilValueReadOnly<RunbookFilterType['type']>
export const useTaskTypeFilter = createFilterHook('type', taskTypeFilterState)

const criticalPathToHereFilterState = filterSelector({ attribute: 'critical_to_here' }) as RecoilValueReadOnly<
  RunbookFilterType['critical_to_here']
>
export const useCriticalPathToHereFilter = createFilterHook('critical_to_here', criticalPathToHereFilterState)

const ancestorsFilterState = filterSelector({ attribute: 'predecessors_to_here' }) as RecoilValueReadOnly<
  RunbookFilterType['predecessors_to_here']
>
export const useAncestorsFilter = createFilterHook('predecessors_to_here', ancestorsFilterState)

// Internal helpers

function createFilterHook<T>(key: keyof RunbookFilterType, selector: RecoilValueReadOnly<T>) {
  return () => {
    const value = useRecoilValue(selector)
    const setValue = useSetRecoilState(filterSelector({ attribute: key })) as unknown as SetterOrUpdater<T>

    return [value, setValue] as [typeof value, typeof setValue]
  }
}

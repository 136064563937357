import { FocusEventHandler, KeyboardEventHandler, ReactNode, SyntheticEvent, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import styled from 'styled-components/macro'

import { IconButton } from '../button/button'
import { Input } from '../form/input'
import { useFocusableElementRef } from '../hooks'
import { Box } from '../layout/'
import { resolveColor } from '../theme/grommet-utils'

export type SearchBarProps = {
  onSearch: any
  placeholder: string
  width?: number | string
  delay?: number
  open?: boolean
  isSearchCleared?: boolean
  onKeyDown?: KeyboardEventHandler
  loading?: boolean
  disabled?: boolean
  autoComplete?: string
  onFocus?: FocusEventHandler<HTMLInputElement>
  isFocusSet?: boolean
  autoFocus?: boolean
  suffix?: () => ReactNode
  value?: string
}

export type SearchBarState = 'opening' | 'closing' | 'open' | 'closed'

export const SearchBar = ({
  onSearch,
  placeholder,
  width = 400,
  delay = 500,
  open = false,
  isSearchCleared = false,
  isFocusSet = false,
  onKeyDown,
  loading,
  disabled,
  autoComplete = 'off',
  onFocus,
  autoFocus = true,
  suffix,
  value: initialValue = ''
}: SearchBarProps) => {
  const [searchBarState, setSearchBarState] = useState<SearchBarState>(open ? 'open' : 'closed')
  const [showCloseButton, setShowCloseButton] = useState(!open)
  const [inputRef, setInputFocus] = useFocusableElementRef<HTMLInputElement>()
  const [isFocused, setIsFocused] = useState(false)
  const [value, setValue] = useState<string>(initialValue)
  const [debouncedValue, setDebouncedValue] = useState('')

  const computedWidth = typeof width === 'number' ? width + 'px' : '100%'
  const computedPlaceholder = open || searchBarState === 'open' ? placeholder : ''
  const showSearch = open || searchBarState === 'opening' || searchBarState === 'open'
  const expanded = open || searchBarState === 'open'
  const hasSuffix = !!suffix

  const handleFocusSeachBar: FocusEventHandler<HTMLInputElement> = evt => {
    onFocus?.(evt)
    setIsFocused(true)
  }

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(value)
      if (inputRef?.current) inputRef.current.value = value
      setShowCloseButton(!open || (open && value !== ''))
    },
    delay,
    [value]
  )

  useEffect(() => {
    return () => {
      cancel()
    }
  }, [])

  const onChange = (event: SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).value
    setShowCloseButton(!open || (open && value !== ''))
    setValue(value)
  }

  useEffect(() => {
    onSearch(debouncedValue)
  }, [debouncedValue])

  useEffect(() => {
    if (isSearchCleared) {
      if (inputRef?.current) inputRef.current.value = ''
      onSearch('')
      setValue('')
      setShowCloseButton(false)
    }
  }, [isSearchCleared])

  useEffect(() => {
    if (isFocusSet && !isFocused) {
      setInputFocus()
    }
  }, [isFocusSet])

  const handleSearchClick = () => {
    if (open) {
      setInputFocus()
    } else {
      if (searchBarState === 'closed') {
        toggleSearchBar()
      } else {
        setInputFocus()
      }
    }
  }

  const handleCloseClick = () => {
    if (open) {
      if (inputRef?.current) inputRef.current.value = ''
      setShowCloseButton(false)
      setSearchBarState('closed')
      setInputFocus()
      onSearch(undefined)
    } else {
      toggleSearchBar()
    }
  }

  const toggleSearchBar = () => {
    if (inputRef?.current) inputRef.current.value = ''
    if (searchBarState === 'open') {
      onSearch(undefined)
      setSearchBarState('closed')
    } else {
      setSearchBarState('open')
    }
  }

  useEffect(() => {
    if (autoFocus && searchBarState === 'open') setInputFocus()
  }, [searchBarState])

  return (
    <Box
      css={`
        width: ${typeof width === 'number' ? width + 'px' : width};
      `}
    >
      <SearchBarContainer width={computedWidth} state={searchBarState} expanded={expanded} align="center">
        {expanded ? (
          <IconButton tertiary label="Search" icon="search" disabled disableTooltip />
        ) : (
          <IconButton tertiary icon="search" onClick={handleSearchClick} label="Search" />
        )}

        {showSearch && (
          <>
            <InputWrapper width={computedWidth} state={searchBarState} expanded={expanded}>
              <StyledInput
                ref={inputRef}
                state={searchBarState}
                width={computedWidth}
                expanded={expanded}
                name={'search'}
                type={'search'}
                aria-label="search"
                placeholder={computedPlaceholder}
                onChange={onChange}
                onFocus={handleFocusSeachBar}
                onBlur={() => setIsFocused(false)}
                onKeyDown={onKeyDown}
                autoComplete={autoComplete}
                disabled={disabled}
                defaultValue={value}
              />
              {hasSuffix ? <Box>{suffix()}</Box> : null}
            </InputWrapper>
            {showCloseButton && !disabled && (
              <Box
                css={`
                  position: absolute;
                  right: 0;
                `}
              >
                <IconButton tertiary label="Clear search" icon="close" onClick={handleCloseClick} loading={loading} />
              </Box>
            )}
          </>
        )}
      </SearchBarContainer>
    </Box>
  )
}

type SearchBarStyledProps = {
  width: string
  state: SearchBarState
  expanded?: boolean
}

const SearchBarContainer = styled(Box)<SearchBarStyledProps>`
  flex-direction: row;
  position: relative;
  height: 40px;
  width: ${props => (props.width ? props.width : props.expanded ? '100%' : 'auto')};
  left: 0;
`

const InputWrapper = styled(Box)<SearchBarStyledProps>`
  display: flex;
  flex-direction: row;
  border: 0;
  border-radius: 20px;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  padding: 0 40px;
  cursor: pointer;
  position: absolute;
  width: ${props => (props.expanded ? props.width : '0')};
  background-color: ${({ theme }) => resolveColor('bg-2', theme)};
  &:hover {
    background-color: ${({ theme }) => resolveColor('bg-3', theme)};
  }
`

const StyledInput = styled(Input)<SearchBarStyledProps>`
  border: 0;
  outline: 0;
  padding: 0;
  font-size: 15px;
  overflow: hidden;
  height: 40px;
  line-height: 40px;
  background: none;
  flex: 1;
  color: ${({ theme }) => resolveColor('text', theme)};
`

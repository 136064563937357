import { useRecoilValue } from 'recoil'

import { TaskEditForm } from './task-edit-form'
import { useEditTaskPanel } from 'main/context/panel-context'
import { taskEditInitialDataState } from 'main/recoil/runbook'

export const TaskEditPanel = () => {
  const { taskId, closePanel } = useEditTaskPanel()
  const taskEditInitialData = useRecoilValue(taskEditInitialDataState(taskId))

  return (
    <>
      {taskEditInitialData && (
        <TaskEditForm key={taskEditInitialData.id} data={taskEditInitialData} onClose={closePanel} />
      )}
    </>
  )
}

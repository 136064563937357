import { useCallback, useEffect, useMemo } from 'react'

import { ColumnConfig, Table, Text } from '@cutover/react-ui'
import { useToggleRightPanel } from 'main/components/layout/layout-hooks'
import { useDatasourceEditPanel } from 'main/context/panel-context'
import { useLanguage } from 'main/services/hooks'
import { DataSource } from 'main/services/queries/types'
import { useDataSourcesQuery } from 'main/services/queries/use-data-sources'
import { usePermissions } from 'main/services/queries/use-permissions'

export const DataSourcesList = () => {
  const { t } = useLanguage('dataSources')
  const toggleRightPanel = useToggleRightPanel()
  const { data } = useDataSourcesQuery()
  const items = data?.data_sources || []

  const permissions = usePermissions('data-sources')
  const canRead = permissions('show')

  const { dataSourceId, clearPanel, openPanel } = useDatasourceEditPanel()

  const dataSourcesColumns: ColumnConfig<DataSource>[] = useMemo(() => {
    const columns = [
      {
        header: t('dataSources.table.columnHeaders.id'),
        property: 'id',
        sortable: true,
        render: (datum: DataSource) => <Text> {datum.id} </Text>
      },
      {
        header: t('dataSources.table.columnHeaders.name'),
        property: 'name',
        sortable: true
      },
      {
        header: t('dataSources.table.columnHeaders.url'),
        property: 'url',
        sortable: true
      }
    ]

    return columns
  }, [])

  const handleClickRow = useCallback(
    ({ datum }: { datum: DataSource }) => {
      if (canRead) {
        if (datum?.id === dataSourceId) {
          clearPanel()
        } else {
          openPanel({ dataSourceId: datum?.id as number })
        }
      }
    },
    [openPanel, clearPanel, canRead, dataSourceId]
  )

  useEffect(() => {
    toggleRightPanel(false)
  }, [])

  return <Table columns={dataSourcesColumns} data={items} onClickRow={handleClickRow} />
}

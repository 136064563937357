import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { eventManager } from 'event-manager'
import { useForm, useFormState } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Box, Button, colors, Form, Heading, Message, TextInput } from '@cutover/react-ui'
import { AuthenticationFormWrapper } from './authentication-form-wrapper'
import { LoginPageOption } from './login-page'
import { FooterLinkText } from './login-styled'
import { LoginWarningMessage } from './login-warning-message'
import { useGlobalConfig, useLanguage } from 'main/services/hooks'
import { LoginPayload, useLogin } from 'main/services/queries/use-login'

export const EmailForm = ({
  handleLoginPageOption,
  loginWarningMessage
}: {
  handleLoginPageOption: (option: LoginPageOption) => void
  loginWarningMessage: string
}) => {
  const [logoutState] = useState<{ success?: string[]; error?: string[] }>(
    JSON.parse(localStorage.getItem('reactLogout') ?? '{}')
  )
  const { oauthActive } = useGlobalConfig()
  const [showRedirectMessage] = useState(localStorage.getItem('showRedirectMessage'))
  const [resetPasswordSuccess] = useState(localStorage.getItem('resetPasswordSuccess'))
  const [userVerifySuccess] = useState(localStorage.getItem('userVerifySuccess'))
  const [reactForbidden] = useState(localStorage.getItem('reactForbidden'))
  const [blockLogin, setBlockLogin] = useState(Boolean(loginWarningMessage))
  const [serverError, setServerError] = useState('')
  const { t } = useLanguage('authentication')
  const navigate = useNavigate()

  const { register, handleSubmit, control } = useForm<LoginPayload>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(
      yup.object({
        email: yup.string().email('incompleteForm').required('incompleteForm'),
        password: yup.string().required('incompleteForm')
      })
    ),
    shouldFocusError: false
  })

  const { errors } = useFormState({ control })
  const login = useLogin({
    onSuccess: user => {
      eventManager.emit('login', user)
      const redirect = sessionStorage.getItem('reactLoginRedirect')
      sessionStorage.removeItem('reactLoginRedirect')
      navigate(redirect || '/app/my-cutover')
    },
    onError: () => {
      setServerError('loginError.invalidCredentials')
    }
  })

  const handleLogin = (data: LoginPayload) => {
    if (serverError) setServerError('')
    login.mutate(data)
  }

  const toggleBlockLogin = () => {
    setBlockLogin(!blockLogin)
  }

  useEffect(() => {
    localStorage.removeItem('reactLogout')
  }, [logoutState])

  useEffect(() => {
    localStorage.removeItem('showRedirectMessage')
  }, [showRedirectMessage])

  useEffect(() => {
    localStorage.removeItem('reactForbidden')
  }, [reactForbidden])

  useEffect(() => {
    localStorage.removeItem('userVerifySuccess')
  }, [userVerifySuccess])

  useEffect(() => {
    localStorage.removeItem('resetPasswordSuccess')
  }, [resetPasswordSuccess])

  return (
    <>
      <Box width="320px">
        <Heading
          as="h1"
          color={colors.white}
          css={`
            margin: 12px 0px 24px;
            text-align: center;
          `}
          weight={300}
        >
          {t('loginHeading')}
        </Heading>
        <Form onSubmit={handleSubmit(handleLogin)}>
          <AuthenticationFormWrapper>
            {reactForbidden && (
              <Box margin={{ bottom: '12px' }}>
                <Message message={t('forbiddenMessage')} type="error" />
              </Box>
            )}
            {showRedirectMessage && (
              <Box margin={{ bottom: '12px' }}>
                <Message message={t('redirectMessage')} type="error" />
              </Box>
            )}
            {logoutState?.success?.includes('user-updated') && (
              <Box margin={{ bottom: '12px' }}>
                <Message message={t('userUpdatedMessage')} type="success" />
              </Box>
            )}
            {logoutState?.success?.includes('logout') && (
              <Box margin={{ bottom: '12px' }}>
                <Message message={t('logOutMessage')} type="success" />
              </Box>
            )}
            {(Boolean(errors.email) || Boolean(errors.password) || serverError) && (
              <Box margin={{ bottom: '12px' }}>
                <Message type="error" message={t(errors.email?.message || errors.password?.message || serverError)} />
              </Box>
            )}
            {Boolean(resetPasswordSuccess) && (
              <Box margin={{ bottom: '12px' }}>
                <Message type="success" message={t('passwordResetSuccess')} />
              </Box>
            )}
            {userVerifySuccess && (
              <Box margin={{ bottom: '12px' }}>
                <Message message={t('userVerifyMessage')} type="success" />
              </Box>
            )}
            {Boolean(loginWarningMessage) && (
              <LoginWarningMessage onClick={toggleBlockLogin} message={loginWarningMessage} />
            )}
            <Box pad={{ horizontal: 'small', top: 'small' }}>
              <TextInput
                {...register('email')}
                hasError={Boolean(errors.email)}
                required
                label={t('emailInputLabel')}
                data-testid="email-input"
                tooltipText={t('emailTooltip')}
                autoComplete="email"
              />
            </Box>
            <Box pad={{ horizontal: 'small' }}>
              <TextInput
                {...register('password')}
                hasError={Boolean(errors.password)}
                required
                label={t('passwordInputLabel')}
                type="password"
                data-testid="password-input"
                tooltipText={t('passwordTooltip')}
                autoComplete="current-password"
              />
            </Box>
            <Box pad="small">
              <Button
                primary
                type="submit"
                data-testid="submit-button"
                full
                label={t('submitLogin')}
                loadingLabel={t('submittingLogin')}
                loading={login.isLoading}
                icon="arrow-forward"
                disabled={blockLogin}
              />
            </Box>
          </AuthenticationFormWrapper>
        </Form>
      </Box>
      <Box
        direction="row"
        gap="medium"
        alignSelf="center"
        css={`
          margin-top: 12px;
          text-align: center;
        `}
      >
        <FooterLinkText onClick={() => navigate('/forgotpassword')}>{t('forgotPasswordLink')}</FooterLinkText>
        {oauthActive && <FooterLinkText onClick={() => handleLoginPageOption('sso')}>{t('ssoLink')}</FooterLinkText>}
      </Box>
    </>
  )
}

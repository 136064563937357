import { useMutation, useQueryClient } from 'react-query'

import { apiClient } from 'main/services/api'

export type ValidateMergePayload = {
  runbook_ids: number[]
  target_runbook_id?: number | string | null
  task_parent_id_to_link_to?: number
}

type RunbookMergeResponse = {
  result: 'success' | 'failure'
  total: number
}

type GenericMergeResult = {
  data: any
  result: 'success' | 'failure'
  total: number
}

export type ValidateMergeResponse = {
  runbooks: RunbookMergeResponse
  streams: GenericMergeResult
  tasks: GenericMergeResult
  teams: GenericMergeResult
}

export const useValidateMerge = () => {
  const queryClient = useQueryClient()

  return useMutation<ValidateMergeResponse, Error, ValidateMergePayload>(
    'validate-merge',
    async (payload: ValidateMergePayload) => {
      const { data } = await apiClient.patch<ValidateMergePayload, ValidateMergeResponse>({
        url: 'runbooks/validate_merge',
        data: payload
      })

      return data as ValidateMergeResponse
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('validateMerge')
      }
    }
  )
}

import { memo, useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { Box, Checkbox, RadioboxGroup } from '@cutover/react-ui'
import { ToggleControl } from '../shared/toggle-control'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { nowDateAtom, useCriticalPathFilter, useDateWithinFilter, useMilestoneFilter } from 'main/recoil/shared/filters'
import { useLanguage } from 'main/services/hooks'
import { DateWithin, RunbookFilterType } from 'main/services/tasks/filtering'

export const QuickFiltersGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const [milestones] = useMilestoneFilter()
  const [criticalPath] = useCriticalPathFilter()
  const [dateWithin] = useDateWithinFilter()

  const isApplied = [milestones, criticalPath, dateWithin].some(filter => filter !== undefined)

  return (
    <FilterAccordionPanel label={t('quickFilters')} applied={isApplied}>
      <CriticalPathToggle />
      <MilestoneToggle />
      <DateWithinToggle />
    </FilterAccordionPanel>
  )
})

const CriticalPathToggle = () => {
  const [value, setValue] = useCriticalPathFilter()

  return <ToggleControl filterKey="critical" value={value} setValue={setValue} />
}

const MilestoneToggle = () => {
  const [value, setValue] = useMilestoneFilter()

  return <ToggleControl filterKey="m" value={value} setValue={setValue} />
}

type DateWithinFilterValueType = RunbookFilterType['dd']

type DateWithinOption = {
  label: string
  value: DateWithin
}

const DateWithinToggle = () => {
  const [isFilterActive, setFilterActive] = useState<boolean>(false)
  const [value, setValue] = useDateWithinFilter()
  const setDate = useSetRecoilState(nowDateAtom)
  const { t } = useLanguage('common', { keyPrefix: 'filter' })

  const dateWithinOptions: DateWithinOption[] = [
    {
      label: t('dateWithin.hour'),
      value: 'hour'
    },
    {
      label: t('dateWithin.12hours'),
      value: 'halfday'
    },
    {
      label: t('dateWithin.day'),
      value: 'day'
    },
    {
      label: t('dateWithin.week'),
      value: 'week'
    },
    {
      label: t('dateWithin.month'),
      value: 'month'
    },
    {
      label: t('dateWithin.3months'),
      value: '3months'
    },
    {
      label: t('dateWithin.year'),
      value: 'year'
    }
  ]

  const toggleFilter = () => {
    if (isFilterActive) {
      setValue(undefined)
    } else if (!value) {
      setValue('day')
    }
    setFilterActive(!isFilterActive)
  }

  useEffect(() => {
    if (value) {
      setDate(Math.floor(Date.now() / 1000))
    } else {
      setDate(0)
    }

    if (!value && isFilterActive) {
      setFilterActive(false)
    } else if (value && !isFilterActive) {
      setFilterActive(true)
    }
  }, [isFilterActive, value])

  return (
    <Box direction="row">
      <Box flex={false}>
        <Checkbox label={t('runbook.dd')} checked={isFilterActive} onClick={toggleFilter} />
      </Box>
      {isFilterActive && (
        <RadioboxGroup
          name="date-within"
          defaultValue={'day'}
          value={value}
          onChange={(event: any) => event && setValue(event.target.value as DateWithinFilterValueType)}
          options={dateWithinOptions}
          a11yTitle={t('dateWithinOptions')}
          plain
          css={'position: relative; top: -12px;'}
        />
      )}
    </Box>
  )
}

import { useState } from 'react'

import { Box, TextEditor } from '@cutover/react-ui'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type TextEditorNodeProps = AppComponentNodeProps & {
  value?: string
  label: string
  name: string
  readOnly?: boolean
}

export const TextEditorNode = ({ appId, resourceId, id, ...props }: TextEditorNodeProps) => {
  const { componentProps, setState } = useComponentPropsAndState(appId, resourceId, id, props)
  const { value: initialValue, label, name, readOnly } = componentProps as TextEditorNodeProps

  const [value, setValue] = useState<string>(initialValue ?? '')

  const updateFieldValue = (value: string) => {
    setValue(value)
    setState({ name, value })
  }

  return (
    <Box
      css={`
        margin-top: ${readOnly ? '-15px' : '0'};
      `}
    >
      <TextEditor
        readOnly={readOnly}
        value={value}
        plain={!label}
        label={label ?? ''}
        onChange={value => updateFieldValue(value)}
      />
    </Box>
  )
}

import { useMemo, useState } from 'react'

import { Box, Message, Modal, RadioboxList, SanitizedString, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import {
  useAccountMetaProperty,
  useAccountProperty,
  useRunbookId,
  useSetModalActiveState,
  useTaskListTask,
  useTaskProgression,
  useTaskProgressionState
} from 'main/recoil/runbook'
import { useCurrentUser } from 'main/recoil/current-user'
import { useModalCheckTaskStageChanged } from './use-modal-check-task-stage-changed'

export const TaskOverride = ({ onClose, id }: { id: number; onClose: () => void }) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskOverrideModal' })
  const [override, setOverride] = useState(false)
  const { resolveProgressionModalCallback, startOrFinishTask } = useTaskProgression()
  const { modalContinue } = useSetModalActiveState()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const progressionState = useTaskProgressionState(id)! // if we've gotten to this modal then there has to be a progression state
  const initialProgressionState = useMemo(() => progressionState, [])
  const accountName = useAccountProperty({ attribute: 'name' })
  const task = useTaskListTask(id)
  const customFields = useAccountMetaProperty({ attribute: 'custom_fields' })
  const currentUser = useCurrentUser()
  const runbookId = useRunbookId()

  const nextModal = useMemo(
    () =>
      resolveProgressionModalCallback({
        task,
        accountName,
        progressionState,
        customFields,
        currentUser,
        runbookId,
        from: { id, type: 'task-override' }
      }),
    [task, accountName, initialProgressionState, customFields, currentUser, runbookId, resolveProgressionModalCallback]
  )

  const {
    handleSubmit,
    handleClose,
    confirmIcon,
    confirmText,
    errors: stageChangedErrors,
    renderContent,
    hasCancelButton
  } = useModalCheckTaskStageChanged({
    task,
    onSubmit: async () => {
      if (nextModal) return modalContinue(nextModal, { id, type: 'task-override', context: { override } })
      return startOrFinishTask(id, { override: initialProgressionState.optional ? override : true })
    },
    onClose,
    confirmIcon: 'chevron-right',
    confirmText: t('confirmText')
  })

  return (
    <Modal
      data-testid="modal-task-override"
      open
      animate={nextModal ? 'in' : true}
      onClose={handleClose}
      confirmIcon={confirmIcon}
      confirmText={confirmText as string}
      title={t(initialProgressionState.stage === 'startable' ? 'titleStart' : 'titleFinish')}
      onClickConfirm={handleSubmit as any}
      hasCancelButton={hasCancelButton}
    >
      {stageChangedErrors && <Message margin type="error" message={stageChangedErrors} />}
      {renderContent(
        <Box>
          {initialProgressionState.optional ? (
            <Box>
              <SanitizedString input={t('contentOptional')} />
              <Text
                size="small"
                color="text-light"
                css={`
                  margin-top: 12px;
                `}
              >
                {t('wishToOverride')}
              </Text>
              <RadioboxList
                name="override"
                direction="row"
                options={[
                  {
                    value: 'continue',
                    label:
                      initialProgressionState.stage === 'startable'
                        ? t('startWithoutOverriding')
                        : t('finishWithoutOverriding')
                  },
                  {
                    value: 'override',
                    label:
                      initialProgressionState.stage === 'startable' ? t('startAndOverride') : t('finishAndOverride')
                  }
                ]}
                defaultValue="continue"
                onChange={e => {
                  setOverride((e.target.value as 'continue' | 'override') === 'override')
                }}
              />
            </Box>
          ) : (
            <Text>{t('content', { stage: initialProgressionState.stage === 'startable' ? 'start' : 'end' })}</Text>
          )}
        </Box>
      )}
    </Modal>
  )
}

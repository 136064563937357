import { LoadingPanel } from '@cutover/react-ui'
import { DataSourcesEdit } from './data-source-edit'
import { useDatasourceEditPanel } from 'main/context/panel-context'
import { useDataSource } from 'main/services/queries/use-data-sources'

export const DataSourcesEditPanel = () => {
  const { dataSourceId, clearPanel } = useDatasourceEditPanel()
  const { data } = useDataSource(dataSourceId)

  return data?.data_source && clearPanel !== null ? (
    <DataSourcesEdit dataSource={data.data_source} onClose={clearPanel} key={dataSourceId} />
  ) : (
    <LoadingPanel />
  )
}

import { useMemo, useState } from 'react'

import { Box, Button, Pill, resolveColor, Tooltip, useTheme } from '@cutover/react-ui'
import { RunbookSettingsMenu } from './runbook-settings-menu'
import { ScheduledStartRunbookModal } from '../modals/scheduled-start-runbook/scheduled-start-runbook-modal'
import {
  useAccountProperty,
  useRunbookCurrentVersionProperty,
  useRunbookId,
  useRunbookPermission,
  useRunbookProperty,
  useRunbookRunbookType,
  useRunbookVersionProperty,
  useToggleRecoilFavorites
} from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'

export const RunbookToolbar = () => {
  const slug = useAccountProperty({ attribute: 'slug' })
  const [showScheduledStartRunbookModal, setShowScheduledStartRunbookModal] = useState(false)
  const startScheduled = useRunbookCurrentVersionProperty({ attribute: 'start_scheduled' })
  const hasScheduledStart = !!startScheduled

  const runbookId = useRunbookId()
  const archived = useRunbookProperty({ attribute: 'archived' })
  const is_template = useRunbookProperty({ attribute: 'is_template' })
  const name = useRunbookProperty({ attribute: 'name' })
  const templateType = useRunbookProperty({ attribute: 'template_type' })

  const [favorite, setFavorite] = useToggleRecoilFavorites({ id: runbookId })
  const isFavorite = !!favorite

  const toggleFavorite = () => {
    if (runbookId) {
      setFavorite(
        favorite || {
          account_slug: slug,
          archived,
          is_template,
          name,
          template_type: templateType,
          id: runbookId
        }
      )
    }
  }

  const openScheduledStartRunbookModal = () => setShowScheduledStartRunbookModal(true)

  return (
    <>
      <RunbookTitleLabel hasScheduledStart={hasScheduledStart} onClickSchedule={openScheduledStartRunbookModal} />
      <RunbookSettingsMenu hasScheduledStart={hasScheduledStart} onClickSchedule={openScheduledStartRunbookModal} />
      <FavoriteStar isFavorite={isFavorite} toggleFavorite={toggleFavorite} />
      {showScheduledStartRunbookModal && (
        <ScheduledStartRunbookModal
          startScheduled={startScheduled}
          onClose={() => setShowScheduledStartRunbookModal(false)}
        />
      )}
    </>
  )
}

const RunbookTitleLabel = ({
  onClickSchedule,
  hasScheduledStart
}: {
  onClickSchedule: () => void
  hasScheduledStart: boolean
}) => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook' })
  const run = useRunbookVersionProperty({ attribute: 'run' })
  const templateVersion = useRunbookVersionProperty({ attribute: 'template_version' })

  const runType = run?.run_type
  const canUpdateRunbook = useRunbookPermission({ attribute: 'update' })
  const runbookType = useRunbookRunbookType()

  const templateType = useRunbookProperty({ attribute: 'template_type' })
  const templateStatus = useRunbookProperty({ attribute: 'template_status' })
  const sensitiveRunbook = useRunbookProperty({ attribute: 'restricted' })

  const pillProps = useMemo(() => {
    // incident runbook
    if (runbookType?.incident) {
      return sensitiveRunbook
        ? { label: t('sensitiveIncident'), color: 'primary' }
        : { label: t('incident'), color: 'primary' }
    }

    // has run type
    if (!!runType) {
      if (runType === 'live') {
        return runbookType.dynamic
          ? { label: t('liveRunDynamic'), color: 'primary' }
          : { label: t('liveRun'), color: 'primary' }
      } else if (runType === 'rehearsal') {
        return runbookType.dynamic
          ? { label: t('rehearsalDynamic'), color: 'secondary' }
          : { label: t('rehearsal'), color: 'secondary' }
      }
    }

    // template type
    if (templateType === 'snippet') {
      return { label: t('status.snippet'), color: 'secondary' }
    } else if (templateType === 'default') {
      return templateStatus === 'template_approved'
        ? { label: t('status.approved', { version: templateVersion }), color: 'success' }
        : templateStatus === 'template_rejected'
        ? { label: t('status.rejected', { version: templateVersion }), color: 'error' }
        : { label: t('status.draft', { version: templateVersion }), color: 'secondary' }
    }

    return null
  }, [templateStatus, templateType, runbookType, runType, sensitiveRunbook, t])

  return pillProps ? (
    <Pill
      {...pillProps}
      css={`
        position: relative;
        top: 6px;
        min-width: fit-content;
        cursor: default;
      `}
    />
  ) : !hasScheduledStart && canUpdateRunbook ? (
    <Button
      primary
      label={t('schedule')}
      onClick={onClickSchedule}
      size="small"
      css={`
        margin-left: 4px;
        top: 1px;
      `}
    />
  ) : null
}

const FavoriteStar = ({ isFavorite, toggleFavorite }: { isFavorite: boolean; toggleFavorite: () => void }) => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook' })
  const theme = useTheme()
  return (
    <Tooltip content={isFavorite ? t('removeFavorite') : t('addFavorite')} placement="bottom">
      <Box
        height="32px"
        align="center"
        justify="center"
        css={`
          position: relative;
          top: 1px;
        `}
      >
        <Button
          plain
          icon={isFavorite ? 'star-filled' : 'star'}
          onClick={toggleFavorite}
          css={`
            svg {
              height: 20px;
              width: 20px;
              fill: ${resolveColor(isFavorite ? 'star' : 'text-light', theme)};
            }
            &:hover {
              svg {
                fill: ${resolveColor('star', theme)};
              }
            }
          `}
        />
      </Box>
    </Tooltip>
  )
}

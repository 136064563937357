import { useRecoilCallback } from 'recoil'

import { AppsPayload } from './apps-types'
import { appConnectionLost } from 'main/recoil/apps/apps-atoms'
import { apiClient } from 'main/services/api'
import { RunbookIntegration, RunbookIntegrationActionItem } from 'main/services/queries/types'

export type AvailableIntegrationsResponse = {
  integration_links: RunbookIntegration[]
}

type IntegrationActionItemResponse = {
  integration_action_item: RunbookIntegrationActionItem
}

export const usePostAppEvents = () => {
  return useRecoilCallback(({ set }) => async (payload: AppsPayload) => {
    try {
      const data = await apiClient.post({
        url: 'app_events',
        data: { ...payload, session_id: window.sessionStorage.getItem('browserHash') || '' }
      })
      return data
    } catch {
      set(appConnectionLost, true)
    }
  })
}

export const getAvailableIntegrations = async (runbookId: string): Promise<AvailableIntegrationsResponse> => {
  const { data } = await apiClient.get<AvailableIntegrationsResponse>({
    url: `runbooks/${runbookId}/runbook_versions/current_version/integrations`
  })
  return data
}

export const getIntegrationActionItem = async (
  integrationActionItemId: string
): Promise<IntegrationActionItemResponse> => {
  const { data } = await apiClient.get<IntegrationActionItemResponse>({
    url: `integration_action_items/${integrationActionItemId}`
  })
  return data
}

import { Layout } from 'main/components/layout'
import { useDefaultLayout } from 'main/components/layout/layout-hooks'
import { SystemParametersHeader } from './system-parameters-header/system-parameters-header'
import { SystemParameterEditPanel } from './right-panels/system-parameters-edit-panel'
import { useHasPermissionsOrNavigateHome } from 'main/services/hooks/use-has-permissions-or-navigate-home'

export const SystemParametersLayout = () => {
  const userCanViewPage = useHasPermissionsOrNavigateHome('custom_fields')

  useDefaultLayout({
    filterPanel: false,
    subHeader: false,
    rightNav: false
  })

  return userCanViewPage ? (
    <Layout header={<SystemParametersHeader />} filter={null} rightPanels={<SystemParameterEditPanel />} />
  ) : null
}

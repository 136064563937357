import { useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { IconButton, Menu, MenuListItem } from '@cutover/react-ui'
import { CsvImportModal } from '../modals/csv-import/csv-import-modal'
import {
  csvImportModalStatus,
  useRunbookPermission,
  useRunbookProperty,
  useRunbookVersionPermission,
  useRunbookVersionProperty
} from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'

type UpdateOptionsProps = {
  hasScheduledStart?: boolean
  isLinkedRunbookChild?: boolean
  isRunbook: boolean
  isSnippet: boolean
  isPlanning: boolean
  onClickSchedule: () => void
}

// TODO see https://cutover.atlassian.net/browse/CFE-1372
// type VersionOptionsProps = {
//   isTemplate: boolean
//   hasTemplateVersions?: boolean
//   isCurrent?: boolean
//   resourceType: string
//   canCreate: boolean
//   canDelete: boolean
// }

type ImportOptionsProps = {
  openModal: () => void
}

export const RunbookSettingsMenu = ({
  onClickSchedule,
  hasScheduledStart
}: {
  onClickSchedule: () => void
  hasScheduledStart: boolean
}) => {
  const canUpdate = useRunbookPermission({ attribute: 'update' })
  const canImport = useRunbookVersionPermission({ attribute: 'import' })
  // const canCreate = useRunbookVersionPermission({ attribute: 'create' })
  // const canDelete = useRunbookVersionPermission({ attribute: 'destroy' })

  const { t } = useLanguage('runbook', { keyPrefix: 'runbookSettingsMenu' })
  const [csvImportModalOpen, setCsvImportModalOpen] = useState(false)

  const templateType = useRunbookProperty({ attribute: 'template_type' })
  const linkedRunbookDetails = useRunbookProperty({ attribute: 'linked_runbook_details' })

  const isLinkedRunbookChild = linkedRunbookDetails && !!linkedRunbookDetails.id

  const run = useRunbookVersionProperty({ attribute: 'run' })
  // const templateVersion = useRunbookVersionProperty({ attribute: 'template_version' })
  // const isCurrent = useRunbookVersionProperty({ attribute: 'is_current' })

  // const hasTemplateVersions = templateVersion !== 1
  const runType = run?.run_type

  const resourceType = templateType === 'snippet' ? 'Snippet' : 'Runbook'

  const isSnippet = templateType === 'snippet'
  // const isTemplate = templateType === 'default'
  const isRunbook = templateType === 'off'
  const isRehearsal = runType === 'rehearsal'
  const isLiveRun = runType === 'live'
  // const isPaused = runbookStage === 'paused'
  const isPlanning = !isRehearsal && !isLiveRun

  return (
    <>
      <Menu
        align="start"
        menuStyle={{ width: '320px' }}
        trigger={
          <IconButton
            icon="settings"
            tertiary
            label={t('iconLabel')}
            tipPlacement="bottom"
            size="medium"
            disableTooltip
            onClick={e => e.stopPropagation()}
            css={`
              position: relative;
              top: 1px;
            `}
          />
        }
      >
        {/* during development, will be commenting options that aren't yet connected to anything */}
        <>
          {/* {canDuplicate &&
            isCurrent &&
            (isTemplate ? (
              <MenuListItem
                label={t('options.createRunbookFromTemplate')}
                icon="copy"
                onClick={() => console.log('Duplicate!')}
              />
            ) : (
              <MenuListItem
                label={t('options.duplicate', { resourceType: resourceType.toLowerCase() })}
                icon="copy"
                onClick={() => console.log('Duplicate!')}
              />
            ))} */}

          {/* {canArchive && !isPaused && (
            <MenuListItem label={t('options.archive')} icon="archive" onClick={() => console.log('Duplicate!')} />
          )} */}
          {/* For portable runbooks */}
          {/* {isTemplate && (
            <MenuListItem
              label={t('options.downloadTemplate')}
              icon="download"
              onClick={() => console.log('With portable runbooks enabled!')}
            />
          )} */}
          {canImport && <ImportOptions openModal={() => setCsvImportModalOpen(true)} />}
          {/* TODO see https://cutover.atlassian.net/browse/CFE-1372 */}
          {/* <VersionOptions
            isTemplate={isTemplate}
            hasTemplateVersions={hasTemplateVersions}
            isCurrent={isCurrent}
            resourceType={resourceType}
            canCreate={canCreate}
            canDelete={canDelete}
          /> */}
          {/* TODO see https://cutover.atlassian.net/browse/CFE-1273 */}
          {/* {isRunbook && isPlanning && (
            <MenuListItem
              label={t('options.makeTemplate')}
              icon="template"
              onClick={() => console.log('make template!!')}
            />
          )} */}
          {/* <MenuListItem label={t('options.auditLog')} icon="page-text" onClick={() => console.log('Audit log!!')} /> */}
          {canUpdate && (
            <UpdateOptions
              onClickSchedule={onClickSchedule}
              hasScheduledStart={hasScheduledStart}
              isLinkedRunbookChild={isLinkedRunbookChild}
              isRunbook={isRunbook}
              isSnippet={isSnippet}
              isPlanning={isPlanning}
            />
          )}
          {/* <MenuListItemDivider /> */}
          {/* <MenuListItem label={t('options.reload')} icon="refresh" onClick={() => console.log('reload!')} /> */}
        </>
      </Menu>

      <CsvImportModal
        open={csvImportModalOpen}
        closeModal={() => setCsvImportModalOpen(false)}
        planningMode={isPlanning}
        resourceType={resourceType}
      />
    </>
  )
}
const UpdateOptions = ({
  onClickSchedule,
  hasScheduledStart,
  isLinkedRunbookChild,
  isRunbook,
  isPlanning
}: UpdateOptionsProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'runbookSettingsMenu' })
  return (
    <>
      {/* {isSnippet ? (
        <MenuListItem
          label={t('options.snippetUsers')}
          icon="user-list"
          onClick={() => console.log('Snippet users!!')}
        />
      ) : (
        <MenuListItem label={t('options.users')} icon="user-list" onClick={() => console.log('Runbook users!!')} />
      )} */}
      {isPlanning &&
        ((!hasScheduledStart || isLinkedRunbookChild) && isRunbook ? (
          <MenuListItem
            label={isLinkedRunbookChild ? t('options.scheduledStart') : t('options.addScheduledStart')}
            icon="time"
            onClick={onClickSchedule}
          />
        ) : hasScheduledStart && !isLinkedRunbookChild ? (
          <MenuListItem label={t('options.removeScheduledStart')} icon="time" onClick={onClickSchedule} />
        ) : (
          <></>
        ))}
    </>
  )
}

// TODO see https://cutover.atlassian.net/browse/CFE-1372
// const VersionOptions = ({ isTemplate, hasTemplateVersions, isCurrent, canCreate, canDelete }: VersionOptionsProps) => {
//   const { t } = useLanguage('runbook', { keyPrefix: 'runbookSettingsMenu' })
//   return (
//     <>
//       {canCreate &&
//         (isTemplate ? (
//           <MenuListItem
//             label={t('options.newTemplateVersion')}
//             icon="save"
//             onClick={() => console.log('New template!')}
//           />
//         ) : isCurrent ? (
//           <MenuListItem label={t('options.saveVersion')} icon="save" onClick={() => console.log('Saving a version')} />
//         ) : (
//           <MenuListItem
//             label={t('options.restoreVersion')}
//             icon="save"
//             onClick={() => console.log('Restoring this version')}
//           />
//         ))}

//       {canDelete && isTemplate && hasTemplateVersions && (
//         <MenuListItem
//           label={t('options.deleteDraftVersion')}
//           icon="delete"
//           onClick={() => console.log('Deleting a draft')}
//         />
//       )}
//       {/* <MenuListItem
//         label={t('options.saveAsSavedView', { resourceType: resourceType.toLowerCase() })}
//         icon="save"
//         onClick={() => console.log('Saved as a saved view')}
//       /> */}
//       <MenuListItem label={t('options.viewVersion')} icon="history" onClick={() => console.log('view version!!')} />
//     </>
//   )
// }

const ImportOptions = ({ openModal }: ImportOptionsProps) => {
  const setModalStatus = useSetRecoilState(csvImportModalStatus)
  const { t } = useLanguage('runbook', { keyPrefix: 'runbookSettingsMenu' })
  return (
    <>
      <MenuListItem
        label={t('options.importTasks')}
        icon="upload"
        onClick={() => {
          setModalStatus('idle')
          openModal()
        }}
      />
      {/* {!isSnippet && (
        <MenuListItem
          label={t('options.importRunbook')}
          icon="add-to-list"
          onClick={() => console.log('Importing a runbook')}
        />
      )} */}
    </>
  )
}

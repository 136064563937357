import styled, { css } from 'styled-components'

import { Box, IconButton, media } from '@cutover/react-ui'
import { useUserOrTeamDetailsEditPanel } from 'main/context/panel-context'

export const RunbookRightNavigation = () => {
  const { openPanel, closePanel, panel } = useUserOrTeamDetailsEditPanel()

  const handleClickPeoplePanel = () => {
    if (panel?.panel === 'user-team-details-edit') {
      closePanel()
    } else {
      openPanel({ initialScreen: 'users-and-teams' })
    }
  }

  // TODO: use i18n for labels
  return (
    <NavContainer>
      {/* TODO see https://cutover.atlassian.net/browse/CFE-1380 */}
      {/* <IconButton icon="edit" onClick={handleEdit} label="Runbook Details" /> */}
      <IconButton icon="users" onClick={handleClickPeoplePanel} label="View Users/Teams" />
      {/* TODO see https://cutover.atlassian.net/browse/CFE-445 */}
      {/* <IconButton icon="message" onClick={handleComments} label="Comments" /> */}
      {/* TODO see https://cutover.atlassian.net/browse/CFE-446 */}
      {/* <IconButton icon="phone" onClick={handleComms} label="Ad hoc Comms" /> */}
    </NavContainer>
  )
}

const NavContainer = styled(Box)`
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-top: 14px;
  ${media.sm(css`
    flex-direction: row;
    justify-content: center;
    gap: 32px;
  `)}
`

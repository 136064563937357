import { Box, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks/use-language'

type SnippetsNotFoundProps = {
  search: string
  clearSearch: () => void
}

export function SnippetsNotFound({ search, clearSearch }: SnippetsNotFoundProps) {
  const { t } = useLanguage('runbook', { keyPrefix: 'snippetListModal' })

  return (
    <Box
      align="center"
      css={`
        padding-top: 20px;
      `}
    >
      {search ? (
        <>
          <Text
            css={`
              margin-bottom: 20px;
            `}
          >
            {t('noSnippetsFound')}
          </Text>
          <Text>{t('cantFind')}</Text>
          <Box as="a" css="cursor: pointer" onClick={clearSearch}>
            {t('clearSearch')}
          </Box>
        </>
      ) : (
        <Text
          css={`
            margin-bottom: 20px;
          `}
        >
          {t('noApprovedSnippetsFound')}
        </Text>
      )}
    </Box>
  )
}

import { useState } from 'react'

import { DateTimePicker } from '@cutover/react-ui'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type DateTimeFieldNodeProps = AppComponentNodeProps & {
  label: string
  name: string
  value?: string
  error?: boolean
}

export const DateTimeFieldNode = ({ appId, resourceId, id, ...props }: DateTimeFieldNodeProps) => {
  const { componentProps, setState } = useComponentPropsAndState(appId, resourceId, id, props)
  const { label, name, error } = componentProps as DateTimeFieldNodeProps
  const initialValue = componentProps.value as string

  const date = initialValue ? new Date(parseInt(initialValue, 10) * 1000) : null
  const [value, setValue] = useState<Date | null>(date)

  const handleValueChange = (value: Date | null) => {
    setValue(value)
    setState({ name, value: value?.toISOString() || '' })
  }

  return (
    <DateTimePicker
      value={value}
      label={label ?? ''}
      onChange={value => handleValueChange(value)}
      fixed
      hasError={error}
    />
  )
}

import { ReactNode, useEffect, useRef } from 'react'
import { useUnmount } from 'react-use'
import { useRecoilValue } from 'recoil'

import { runbookUrlParamState } from '../models'
import { useCurrentUser } from 'main/recoil/current-user'
import { useWebsockets } from 'main/services/hooks'
import { useRunbookChannelResponseProcessor } from '../updaters/use-runbook-channel-response-processor'

export const RunbookChannelSubscriber = ({ children }: { children?: ReactNode }) => {
  const { subscribe, findExistingSubscription } = useWebsockets()
  const runbookId = useRecoilValue(runbookUrlParamState)
  const { id: currentUserId } = useCurrentUser()
  const processRunbookResponse = useRunbookChannelResponseProcessor()

  const websocketRef = useRef<ActionCable.Channel | undefined>()

  useEffect(() => {
    websocketRef.current?.unsubscribe?.()

    if (!runbookId) return

    const existingRunbookChannel = findExistingSubscription('RunbookChannel', runbookId)

    if (existingRunbookChannel) return

    websocketRef.current = subscribe('RunbookChannel', runbookId, {
      received: response => {
        const [sameTab, sameUser] = [
          response.meta?.headers?.request_hash === window.sessionStorage.getItem('browserHash'),
          response.meta?.headers?.request_user_id === currentUserId
        ]

        if (sameTab && sameUser) return

        processRunbookResponse(response)
      }
    })
  }, [runbookId])

  useUnmount(() => {
    websocketRef.current?.unsubscribe?.()
  })

  return <>{children}</>
}

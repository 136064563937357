import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { Outlet } from 'react-router-dom'
import { isMountingOnlyMatch } from 'single-spa/react-routes'
import styled, { CSSProperties } from 'styled-components'

import { Box, BoxProps, useResponsiveContext } from '@cutover/react-ui'
import { useConnectRefreshState } from './refresh-state-connector'
import { useInitialMount } from 'main/services/hooks/use-initial-mount'

const MAIN_NAV_WIDTH = 280
const MAIN_HEADER_HEIGHT = 72

type PositionStyles = {
  left: string | number
  right: string | number
  transition?: string
  top: string | number
  bottom: string | number
  height?: string | number
  zIndex?: CSSProperties['zIndex']
}

type ReactAppLegacyConnectorProps = {
  mainHeaderHeight?: number
  mainNavWidth?: number
}

// NOTE: this is only to be used when the react workspace feature flag is enabled
// This is layout management is a mess but will be obsolete soon with the fullpage react work.
export const ReactAppLegacyConnector = (props: ReactAppLegacyConnectorProps) => {
  const isFirstMount = useInitialMount()
  const screenSize = useResponsiveContext()
  useConnectRefreshState(true)
  const [isMainNavOpen, setIsMainNavOpen] = useState(screenSize !== 'small')
  const isLoggedOutView = props.mainNavWidth === 0
  const mainNavWidth = props.mainNavWidth ?? MAIN_NAV_WIDTH
  const mainHeaderHeight = props.mainHeaderHeight ?? MAIN_HEADER_HEIGHT
  const [styles, setStyles] = useState<PositionStyles>({
    left: isLoggedOutView ? 0 : mainNavWidth,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 'auto'
  })
  const [rightPanelWidth, setRightPanelWidth] = useState(0)
  const [rightSubPanelWidth, setRightSubPanelWidth] = useState(0)
  // Not sure what this is for but don't wan't to mess with additional legacy react stuff in case it messes something up
  const [, setTransitionTiming] = useState('')
  const [isHidden, setIsHidden] = useState(false)

  useEffect(() => {
    const isMainNavFull = screenSize === 'small' && isMainNavOpen
    if (!isFirstMount) {
      setStyles({
        left: isLoggedOutView
          ? 0
          : isMainNavOpen
          ? screenSize === 'small'
            ? '100%'
            : mainNavWidth
          : screenSize === 'small'
          ? 0
          : 64,
        right: rightPanelWidth + rightSubPanelWidth,
        top: mainHeaderHeight,
        bottom: isHidden ? 'initial' : 0,
        height: isMainNavFull ? '0' : 'auto',
        zIndex: 'auto'
      })
    }
  }, [rightPanelWidth, rightSubPanelWidth, isHidden, isMainNavOpen, screenSize, isFirstMount])

  const setInitialStyles = () => {
    // when switching from angular to react sometimes the panels persist
    // their open/closed state and therefore don't emit init/destroy events.
    // So on initial mount of this component we check the DOM only, but only for angular layout elements.
    // As soon as we move the outer page to react, this approach won't be used anymore.
    const rightSubPanel = document.querySelector('.nav-project')
    const mountingOnlyMatch = isMountingOnlyMatch(window.location.hash)
    if (mountingOnlyMatch) setIsHidden(true)
    if (!!rightSubPanel) setRightSubPanelWidth(rightSubPanel.clientWidth)
  }

  useEffect(() => {
    setInitialStyles()

    const onExternalToggleMainNav = ({ open }: { open: boolean }) => {
      setIsMainNavOpen(open)
    }

    const onRightPanelOpen = ({ width }: { width: number }) => {
      setRightPanelWidth(width)
      setTransitionTiming('cubic-bezier(0, 0, 0.25, 1)')
    }
    const onRightPanelClose = () => {
      setRightPanelWidth(0)
      setTransitionTiming('cubic-bezier(0.4, 0, 0.4, 1)')
    }
    const onRightPanelResize = ({ width }: { width: number }) => {
      setRightPanelWidth(width)
    }
    const onRightSubPanelOpen = ({ width }: { width: number }) => {
      setRightSubPanelWidth(width)
    }
    const onRightSubPanelClose = () => {
      setRightSubPanelWidth(0)
    }
    const onAngularMainNavOpened = () => {
      setIsMainNavOpen(true)
    }
    const onAngularMainNavClosed = () => {
      setIsMainNavOpen(false)
    }
    const onRoutingEvent = ({
      target: {
        location: { hash }
      }
    }: {
      target: { location: { hash: string } }
    }) => {
      setIsHidden(isMountingOnlyMatch(hash))
    }

    eventManager.on('angular-right-panel-opened', onRightPanelOpen)
    eventManager.on('angular-right-panel-closed', onRightPanelClose)
    eventManager.on('angular-right-sub-panel-closed', onRightSubPanelClose)
    eventManager.on('angular-right-sub-panel-opened', onRightSubPanelOpen)
    eventManager.on('angular-right-panel-resize', onRightPanelResize)
    eventManager.on('angular-main-nav-opened', onAngularMainNavOpened)
    eventManager.on('angular-main-nav-closed', onAngularMainNavClosed)
    eventManager.on('react-toggle-main-nav', onExternalToggleMainNav)
    eventManager.on('angular-toggle-main-nav', onExternalToggleMainNav)

    //@ts-ignore
    window.addEventListener('single-spa:before-routing-event', onRoutingEvent)

    if (screenSize === 'small') {
      eventManager.emit('legacy-react-toggle-main-nav', { open: false })
      setIsMainNavOpen(false)
    } else if (!isMountingOnlyMatch(window.location.hash)) {
      eventManager.emit('legacy-react-toggle-main-nav', { open: true })
    }

    return () => {
      eventManager.off('angular-right-panel-opened', onRightPanelOpen)
      eventManager.off('angular-right-panel-closed', onRightPanelClose)
      eventManager.off('angular-right-sub-panel-closed', onRightSubPanelClose)
      eventManager.off('angular-right-sub-panel-opened', onRightSubPanelOpen)
      eventManager.off('angular-right-panel-resize', onRightPanelResize)
      eventManager.off('angular-main-nav-opened', onAngularMainNavOpened)
      eventManager.off('angular-main-nav-closed', onAngularMainNavClosed)
      eventManager.off('react-toggle-main-nav', onExternalToggleMainNav)
      eventManager.off('angular-toggle-main-nav', onExternalToggleMainNav)
      //@ts-ignore
      window.removeEventListener('single-spa:before-routing-event', onRoutingEvent)
    }
  }, [])

  return (
    <ReactContainer styles={styles}>
      <Outlet />
    </ReactContainer>
  )
}

type ReactContainerProps = BoxProps & { styles: PositionStyles }

const ReactContainer = styled(Box)<ReactContainerProps>`
  position: absolute;
  backface-visibility: hidden;
  transition: ${({ styles }) => styles.transition};
  z-index: ${({ styles }) => styles.zIndex};
  left: ${({ styles }) => (typeof styles.left === 'number' ? styles.left + 'px' : styles.left)};
  right: ${({ styles }) => (typeof styles.right === 'number' ? styles.right + 'px' : styles.right)};
  top: ${({ styles }) => (typeof styles.top === 'number' ? styles.top + 'px' : styles.top)};
  bottom: ${({ styles }) => (typeof styles.bottom === 'number' ? styles.bottom + 'px' : styles.bottom)};
  height: ${({ styles }) => (typeof styles.height === 'number' ? styles.height + 'px' : styles.height)};
  will-change: top, right, bottom, left, width, z-index;
`

import { useMutation } from 'react-query'

import { RunbookComment } from './types'
import { apiClient } from 'main/services/api/api-client'

type RunbookCommentsResponse = {
  runbook: RunbookComment
  meta: {
    headers: {
      request_class: string
      request_hash: string
      request_method: string
      request_user_id: number
    }
  }
}

type CommentPayload = {
  comment: {
    content: string
  }
}

export function useRunbookCommentCreate(runbookId: number, runbookVersionId: number | string = 'current_version') {
  return useMutation(async (comment: string) => {
    const response = await apiClient.post<CommentPayload, RunbookCommentsResponse>({
      url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/comments`,
      data: {
        comment: {
          content: comment
        }
      }
    })

    return response
  })
}

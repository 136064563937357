import { useState } from 'react'

import {
  LinkedSnippet,
  SelectSnippet
} from 'main/components/runbook/modals/snippets/start-snippet-task-type/select-snippet'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

type StartSnippetTaskTypeState = {
  linkedSnippets: LinkedSnippet[] | null
}

export const StartSnippetTaskTypeConnector = () => {
  const [state, setState] = useState<StartSnippetTaskTypeState>({ linkedSnippets: null })

  const handleOnMount = ({ linkedSnippets }: { linkedSnippets: LinkedSnippet[] }) => {
    setState({ linkedSnippets })
  }

  const handleOnUnmount = () => {
    setState({ linkedSnippets: null })
  }

  return (
    <ReactAngularConnector
      mountEvent="show-start-snippet-task-type"
      unmountEvent="hide-start-snippet-task-type"
      onMount={handleOnMount}
      onUnmount={handleOnUnmount}
    >
      {state.linkedSnippets && <SelectSnippet linkedSnippets={state.linkedSnippets} />}
    </ReactAngularConnector>
  )
}

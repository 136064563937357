import { useGlobalConfig } from '.'

export type Feature = {
  isEnabled: (feature: string) => boolean
}

export function useFeature(): Feature {
  const globalConfig = useGlobalConfig()
  return { isEnabled: (feature: string) => globalConfig.enabledFeatures.includes(feature) }
}

import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { Box, Message, Text, useNotify } from '@cutover/react-ui'
import { TaskLineItem } from 'main/components/shared/task-line-item'
import { useEditTaskPanel } from 'main/context/panel-context'
import { taskListLookupState, useRunbookId, useRunbookVersionId } from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'
import { deleteTasks } from 'main/services/queries/use-tasks'
import { FormModal } from 'main/components/shared/form'
import { RunbookTaskBulkDeleteResponse } from 'main/services/api/data-providers/runbook-types'
import { useProcessTaskBulkDeleteResponse } from 'main/recoil/runbook/updaters/task-operations'

export const TasksDeleteModal = ({
  open,
  taskIds = [],
  onClose
}: {
  open: boolean
  taskIds: number[]
  onClose?: () => void
}) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'tasksDeleteModal' })
  const handleSuccess = useProcessTaskBulkDeleteResponse()
  const { closePanel } = useEditTaskPanel()
  const runbookId = useRunbookId()
  const runbookVersionId = useRunbookVersionId()
  const notify = useNotify()
  const tasksRecord = useRecoilValue(taskListLookupState)
  const tasks = taskIds.map(id => tasksRecord[id])
  const sortedTaskList = useMemo(
    () => tasks.sort((a, b) => (a.start_latest_planned || 0) - (b.start_latest_planned || 0)),
    [tasks]
  )
  const tasksWithMultiplePredsAndSuccs = useMemo(
    () => tasks.filter(task => task?.predecessor_ids.length > 1 && task?.successor_ids.length > 1),
    [tasks]
  )

  const handleSubmit = async () => {
    return await deleteTasks(runbookId, runbookVersionId, taskIds)
  }

  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      onSuccess={(response: RunbookTaskBulkDeleteResponse) => {
        handleSuccess(response)
        notify.success(t('success', { count: taskIds.length }))
        closePanel()
      }}
      title={t('title', { count: taskIds.length })}
      confirmText={t('submit', { count: taskIds.length })}
      confirmIcon="delete"
      formElementWrapper={false}
    >
      <Box gap="small" margin={{ bottom: 'small' }}>
        <Text>{t('description', { count: taskIds.length })}</Text>
        {/* TODO: when we implement linked runbooks functionality there is a different warning */}
        <Box>{sortedTaskList.map(task => task && <TaskLineItem key={task.id} taskId={task.id} />)}</Box>
        {tasksWithMultiplePredsAndSuccs.length > 0 && (
          <Message
            type="warning"
            message={t('multiplePredecessorAndSuccessorWarning', {
              count: tasksWithMultiplePredsAndSuccs.length,
              taskIds: tasksWithMultiplePredsAndSuccs.map(t => `#${t.internal_id}`)
            })}
          />
        )}
      </Box>
    </FormModal>
  )
}

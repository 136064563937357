import { useEffect } from 'react'
import { useIntercom as useReactIntercom } from 'react-use-intercom'

import { useGlobalConfig } from '../../services/hooks'
import { useCurrentUser } from '../../recoil/current-user'

const DEFAULT_TIMEOUT = 36000

export const useIntercom = () => {
  const { boot, shutdown } = useReactIntercom()
  const { deviseMaxSessionTimeout } = useGlobalConfig()
  const { hashed_email, first_name, last_name, email } = useCurrentUser()

  useEffect(() => {
    if (!hashed_email) return

    boot({
      name: `${first_name} ${last_name}`,
      email,
      userHash: hashed_email,
      sessionDuration: (deviseMaxSessionTimeout ? parseInt(deviseMaxSessionTimeout) : DEFAULT_TIMEOUT) * 1000,
      customLauncherSelector: '#help'
    })

    return shutdown
  }, [])
}

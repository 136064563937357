import { ReactNode, Suspense } from 'react'
import { useRecoilValueLoadable } from 'recoil'

import {
  accountState,
  runbookResponseState_INTERNAL,
  runbookVersionResponseState_INTERNAL,
  taskListResponseState_INTERNAL
} from 'main/recoil/runbook'
import { LayoutLoading } from '../layout/layout-loading'

export const RunbookLoadingBoundary = ({ children }: { children: ReactNode }) => {
  const { state: accountResponse } = useRecoilValueLoadable(accountState)
  const { state: taskResponse } = useRecoilValueLoadable(taskListResponseState_INTERNAL)
  const { state: rbResponse } = useRecoilValueLoadable(runbookResponseState_INTERNAL)
  const { state: rbvResponse } = useRecoilValueLoadable(runbookVersionResponseState_INTERNAL)
  const isLoading = [accountResponse, taskResponse, rbResponse, rbvResponse].includes('loading')

  if (isLoading) return <LayoutLoading filterPanel subHeader rightNav />

  return <Suspense fallback={<LayoutLoading filterPanel subHeader rightNav />}>{children}</Suspense>
}
